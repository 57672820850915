import querystring from 'querystring';
import { withProps } from 'recompose';
import config from 'config';

const supportWebp = (() => {
  const el = document.createElement('canvas');
  if (!!(el.getContext && el.getContext('2d'))) {
    return el.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
})();

export const withBackgroundMediaCdn = withProps(({ backgroundMediaUrl, profile, ...props }) => {
  if (!backgroundMediaUrl && !profile) return;
  if (!profile.defaultBackgroundMediaUrl) return;

  const { imageCdnHost, storageBucketHost } = config;

  backgroundMediaUrl = backgroundMediaUrl || profile.defaultBackgroundMediaUrl;
  backgroundMediaUrl = backgroundMediaUrl.replace(storageBucketHost, imageCdnHost);

  return {
    ...props,
    backgroundMediaUrl,
  };
});

export const withBackgroundMediaAdjustments = withProps((props) => {
  if (!props.backgroundMediaUrl) return {};

  const [url, adjustments] = props.backgroundMediaUrl.split('?');
  const { w, ...qs } = querystring.parse(adjustments);
  const ext = url.split('.').pop();
  const backgroundAnimated = ext === 'gif';

  const compression = {
    w: window.screen.width < 540 ? 375 : 540,
    cs: 'strip',
    dpr: window.devicePixelRatio || 1,
  };

  if (!backgroundAnimated) {
    compression.fm = 'jpg';
  }

  if (supportWebp) {
    compression.auto = 'format';
  }

  const dramaFilter = {
    blend: props.brandColor,
    bm: 'multiply',
  };

  const params =
    qs.blend && qs.bm !== 'normal'
      ? { ...qs, ...compression, ...dramaFilter }
      : { ...qs, ...compression };

  return {
    backgroundMediaUrl: url + '?' + querystring.stringify(params),
    backgroundAnimated,
  };
});

import React from 'react';
import styled from 'styled-components/macro';
import { compose, withHandlers, withState } from 'recompose';
import VideoMeta from './video-meta';
import { YoutubeIcon } from 'components/ui/icons';

const VideoContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const ThumbnailImage = styled.div`
  border-radius: 4px;
  width: 100%;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center;
  height: 0;
  padding-bottom: ${(p) => p.ratio * 100}%;
`;

const Icon = styled.div`
  position: absolute;
  left: calc(50% - 36px);
  top: calc(50% - 24px);

  path {
    fill: rgba(0, 0, 0, 0.75);
  }

  #youtube-icon-chevron {
    fill: white;
  }
`;

const ratio = 9 / 16;

const Video = ({ video, handleClick, play, activeVideo }) => (
  <VideoContainer>
    <ThumbnailContainer onClick={handleClick}>
      <ThumbnailImage src={video.thumbnailUrl} ratio={ratio} />
      <Icon>
        <YoutubeIcon width={72} height={48} />
      </Icon>
    </ThumbnailContainer>
    <VideoMeta video={video} />
  </VideoContainer>
);

const withHandlePlayVideo = withHandlers({
  handleClick: ({ setActiveVideo, video }) => (event) => {
    event.preventDefault();
    setActiveVideo(video);
  },
});

export default compose(
  //
  withState('play', 'setPlay', false),
  withHandlePlayVideo,
)(Video);

import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as reducers from './reducers';

const rootReducer = combineReducers({
  ...reducers,
});

export const getDispatcher = (dispatch) => (type, payload) => {
  dispatch({ type, payload });
};

export const connectDispatcher = (dispatch) => ({
  dispatcher: getDispatcher(dispatch),
});

export { connect } from 'react-redux';

export default createStore(rootReducer, composeWithDevTools());

import React from 'react';

function Soundcloud(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M432 240c-5.696 0-11.296.608-16.672 1.76C408.128 169.024 346.592 112 272 112c-8.832 0-16 7.168-16 16v256c0 8.832 7.168 16 16 16h160c44.128 0 80-35.872 80-80 0-44.096-35.872-80-80-80zM208 144c-8.832 0-16 7.168-16 16v224c0 8.832 7.168 16 16 16s16-7.168 16-16V160c0-8.832-7.168-16-16-16zM144 208c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V224c0-8.832-7.168-16-16-16zM80 208c-8.832 0-16 7.168-16 16v160c0 8.832 7.168 16 16 16s16-7.168 16-16V224c0-8.832-7.168-16-16-16zM16 256c-8.832 0-16 7.168-16 16v96c0 8.832 7.168 16 16 16s16-7.168 16-16v-96c0-8.832-7.168-16-16-16z" />
    </svg>
  );
}

Soundcloud.defaultProps = {
  fill: 'currentColor',
  width: 24,
  height: 24,
};

export default Soundcloud;

import styled, { css } from 'styled-components/macro';
import CardLayout from 'components/story-card/ui/card-layout';
import { isNull } from 'lib/utils';
import { Heading } from 'components/web-ui';
import { omitProps } from 'lib/recompose';
import { View } from 'components/ui';

export const BaseHeading = omitProps('isVisible')(Heading);

export const BaseCSS = css`
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.32);
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 49.5%;
`;

export const Layout = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Content = styled(View)`
  align-items: center;
  flex-shrink: 0;
`;

export const GutterSizer = styled.div`
  width: 5px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 49.5%;
  flex-direction: column;
  margin-bottom: 1%;
`;

export const CopyWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const VideoWrapper = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-color: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding-top: ${(props) => {
    if (isNull(props.aspectRatio)) return '100%';
    return `${props.aspectRatio * 100}%`;
  }};
`;

export const Image = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 6px;
  /*
   If the aspect ratio is null this div will have a zero height
   And the child ImageFallback component will be visible
   */
  ${(props) => `padding-top: ${props.aspectRatio * 100}%`};
`;

export const ImageFallback = styled.img``;

export const Video = styled.video`
  position: absolute;
  top: 0;
  border-radius: 7px;
  display: block;
  width: 100%;
`;

export const Title = styled.div`
  ${BaseCSS};
  width: 100%;
  color: #fff;
  font-size: 13px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin: 0;
  line-height: 1.35;
  padding-left: 7px;
  text-align: left;
`;

export const Description = styled.div`
  ${BaseCSS};
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 7px;
  padding: 0 7px;
  line-height: 1.2;
  text-align: left;
`;

export const Crop = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Layout", {
  enumerable: true,
  get: function get() {
    return _layout.Layout;
  }
});
Object.defineProperty(exports, "GridSpace", {
  enumerable: true,
  get: function get() {
    return _gridSpace.GridSpace;
  }
});
exports["default"] = void 0;

var _layout = require("./layout");

var _gridSpace = require("./grid-space");

var _default = {
  Layout: _layout.Layout,
  GridSpace: _gridSpace.GridSpace
};
exports["default"] = _default;
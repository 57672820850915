import React from 'react';
import styled from 'styled-components/macro';
import { withAccount } from 'lib/graphql';
import { withUsername } from 'hocs';
import { compose } from 'lib/compose';

const Container = styled.div`
  position: relative;
  height: ${(p) => p.w};
  min-width: ${(p) => p.w};
`;

const Avatar = styled.div`
  width: ${(p) => p.w};
  height: ${(p) => p.w};
  border-radius: 50%;
  background-color: #c0c0c0;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center;
`;

const AvatarText = styled.span`
  font-size: 22px;
  color: white;
  position: absolute;
  line-height: 1;
  left: 2px;
  top: 2px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
`;

const ProfilePic = (props) => {
  const { account, username, ...rest } = props;

  if (!account) return null;

  return (
    <Container {...rest}>
      <Avatar src={account.profile.pictureUrl} {...rest} />
      {!account.profile.pictureUrl && <AvatarText children={username[0]} />}
    </Container>
  );
};

ProfilePic.defaultProps = {
  w: '40px',
};

export default compose(
  //
  withUsername,
  withAccount,
)(ProfilePic);

import { withHandlers } from 'recompose';
import { CardSubmitPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendCardSubmit: (props) => (accountId, cardId) => {
    const payload = CardSubmitPayload.create({
      accountId,
      cardId,
    });
    StatsCollector.cardSubmit(payload);
  },
});

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.GridSpace = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var GridSpace = _styledComponents["default"].div.withConfig({
  displayName: "grid-space__GridSpace",
  componentId: "eez7s-0"
})(["display:flex;"]);

exports.GridSpace = GridSpace;
GridSpace.displayName = 'GridSpace';
var _default = GridSpace;
exports["default"] = _default;
import styled, { css } from 'styled-components/macro';
import compose from 'lib/compose';
import { withProps, omitProps } from 'lib/recompose';
import View from 'components/ui/view';
import { withUsername } from 'hocs';
import { withAccount } from 'lib/graphql';
import { withBackgroundMediaCdn, withBackgroundMediaAdjustments } from 'lib/background-media';
import withHasBlendMode from 'hocs/with-has-blend-mode';
import addLightenFilter from 'lib/add-lighten-filter';

const baseStyles = css`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  color: white;
  background-color: #333;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
`;

const paginationGradientStyles = css`
  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 15px;
    width: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
    transform: translate3d(0, 0, 0);
    z-index: 5;
  }
`;

const backgroundGradientOverlayStyles = css`
  &::before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.9));
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const backgroundMedia = css`
  background-image: ${(p) =>
    p.backgroundAnimated || p.backgroundColorSolid ? 'none' : `url(${p.backgroundMediaUrl})`};
  background-color: ${(p) => (p.backgroundColorSolid ? p.backgroundColorSolid : 'transparent')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  ${(props) => addLightenFilter(props.brandColor, props.hasBlendMode)};
  ${(props) => props.hasBackgroundGradient && backgroundGradientOverlayStyles};

  & > * {
    position: relative;
    z-index: 2;
  }
`;

const CardLayout = styled(
  omitProps(
    'activeIndex',
    'backgroundColor',
    'backgroundMediaPreviewUrl',
    'brandColor',
    'buttonColor',
    'cardIndexInDeck',
    'currentAccountsQuery',
    'dispatch',
    'featuredImage',
    'handleClick',
    'handleCtaClick',
    'hasBackgroundGradient',
    'hasBlendMode',
    'hasCta',
    'imageGallery',
    'isOwnerAuthenticated',
    'mailingList',
    'onContentScroll',
    'sendImageGalleryItemClick',
    'sendVideoPlay',
    'setCardWidth',
  )(View),
)`
  ${baseStyles};
  ${(props) => props.backgroundMediaUrl && backgroundMedia};
  ${paginationGradientStyles};
`;

export default compose(
  withUsername,
  withAccount,
  withProps((props) => {
    const brandColor = props.backgroundColor
      ? props.backgroundColor
      : ((props.account || {}).profile || {}).brandColor;

    return {
      ...props,
      brandColor,
    };
  }),
  withBackgroundMediaCdn,
  withBackgroundMediaAdjustments,
  withHasBlendMode,
)(CardLayout);

import React from 'react';
import { parseDate, formatDate } from 'lib/date';
import { Text } from 'components/web-ui';
import styled from 'styled-components/macro';

const TweetContainer = styled.div`
  margin-bottom: 5px;
  color: #000;
  background-color: #fff;
  padding: 10px 12px;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  &:active {
    opacity: 0.8;
  }
`;

const TwitterHandle = styled(Text)`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  margin-bottom: 6px;
  text-align: left;
  text-shadow: none;

  & span {
    font-weight: normal;
  }
`;

const TweetContent = styled(Text)`
  font-size: 16px;
  line-height: 1.35;
  text-align: left;
  text-shadow: none;
`;

const TweetLink = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
`;

function Tweet(props) {
  const { twitterUsername, tweet, onTweetClick } = props;
  const parsedDate = parseDate(tweet.created_at);
  const createdAt = formatDate(parsedDate, 'MMM do');
  const href = `https://twitter.com/${twitterUsername}/status/${tweet.id}`;

  return (
    <TweetLink target="_blank" href={href} onClick={onTweetClick} data-id={tweet.id}>
      <TweetContainer>
        <TwitterHandle>
          @{twitterUsername} - <span children={createdAt} />
        </TwitterHandle>
        <TweetContent>{tweet.text}</TweetContent>
      </TweetContainer>
    </TweetLink>
  );
}

export default Tweet;

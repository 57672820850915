import { withHandlers } from 'recompose';

const validEmail = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/gi;
// http://www.rubular.com/r/ga546JrYpQ
const validPhoneNumber = /^\+?[(]?[*0-9]+[)]?.?[0-9]{3,4}?.?[0-9]{3,4}$/;
const validUrl = /(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

function detectProtocol(value) {
  if (!value) return;
  if (value.match(validEmail)) return 'email';
  if (value.match(validPhoneNumber)) return 'tel';
  if (value.match(validUrl)) return 'url';
}

const withRenderProtocol = withHandlers({
  renderHrefWithProtocol: () => (href) => {
    // Skip URLs that already have a protocol
    if (href.startsWith('http://') || href.startsWith('https://')) return href;
    if (href.startsWith('mailto:')) return href;
    if (href.startsWith('tel:')) return href;

    switch (detectProtocol(href)) {
      case 'tel':
        let [countryCode, number] = href.split('*');
        if (countryCode.length < 5) countryCode = `+${countryCode}`;
        return `tel:${countryCode}${number || ''}`;
      case 'email':
        return `mailto:${href}`;
      default:
        return href;
    }
  },
});

export default withRenderProtocol;

import React from 'react';
import InstagramFeed from './instagram-feed';
import * as Recompose from 'lib/recompose';
import throttleEventHandler from 'lib/throttle-event-handler';
import withCollectCardLinkClick from 'hocs/with-collect-card-click';
import withHandleCtaClick from 'hocs/with-handle-cta-click';
import withRenderProtocol from 'hocs/with-render-protocol';
import { TitleSmall, DescriptionSmall, CtaButton, CtaButtonContainerFixed } from '../styles';
import { Layout, Scrollable } from './styles';
import { convertHexToRgba } from 'lib/color-utils';
import { fixLineBreakSpacers } from 'lib/utils';

const InstagramCard = (props) => {
  const {
    description,
    handleScroll,
    id: cardId,
    instagram: config,
    buttonColor,
    cta,
    handleCtaClick,
    shouldLazyLoad,
    setShouldLazyLoad,
    isLoading,
    title,
    ...rest
  } = props;

  return (
    <Layout {...rest} full scroll={false} flex="column">
      <Scrollable scroll onScroll={throttleEventHandler(handleScroll, 500, { leading: true })}>
        {title && <TitleSmall children={fixLineBreakSpacers(title)} />}
        <DescriptionSmall>
          {description ? fixLineBreakSpacers(description) : 'Tap on any photo to visit the link'}
        </DescriptionSmall>
        <InstagramFeed
          cardId={cardId}
          config={config}
          hasCta={!!cta}
          isLoading={isLoading}
          setShouldLazyLoad={setShouldLazyLoad}
          shouldLazyLoad={shouldLazyLoad}
        />
        {cta && (
          <CtaButtonContainerFixed>
            <CtaButton
              bg={convertHexToRgba(buttonColor || props.profile.brandColor)}
              children={cta.title}
              onClick={handleCtaClick}
            />
          </CtaButtonContainerFixed>
        )}
      </Scrollable>
    </Layout>
  );
};

const handleScroll = (props) => (event) => {
  // Hide the header on scroll
  props.onContentScroll(event);

  if (props.shouldLazyLoad === 'disabled') return;
  if (event.target.scrollTop < 200) return;

  // Trigger lazy load - When the IG feed component sees the change
  // in this state it'll execute the next request.
  props.setShouldLazyLoad(true);
};

export default Recompose.compose(
  withRenderProtocol,
  Recompose.withState('shouldLazyLoad', 'setShouldLazyLoad', false),
  Recompose.withProps((props) => ({ cta: props.ctas[0] || props.cta })),
  Recompose.withHandlers({ handleScroll }),
  withCollectCardLinkClick,
  withHandleCtaClick,
  Recompose.pure,
)(InstagramCard);

import React, { useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Clipboard from 'clipboard';
import { compose, withProps } from 'lib/recompose';
import withHandleCtaClick from 'hocs/with-handle-cta-click';
import withCollectCardLinkClick from 'hocs/with-collect-card-click';
import { convertHexToRgba } from 'lib/color-utils';
import { fixLineBreakSpacers } from 'lib/utils';
import throttleEventHandler from 'lib/throttle-event-handler';
import { Title, Description, CtaButton, CtaButtonContainer } from '../styles';
import { Layout, Scrollable, CopyNotice } from './styles';

const SimpleCard = (props) => {
  const { buttonColor, cta, handleCtaClick, onContentScroll } = props;

  // --- Code to handle displaying the 'copied' message ---
  //
  // This was added as a temporary measure. This code should be refactored to
  // make it generic, and applicable to any CTA.

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const copyProps = {};
  const linkClipboard = new Clipboard('#copy-cta-link');

  if (cta && cta.url.startsWith('copy:')) {
    copyProps['id'] = 'copy-cta-link';
    copyProps['data-clipboard-text'] = cta.url.replace('copy:', '');
    linkClipboard.on('success', function(e) {
      setShowCopiedMessage(true); // display the message
      setTimeout(() => setShowCopiedMessage(null), 2500); // Hide the message after 2.5 seconds.
    });
  }

  // ------------------------------------------------------

  return (
    <Layout {...props} full>
      <Scrollable
        center
        flex="column"
        onScroll={throttleEventHandler(onContentScroll, 500, { leading: true })}
        scroll
      >
        {props.title && <Title children={fixLineBreakSpacers(props.title)} />}
        {props.description && <Description children={fixLineBreakSpacers(props.description)} />}
        {cta && (
          <CtaButtonContainer>
            <CSSTransition in={showCopiedMessage} timeout={200} classNames="fade-in-out">
              <CopyNotice>Copied {copyProps['data-clipboard-text']}</CopyNotice>
            </CSSTransition>
            <CtaButton
              bg={convertHexToRgba(buttonColor || props.profile.brandColor)}
              children={cta.title}
              onClick={handleCtaClick}
              {...copyProps}
            />
          </CtaButtonContainer>
        )}
      </Scrollable>
    </Layout>
  );
};

export default compose(
  withProps((props) => ({ cta: props.ctas[0] || props.cta })),
  withCollectCardLinkClick,
  withHandleCtaClick,
)(SimpleCard);

import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsNull from 'lodash/isNull';
import lodashPick from 'lodash/pick';
import lodashSortBy from 'lodash/sortBy';
import lodashThrottle from 'lodash/throttle';

console.debug = process.env.REACT_APP_DEBUG ? console.info : () => null;

console.debug(
  'Debug is active. Use `console.debug` in your code, seen only when REACT_APP_DEBUG is set',
);

// Find the next value in an array, object literal, or number, given a current value
export const nextValue = (obj, state, adv = 1) => {
  if (typeof obj !== 'object' && typeof obj !== 'number')
    throw new Error('first parameter must be an Array, Object literal, or Number');
  const len = typeof obj === 'number' && obj;

  if (len) {
    const forward = adv > 0;
    const start = 0;
    const end = len - 1;
    const max = forward ? state >= end : state <= start;
    const restart = forward ? start : end;
    state += adv;
    state = max ? restart : state;
    return state;
  } else {
    const arr = Array.isArray(obj) ? obj : Object.values(obj);

    let index = arr.indexOf(state);
    index = index + adv >= arr.length ? 0 : index + adv;

    return arr[index];
  }
};

/**
 * Use like:
 *    sleep(n).then(fn); //or
 *    await sleep(n);
 */
export const sleep = (time = 0) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

/**
 * @summary Parses a URL's query/search string into an object
 * @param   {String} search - The url's search string
 * @returns {Object} The parsed string as an object containing each of the query key/values.
 */

export function searchToObject(search) {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    );
  } catch (error) {
    return;
  }
}

// When rendering content that has an ending line break, we need to add another
// one or it won't render. Yeah!
// https://stackoverflow.com/questions/43492826/why-does-the-browser-not-render-a-line-break-caused-by-a-trailing-newline-charac
export function fixLineBreakSpacers(content) {
  return content.slice(-1) === '\n' ? `${content}\n` : content;
}

export const throttle = lodashThrottle;

export const sortBy = lodashSortBy;

export const isNull = lodashIsNull;

export const isEmpty = lodashIsEmpty;

export const isEqual = lodashIsEqual;

export const pick = lodashPick;

export const get = lodashGet;

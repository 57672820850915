import React, { useEffect } from 'react';
import FacebookPixel from 'lib/facebook-pixel';
import GoogleAnalytics from 'lib/google-analytics';
import useGdprConsent from 'hocs/use-gdpr-consent';
import { compose } from 'recompose';
import { withUsername } from 'hocs';
import { withAccount } from 'lib/graphql';
import { ActionBar, AcceptButton, DeclineButton, Title, ConsentMessage, Container } from './styled';

const GdprConsent = ({ username, account }) => {
  const { facebookPixelId, googleTrackingId } = (account || {}).profile || {};
  const [accepted, decisionMade, setAccepted] = useGdprConsent();
  const acceptHandler = () => setAccepted(true);
  const declineHandler = () => setAccepted(false);

  useEffect(() => {
    if (!username || !decisionMade) return;

    if (googleTrackingId) {
      if (accepted) {
        GoogleAnalytics.startTracking();
        GoogleAnalytics.startTracking(username);
      } else {
        GoogleAnalytics.stopTracking();
        GoogleAnalytics.stopTracking(username);
      }
    }
  }, [googleTrackingId, username, accepted, decisionMade]);

  useEffect(() => {
    if (!username || !decisionMade) return;

    if (facebookPixelId) {
      if (accepted) {
        FacebookPixel.startTracking();
      } else {
        FacebookPixel.stopTracking();
      }
    }
  }, [facebookPixelId, username, accepted, decisionMade]);

  if ((!facebookPixelId && !googleTrackingId) || decisionMade) return null;

  return (
    <Container>
      <Title>We use cookies</Title>
      <ConsentMessage>
        We use cookies and tracking technologies to improve your browsing experience on our website.
        By browsing our website, you consent to our use of cookies and other tracking technologies.
      </ConsentMessage>
      <ActionBar>
        <AcceptButton onClick={acceptHandler}>Continue</AcceptButton>
        <DeclineButton onClick={declineHandler}>Decline</DeclineButton>
      </ActionBar>
    </Container>
  );
};

export default compose(withUsername, withAccount)(GdprConsent);

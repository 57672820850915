import React from 'react';
import styled from 'styled-components/macro';
import withRenderProtocol from 'hocs/with-render-protocol';
import {
  SocialAppleMusicIcon,
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialThreadsIcon,
  SocialLinkedInIcon,
  SocialPinterestIcon,
  SocialSpotifyIcon,
  SocialTwitterIcon,
  SocialYouTubeIcon,
  SocialEmailIcon,
  SocialTelephoneIcon,
  SocialTiktokIcon,
  SocialSnapchatIcon,
  SocialSoundcloudIcon,
} from 'components/ui/icons';

const LinkWithIcon = styled.a`
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: inherit;
  background: rgba(255, 255, 255, 0.48);
  margin: 0 8px 10px 8px;

  :active {
    opacity: 0.75;
  }
`;

// e.g. [IconComponent, width/height]
const icons = {
  'apple-music': [SocialAppleMusicIcon, 30],
  email: [SocialEmailIcon, 22],
  facebook: [SocialFacebookIcon, 22],
  instagram: [SocialInstagramIcon, 22],
  threads: [SocialThreadsIcon, 22],
  linkedin: [SocialLinkedInIcon, 22],
  pinterest: [SocialPinterestIcon, 22],
  spotify: [SocialSpotifyIcon, 28],
  telephone: [SocialTelephoneIcon, 22],
  twitter: [SocialTwitterIcon, 22],
  youtube: [SocialYouTubeIcon, 22],
  snapchat: [SocialSnapchatIcon, 22],
  soundcloud: [SocialSoundcloudIcon, 22],
  tiktok: [SocialTiktokIcon, 22],
};

const SocialMediaLink = ({ icon: iconName, renderHrefWithProtocol, ...props }) => {
  const icon = icons[iconName];
  if (!icon) return null;
  const IconComponent = icon[0];
  const widthHeight = icon[1];
  return (
    <LinkWithIcon {...props} href={renderHrefWithProtocol(props.href)}>
      <IconComponent width={widthHeight} height={widthHeight} />
    </LinkWithIcon>
  );
};

export default withRenderProtocol(SocialMediaLink);

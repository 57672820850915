import React from 'react';
import styled from 'styled-components/macro';
import { toEm } from 'lib/css';
import { theme } from 'components/application/layout';
import { TapBioLogo } from 'components/ui/icons';
import withSignupUrl from 'hocs/with-signup-url';

const { viewport } = theme;
const { w, h } = viewport.breakpoints;
const width = toEm(w + 200);
const width2 = toEm(w + 520);
const height = toEm(h + 30);

const Layout = styled.div`
  display: none;
  position: fixed;
  bottom: 1em;
  right: 1em;
  left: 1em;
  font-size: 14px;
  color: #888888;
  transform: translate3d(0, 0, 0);
  text-align: center;
  line-height: 16px;
  z-index: 1;

  @media (min-height: ${height}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: ${width}) and (max-height: ${height}) {
    left: initial;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  @media (min-width: ${width2}) and (max-height: ${height}) {
    left: initial;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h6`
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  margin-right: 8px;
  text-align: right;
  font-size: 12px;
  line-height: 14px;

  @media (min-width: ${width}) and (max-height: ${height}) {
    text-align: center;
  }

  @media (min-width: ${width2}) and (max-height: ${height}) {
    text-align: right;
  }
`;

const Logo = styled(TapBioLogo)`
  width: 56px;
`;

const Body = styled.div`
  text-align: left;
  margin-left: 8px;
  max-width: 14em;

  @media (min-width: ${width}) and (max-height: ${height}) {
    margin-top: 20px;
    margin-left: 0;
    max-width: 9em;
  }

  @media (min-width: ${width2}) and (max-height: ${height}) {
    margin-top: 0;
    margin-left: 8px;
    max-width: 14em;
  }
`;

const Link = styled.a`
  color: inherit;
`;

const Component = ({ signupUrl }) => (
  <Layout>
    <Header>
      <Title>
        Made
        <br />
        with
      </Title>
      <div>
        <Logo />
      </div>
    </Header>
    <Body>
      Make your Tap Bio now by clicking here <Link href={signupUrl}>sign-up</Link>.
    </Body>
  </Layout>
);

export default withSignupUrl(Component);

import React from 'react';
import styled, { css } from 'styled-components/macro';
import CardLayout from 'components/story-card/ui/card-layout';
import { ButtonCircle } from 'components/web-ui';

export function getContentSpacing(card) {}

// --- Components ---

// Sometimes we need to add extra space between the title / description and the content below it
// Whether we show this space depends on the presence / absence of the title / description
export const ContentSpacer = ({ title, description, children }) => {
  let paddingBottom = '0';
  if (title && !description) paddingBottom = '16px';
  return <div style={{ paddingBottom }} children={children} />;
};

// --- Styles ---

export const BaseCSS = css`
  color: ${(props) => props.theme.colors.white};
  font-weight: 400;
  margin: 0;
  padding: 0;
  white-space: pre-line;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu;
`;

export const TitleCSS = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.1875;
  margin: 0 0 1em 0;
  text-align: center;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
`;

export const TitleSmallCSS = css`
  ${TitleCSS};
  font-size: 20px;
  margin: 0 0 3px 0;
`;

export const DescriptionCSS = css`
  font-size: 23px;
  line-height: 1.3;
  text-align: center;
  box-sizing: border-box;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  margin: 0 0 18px 0;
`;

export const DescriptionSmallCSS = css`
  ${DescriptionCSS};
  font-size: 13px;
`;

// --- Components ---

export const Layout = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.h2`
  ${BaseCSS};
  ${TitleCSS};
`;

export const Description = styled.h2`
  ${BaseCSS};
  ${DescriptionCSS};
`;

export const TitleSmall = styled.h2`
  ${BaseCSS};
  ${TitleSmallCSS};
`;

export const DescriptionSmall = styled.h2`
  ${BaseCSS};
  ${DescriptionSmallCSS};
`;

export const CtaButtonContainer = styled.div`
  width: 100%;
  margin-top: 18px;
  position: relative;
`;

// Please be very careful when changing this style. It *must* use fixed positioning because of a
// webkit bug with -webkit-overflow-scrolling: touch;
//
// When you layer an absolutly positioned element over any element that uses
// -webkit-overflow-scrolling: touch; it causes the z-index style to be ignored (until a repaint?).
//
// For us this causes the CTA to temporarily display behind the scroll element, then 'pop' back
// into its rightful place above the content.
//
// https://stackoverflow.com/questions/16033416/while-scrolling-on-an-ios-device-the-z-index-of-elements-isnt-working
//
export const CtaButtonContainerFixed = styled.div`
  position: absolute;
  bottom: 10px;
  z-index: 100;
  width: calc(100% - 20px);
  margin: 14px 0 0 10px;
`;

export const CtaButton = styled(ButtonCircle)`
  width: 100%;
  line-height: 1.35;
  padding: 18px;
  font-size: 17px;
  margin: 0 0 10px 0;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  min-height: 58px;

  &:last-child {
    margin-bottom: 0;
  }

  &:disabled {
    opacity: 0.7;
  }
`;

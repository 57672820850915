import * as Sentry from '@sentry/browser';

function initSentry(config, packageName) {
  const sentryConfig = {
    debug: config.environment === 'development',
    dsn: config.sentry.publicDsn,
    environment: config.environment,
    serverName: packageName,
    whitelistUrls: [config[`${packageName}Host`].replace('https://', '')],
  };
  Sentry.init(sentryConfig);
}

export default initSentry;

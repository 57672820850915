import { ApolloClient } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Authentication from 'lib/authentication';
import config from 'config';

const FUNCTION_CANDIDATES = [
  'publicAccount',
  'publicInstagramFeedwithCardId',
  'getYouTubeVideos',
  'getTweetsPayload',
];
const RETRIES = 2;

// Configure GraphQL to read a JWT from local storage and send
// it in the Authorization header of each request
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext((context) => {
    const { headers = {} } = context;
    const token = Authentication.getToken();

    context.headers = {
      authorization: token ? `Bearer ${token}` : null,
      ...headers,
    };

    return context;
  });

  return forward(operation);
});

const httpFunctionsLink = new HttpLink({
  uri: config.functionsGraphqlHost,
  useGETForQueries: true,
});

const httpApiLink = new HttpLink({
  uri: config.graphQLHost,
});

const httpLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (!error) return false;
    if (count < RETRIES) return true;
    if (operation.getContext().fallback) {
      if (count < RETRIES * 2) return true;
      return false;
    }

    operation.setContext({ fallback: true, retry: count });

    return true;
  },
  delay: (count, operation, error) => {
    return count * 1000 * Math.random();
  },
}).split(
  (operation) => {
    const candidate = FUNCTION_CANDIDATES.includes(operation.operationName);
    const { fallback } = operation.getContext();

    return candidate && !fallback;
  },
  httpFunctionsLink,
  httpApiLink,
);

const cache = new InMemoryCache({ addTypename: true });
const link = from([authMiddleware, httpLink]);

export default new ApolloClient({ cache, link });

import { withHandlers } from 'recompose';
import { CardViewPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendCardView: (props) => (accountId, cardId) => {
    const payload = CardViewPayload.create({
      accountId,
      cardId,
    });
    StatsCollector.cardView(payload);
  },
});

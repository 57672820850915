import tinycolor from 'tinycolor2';

export default function addLightenFilter(brandColor, shouldApply) {
  if (!shouldApply) return '';
  const color = tinycolor(brandColor);
  const lightenColor = color.toHsv().v > 0.6 ? '#292D99' : '#000366';
  return `
        background-color: ${lightenColor};
        background-blend-mode: lighten;
      `;
}

import config from '../config';

const trackers = {};

const initialize = (trackingId, username) => {
  if (!trackingId) return;

  if (username) {
    if (trackers[username]) {
      return;
    }
    trackers[username] = trackingId;
  }

  window.gtag('config', trackingId, { anonymize_ip: true });
};

const startTracking = (username) => {
  if (trackers[username]) {
    gtag('config', trackers[username], { send_page_view: true });
  }
};

const stopTracking = (username) => {
  if (trackers[username]) {
    gtag('config', trackers[username], { send_page_view: false });
  }
};

const sendTo = (username) => {
  if (username in trackers) {
    return trackers[username];
  }
  return config.gaTrackingId;
};

const pageView = (username) => {
  gtag('event', 'page_view', {
    path: window.location.pathname,
    title: `@${username}`,
    send_to: sendTo(username),
  });
};

const cardView = (card, username) => {
  gtag('event', 'page_view', {
    path: window.location.pathname,
    title: `${card.kind} | ${card.title || 'untitled'}`,
    send_to: sendTo(username),
  });
};

const ctaClick = (card, cta) => {
  gtag('event', 'action', {
    event_category: 'cta',
    event_action: 'click',
    event_label: `${card.kind} | CTA click | ${cta.url}`,
    path: window.location.pathname,
  });
};

const gtag = (...args) => {
  if (!window.gtag) return;
  return window.gtag(...args);
};

export default {
  cardView,
  ctaClick,
  initialize,
  startTracking,
  stopTracking,
  pageView,
};

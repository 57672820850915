import React from 'react';
import styled from 'styled-components/macro';
import BrandingTag from './branding-tag';
import NavButton from '@tap-bio/ui/patterns/story-slider/nav-button';

const SwiperMouseNav = styled.div`
  position: absolute;
  width: 36rem;
  left: 50%;

  transform: translateX(-50%);

  display: flex;
  justify-content: space-between;
`;

export default () => {
  return (
    <React.Fragment>
      <BrandingTag />
      <SwiperMouseNav>
        <NavButton direction="prev" />
        <NavButton direction="next" />
      </SwiperMouseNav>
    </React.Fragment>
  );
};

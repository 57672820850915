import proto from './stats_pb.js';

const { proto_stats } = proto;

export default proto_stats;
export const Platform = proto_stats.Platform;
export const StatType = proto_stats.StatType;
export const IndexType = proto_stats.IndexType;
export const StatEvent = proto_stats.StatEvent;
export const IndexOptions = proto_stats.IndexOptions;
export const PageViewPayload = proto_stats.PageViewPayload;
export const CardViewPayload = proto_stats.CardViewPayload;
export const CardClickPayload = proto_stats.CardClickPayload;
export const CardSubmitPayload = proto_stats.CardSubmitPayload;
export const InstagramLinkClickPayload = proto_stats.InstagramLinkClickPayload;
export const InstagramFollowersPayload = proto_stats.InstagramFollowersPayload;
export const YoutubeVideoPlayPayload = proto_stats.YoutubeVideoPlayPayload;
export const TwitterTweetClickPayload = proto_stats.TwitterTweetClickPayload;
export const ImageGalleryItemClickPayload = proto_stats.ImageGalleryItemClickPayload;

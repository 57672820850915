import styled from 'styled-components/macro';
import View from 'components/ui/view';
import CardLayout from 'components/story-card/ui/card-layout';

const Layout = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Scrollable = styled(View)`
  padding: 85px 20px 10px 20px;
  justify-content: flex-start;
`;

const CopyNotice = styled.div`
  opacity: 0;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  text-align: center;

  &.fade-in-out-enter {
    opacity: 0;
    top: -20px;
    height: auto;
  }

  &.fade-in-out-enter-active {
    opacity: 1;
    transform: translateY(-10px);
    transition: 0.2s ease-in-out;
  }

  &.fade-in-out-enter-done {
    opacity: 1;
    transform: translateY(-10px);
  }

  &.fade-in-out-exit {
    opacity: 1;
    transform: translateY(-10px);
    transition: 0.2s ease-in-out;
  }

  &.fade-in-out-exit-active {
    opacity: 0;
    transform: translateY(0px);
  }
`;

export { Layout, Scrollable, CopyNotice };

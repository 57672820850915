import cookie from 'js-cookie';
import config from 'config';

// cookie storage
const cookieStorage = {
  get: function(key) {
    return cookie.get(key, { domain: config.domain });
  },
  set: function(key, val) {
    const secure = window.location.protocol === 'https:';
    const expires = 365;
    cookie.set(key, val, { domain: config.domain, expires, secure });
  },
  remove: function(key) {
    cookie.remove(key, { domain: config.domain });
  },
  support: function() {
    const key = '_t',
      val = '0';
    cookie.set(key, val);
    return cookie.get(key) === val;
  },
};

// window.name storage
const windowName = {
  get: function(key) {
    let obj = {};
    try {
      obj = JSON.parse(window.name);
    } catch (_) {}
    if (typeof obj[key] !== 'string') {
      return '';
    }
    return obj[key];
  },
  set: function(key, val) {
    let obj = {};
    try {
      obj = JSON.parse(window.name);
    } catch (_) {}
    obj[key] = val;
    window.name = JSON.stringify(obj);
  },
  remove: function(key) {
    let obj = {};
    try {
      obj = JSON.parse(window.name);
    } catch (_) {}
    delete obj[key];
    window.name = JSON.stringify(obj);
  },
  support: function() {
    return typeof window.name !== 'undefined';
  },
};

// memoryStorage
const memoryStorage = {
  map: {},
  get: function(key) {
    if (typeof this.map[key] !== 'string') {
      return '';
    }
    return this.map[key];
  },
  set: function(key, val) {
    this.map[key] = val;
  },
  remove: function(key) {
    delete this.map[key];
  },
  support: function() {
    return true;
  },
};

const storages = [cookieStorage, windowName, memoryStorage];

export default (() => {
  for (const storage of storages) {
    if (storage.support()) {
      return storage;
    }
  }
  return memoryStorage;
})();

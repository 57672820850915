import styled from 'styled-components/macro';

export default styled.div`
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
  height: 20px;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
`;

import React from 'react';
import styled from 'styled-components/macro';

const ErrorContainer = styled.div`
  background-color: pink;
  border: 2px solid red;
  padding: 10px;
`;

const ErrorMessage = ({ message }) => (
  <ErrorContainer>
    <span>{message}</span>
  </ErrorContainer>
);

ErrorMessage.defaultProps = { message: 'Something went wrong!' };

export default ErrorMessage;

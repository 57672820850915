import React from 'react';
import styled from 'styled-components/macro';
import { TwitterIcon } from 'components/ui/icons';
import { withProps } from 'recompose';

const Link = styled.a`
  color: white;
  width: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.backgroundColor};
  border-radius: 2em;
  padding: 18px;
  font-size: 17px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  line-height: 1.35;

  :active,
  :focus {
    background-color: ${(props) => props.backgroundColor};
    opacity: 0.7;
    color: white;
    outline: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }

  :active {
    background-color: ${(props) => props.backgroundColor};
  }
`.withComponent(({ backgroundColor, children, ...props }) => <a {...props} children={children} />);

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 5px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }

  path {
    fill: #ffffff;
  }
  #youtube-icon-chevron {
    fill: ${(props) => props.backgroundColor};
  }
`.withComponent(({ backgroundColor, ...props }) => <div {...props} />);

function SubscribeButton(props) {
  const { backgroundColor, href } = props;
  return (
    <Link backgroundColor={backgroundColor} href={href} target="_blank">
      <Icon backgroundColor={backgroundColor}>
        <TwitterIcon width={24} height={16} />
        <span>Follow Me</span>
      </Icon>
    </Link>
  );
}

const withPlatformLink = withProps(({ username }) => {});

export default withPlatformLink(SubscribeButton);

import 'lib/polyfills';
import React from 'react';
import { render } from 'react-dom';
import Application from 'components/application';
import Aside from 'components/application/aside';
import { ApolloProvider } from '@apollo/react-hooks';
import graphQLClient from 'lib/graphql/client';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import { ThemeProvider } from 'styled-components/macro';
import theme from 'components/web-ui/theme';
import GoogleAnalytics from 'lib/google-analytics';
import StatsCollector from 'lib/stats-collector';
import config from './config';
import initSentry from '@tap-bio/core/init-sentry';

// This polyfill is required to support iOS versions < 11.3 (released April 2018)
// https://caniuse.com/#search=sendbeacon
import 'navigator.sendbeacon';

// Initialize sentry scoping to the landing site
initSentry(config, 'landing');

// preloadAccountData(apolloClient, getUsername());

// Initialize internal GA as early as possible
// Customer GA is initialized just before it's used.
GoogleAnalytics.initialize(config.gaTrackingId);

// This patch is required to support iOS versions between >= 12.1 and < 13.0
// https://bugs.webkit.org/show_bug.cgi?id=193508
StatsCollector.initialize();

const appRootElement = document.getElementById('application-root');
const ApplicationRoot = (
  <ApolloProvider client={graphQLClient}>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Application />
      </ThemeProvider>
    </ReduxProvider>
  </ApolloProvider>
);

render(ApplicationRoot, appRootElement);

const asideRootElement = document.getElementById('application-aside');
const AsideRoot = (
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <Aside />
    </ThemeProvider>
  </ReduxProvider>
);

render(AsideRoot, asideRootElement);

if (module.hot) {
  module.hot.accept(() => {
    render(ApplicationRoot, appRootElement);
  });
}

if (module.hot) {
  module.hot.accept(() => {
    render(AsideRoot, asideRootElement);
  });
}

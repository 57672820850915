import React from 'react';
import styled from 'styled-components/macro';
import { compose, withProps, withHandlers } from 'lib/recompose';
import withCollectCardLinkClick from 'hocs/with-collect-card-click';
import withCollectImageGalleryItemClick from 'hocs/with-collect-image-gallery-click';
import { sortBy } from 'lib/utils';
import throttleEventHandler from 'lib/throttle-event-handler';
import { convertHexToRgba } from 'lib/color-utils';
import GalleryImagesMasonry from './gallery-images-masonry';
import GalleryImagesSquare from './gallery-images-square';
import FeaturedImage from './featured-image';
import View from 'components/ui/view';
import { Content, Layout } from './styled';
import {
  ContentSpacer,
  CtaButton,
  CtaButtonContainerFixed,
  DescriptionSmall,
  TitleSmall,
} from '../styles';
import { fixLineBreakSpacers } from 'lib/utils';
import resolveCtaUrl from '@tap-bio/core/utils/resolve-cta-url';

const Scrollable = styled(View)`
  padding: 68px 5px 0 5px;
  position: relative;

  /* Allow children to expand to take up all available height */
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImageGalleryCard = (props) => {
  const {
    buttonColor,
    ctas,
    description,
    featuredImage,
    handleClick,
    hasCta,
    imageGallery,
    images,
    onContentScroll,
    title,
  } = props;

  return (
    <Layout {...props} full>
      <Scrollable scroll onScroll={throttleEventHandler(onContentScroll, 500, { leading: true })}>
        <Content flex="column" center>
          <ContentSpacer title={title} description={description}>
            {title && <TitleSmall children={fixLineBreakSpacers(title)} />}
            {description && <DescriptionSmall children={fixLineBreakSpacers(description)} />}
          </ContentSpacer>
        </Content>
        <FeaturedImage image={featuredImage} />
        <GalleryImagesSquare images={images} view={imageGallery.view} handleClick={handleClick} />
        <GalleryImagesMasonry
          images={images}
          view={imageGallery.view}
          hasCta={hasCta}
          handleClick={handleClick}
        />
      </Scrollable>
      {hasCta && (
        <CtaButtonContainerFixed>
          <CtaButton
            bg={convertHexToRgba(buttonColor || props.profile.brandColor)}
            children={ctas[0].title}
            onClick={(event) => handleClick()}
          />
        </CtaButtonContainerFixed>
      )}
    </Layout>
  );
};

const featuredImageProps = withProps(({ images, imageGallery }) => {
  if (!images.length || !imageGallery.hasFeaturedImage) return { images };
  return {
    featuredImage: images[0],
    images: images.slice(1),
  };
});

const sortedImagesProps = withProps((props) => ({
  images: sortBy(props.images, 'position'),
  hasCta: props.ctas.length > 0,
}));

export default compose(
  withCollectCardLinkClick,
  withCollectImageGalleryItemClick,
  sortedImagesProps,
  featuredImageProps,
  withHandlers({
    handleClick: (props) => (image) => {
      const { id: cardId, ctas, account, sendCardClick, sendImageGalleryItemClick } = props;
      let url;

      // If the image was passed in to the click handler then we should track a click on the image
      // Otherwise track a click on the card's CTA
      if (image) {
        url = image.url;
        if (!url) return;
        sendImageGalleryItemClick(account.id, cardId, image.id, url);
      } else {
        // There must be a URL if we get here
        url = ctas[0].url;
        sendCardClick(account.id, cardId, ctas[0].id, url);
      }
      window.location = resolveCtaUrl(url);
    },
  }),
)(ImageGalleryCard);

import { graphql } from '@apollo/react-hoc';
import { subscribeMailingListMutation } from 'lib/graphql/mutations';

export const withMailingListSubscribeHandler = graphql(subscribeMailingListMutation, {
  props: ({ mutate, ownProps }) => ({
    subscribe: async (cardId, email, username) => {
      try {
        return await mutate({
          subscribeMailingListMutation,
          variables: { cardId, email, username },
        });
      } catch (error) {
        return {
          hasErrors: true,
          errorMessage: error.graphQLErrors[0].message,
        };
      }
    },
  }),
});

export default withMailingListSubscribeHandler;

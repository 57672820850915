/*eslint-disable block-scoped-var, no-redeclare, no-control-regex, no-prototype-builtins*/
import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

export const proto_stats = ($root.proto_stats = (() => {
  /**
   * Namespace proto_stats.
   * @exports proto_stats
   * @namespace
   */
  const proto_stats = {};

  /**
   * Platform is the application platform name
   * @name proto_stats.Platform
   * @enum {string}
   * @property {number} UNKNOWN_PLATFORM=0 UNKNOWN_PLATFORM value
   * @property {number} TB=1 TB value
   */
  proto_stats.Platform = (function() {
    const valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = 'UNKNOWN_PLATFORM')] = 0;
    values[(valuesById[1] = 'TB')] = 1;
    return values;
  })();

  /**
   * StatType is the type of stat
   * @name proto_stats.StatType
   * @enum {string}
   * @property {number} UNKNOWN_STAT_TYPE=0 UNKNOWN_STAT_TYPE value
   * @property {number} USER_EVENTS=1 event recorder
   * @property {number} ACCOUNT_PAGE_VIEW=20 page views
   * @property {number} APP_ACCOUNTS_PAGE_VIEW=21 APP_ACCOUNTS_PAGE_VIEW value
   * @property {number} ACCOUNT_PAGE_VIEW_UNIQUE=50 ACCOUNT_PAGE_VIEW_UNIQUE value
   * @property {number} ACCOUNT_CARD_VIEW=100 card views
   * @property {number} ACCOUNT_CARDS_VIEW=101 ACCOUNT_CARDS_VIEW value
   * @property {number} APP_CARDS_VIEW=102 APP_CARDS_VIEW value
   * @property {number} ACCOUNT_CARD_VIEW_UNIQUE=110 ACCOUNT_CARD_VIEW_UNIQUE value
   * @property {number} ACCOUNT_CARD_CLICK=130 card clicks
   * @property {number} ACCOUNT_CARDS_CLICK=131 ACCOUNT_CARDS_CLICK value
   * @property {number} APP_CARDS_CLICK=132 APP_CARDS_CLICK value
   * @property {number} ACCOUNT_CARD_CTA_CLICK=140 ACCOUNT_CARD_CTA_CLICK value
   * @property {number} ACCOUNT_CARD_URL_CLICK=141 ACCOUNT_CARD_URL_CLICK value
   * @property {number} ACCOUNT_CARDS_CTA_CLICK=142 ACCOUNT_CARDS_CTA_CLICK value
   * @property {number} ACCOUNT_CARDS_URL_CLICK=143 ACCOUNT_CARDS_URL_CLICK value
   * @property {number} ACCOUNT_CARD_CLICK_UNIQUE=170 ACCOUNT_CARD_CLICK_UNIQUE value
   * @property {number} ACCOUNT_CARD_CTA_CLICK_UNIQUE=171 ACCOUNT_CARD_CTA_CLICK_UNIQUE value
   * @property {number} ACCOUNT_CARD_URL_CLICK_UNIQUE=172 ACCOUNT_CARD_URL_CLICK_UNIQUE value
   * @property {number} ACCOUNT_INSTAGRAM_LINKS_CLICK=200 ACCOUNT_INSTAGRAM_LINKS_CLICK value
   * @property {number} APP_INSTAGRAM_LINKS_CLICK=201 APP_INSTAGRAM_LINKS_CLICK value
   * @property {number} ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK=205 ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK value
   * @property {number} ACCOUNT_INSTAGRAM_LINK_URL_CLICK=206 ACCOUNT_INSTAGRAM_LINK_URL_CLICK value
   * @property {number} ACCOUNT_INSTAGRAM_LINKS_CLICK_UNIQUE=240 ACCOUNT_INSTAGRAM_LINKS_CLICK_UNIQUE value
   * @property {number} ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK_UNIQUE=241 ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK_UNIQUE value
   * @property {number} ACCOUNT_INSTAGRAM_LINK_URL_CLICK_UNIQUE=242 ACCOUNT_INSTAGRAM_LINK_URL_CLICK_UNIQUE value
   * @property {number} ACCOUNT_CARD_SUBMIT=300 card submits
   * @property {number} ACCOUNT_CARDS_SUBMIT=301 ACCOUNT_CARDS_SUBMIT value
   * @property {number} APP_CARDS_SUBMIT=302 APP_CARDS_SUBMIT value
   * @property {number} ACCOUNT_CARD_SUBMIT_UNIQUE=310 ACCOUNT_CARD_SUBMIT_UNIQUE value
   * @property {number} ACCOUNT_INSTAGRAM_FOLLOWERS=400 instagram followers
   * @property {number} ACCOUNT_YOUTUBE_VIDEO_PLAYS=500 ACCOUNT_YOUTUBE_VIDEO_PLAYS value
   * @property {number} ACCOUNT_YOUTUBE_VIDEOS_PLAYS=501 ACCOUNT_YOUTUBE_VIDEOS_PLAYS value
   * @property {number} ACCOUNT_CARD_YOUTUBE_VIDEO_PLAYS=550 ACCOUNT_CARD_YOUTUBE_VIDEO_PLAYS value
   * @property {number} ACCOUNT_CARD_YOUTUBE_VIDEOS_PLAYS=551 ACCOUNT_CARD_YOUTUBE_VIDEOS_PLAYS value
   * @property {number} APP_YOUTUBE_VIDEOS_PLAYS=580 APP_YOUTUBE_VIDEOS_PLAYS value
   * @property {number} ACCOUNT_TWITTER_TWEET_CLICK=600 ACCOUNT_TWITTER_TWEET_CLICK value
   * @property {number} ACCOUNT_TWITTER_TWEETS_CLICK=601 ACCOUNT_TWITTER_TWEETS_CLICK value
   * @property {number} ACCOUNT_CARD_TWITTER_TWEET_CLICK=650 ACCOUNT_CARD_TWITTER_TWEET_CLICK value
   * @property {number} ACCOUNT_CARD_TWITTER_TWEETS_CLICK=651 ACCOUNT_CARD_TWITTER_TWEETS_CLICK value
   * @property {number} APP_TWITTER_TWEETS_CLICK=680 APP_TWITTER_TWEETS_CLICK value
   * @property {number} ACCOUNT_IMAGE_GALLERY_ITEM_CLICK=700 ACCOUNT_IMAGE_GALLERY_ITEM_CLICK value
   * @property {number} ACCOUNT_IMAGE_GALLERY_ITEMS_CLICK=701 ACCOUNT_IMAGE_GALLERY_ITEMS_CLICK value
   * @property {number} ACCOUNT_CARD_IMAGE_GALLERY_ITEM_CLICK=730 ACCOUNT_CARD_IMAGE_GALLERY_ITEM_CLICK value
   * @property {number} ACCOUNT_CARD_IMAGE_GALLERY_ITEMS_CLICK=731 ACCOUNT_CARD_IMAGE_GALLERY_ITEMS_CLICK value
   * @property {number} APP_IMAGE_GALLERY_ITEMS_CLICK=780 APP_IMAGE_GALLERY_ITEMS_CLICK value
   */
  proto_stats.StatType = (function() {
    const valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = 'UNKNOWN_STAT_TYPE')] = 0;
    values[(valuesById[1] = 'USER_EVENTS')] = 1;
    values[(valuesById[20] = 'ACCOUNT_PAGE_VIEW')] = 20;
    values[(valuesById[21] = 'APP_ACCOUNTS_PAGE_VIEW')] = 21;
    values[(valuesById[50] = 'ACCOUNT_PAGE_VIEW_UNIQUE')] = 50;
    values[(valuesById[100] = 'ACCOUNT_CARD_VIEW')] = 100;
    values[(valuesById[101] = 'ACCOUNT_CARDS_VIEW')] = 101;
    values[(valuesById[102] = 'APP_CARDS_VIEW')] = 102;
    values[(valuesById[110] = 'ACCOUNT_CARD_VIEW_UNIQUE')] = 110;
    values[(valuesById[130] = 'ACCOUNT_CARD_CLICK')] = 130;
    values[(valuesById[131] = 'ACCOUNT_CARDS_CLICK')] = 131;
    values[(valuesById[132] = 'APP_CARDS_CLICK')] = 132;
    values[(valuesById[140] = 'ACCOUNT_CARD_CTA_CLICK')] = 140;
    values[(valuesById[141] = 'ACCOUNT_CARD_URL_CLICK')] = 141;
    values[(valuesById[142] = 'ACCOUNT_CARDS_CTA_CLICK')] = 142;
    values[(valuesById[143] = 'ACCOUNT_CARDS_URL_CLICK')] = 143;
    values[(valuesById[170] = 'ACCOUNT_CARD_CLICK_UNIQUE')] = 170;
    values[(valuesById[171] = 'ACCOUNT_CARD_CTA_CLICK_UNIQUE')] = 171;
    values[(valuesById[172] = 'ACCOUNT_CARD_URL_CLICK_UNIQUE')] = 172;
    values[(valuesById[200] = 'ACCOUNT_INSTAGRAM_LINKS_CLICK')] = 200;
    values[(valuesById[201] = 'APP_INSTAGRAM_LINKS_CLICK')] = 201;
    values[(valuesById[205] = 'ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK')] = 205;
    values[(valuesById[206] = 'ACCOUNT_INSTAGRAM_LINK_URL_CLICK')] = 206;
    values[(valuesById[240] = 'ACCOUNT_INSTAGRAM_LINKS_CLICK_UNIQUE')] = 240;
    values[(valuesById[241] = 'ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK_UNIQUE')] = 241;
    values[(valuesById[242] = 'ACCOUNT_INSTAGRAM_LINK_URL_CLICK_UNIQUE')] = 242;
    values[(valuesById[300] = 'ACCOUNT_CARD_SUBMIT')] = 300;
    values[(valuesById[301] = 'ACCOUNT_CARDS_SUBMIT')] = 301;
    values[(valuesById[302] = 'APP_CARDS_SUBMIT')] = 302;
    values[(valuesById[310] = 'ACCOUNT_CARD_SUBMIT_UNIQUE')] = 310;
    values[(valuesById[400] = 'ACCOUNT_INSTAGRAM_FOLLOWERS')] = 400;
    values[(valuesById[500] = 'ACCOUNT_YOUTUBE_VIDEO_PLAYS')] = 500;
    values[(valuesById[501] = 'ACCOUNT_YOUTUBE_VIDEOS_PLAYS')] = 501;
    values[(valuesById[550] = 'ACCOUNT_CARD_YOUTUBE_VIDEO_PLAYS')] = 550;
    values[(valuesById[551] = 'ACCOUNT_CARD_YOUTUBE_VIDEOS_PLAYS')] = 551;
    values[(valuesById[580] = 'APP_YOUTUBE_VIDEOS_PLAYS')] = 580;
    values[(valuesById[600] = 'ACCOUNT_TWITTER_TWEET_CLICK')] = 600;
    values[(valuesById[601] = 'ACCOUNT_TWITTER_TWEETS_CLICK')] = 601;
    values[(valuesById[650] = 'ACCOUNT_CARD_TWITTER_TWEET_CLICK')] = 650;
    values[(valuesById[651] = 'ACCOUNT_CARD_TWITTER_TWEETS_CLICK')] = 651;
    values[(valuesById[680] = 'APP_TWITTER_TWEETS_CLICK')] = 680;
    values[(valuesById[700] = 'ACCOUNT_IMAGE_GALLERY_ITEM_CLICK')] = 700;
    values[(valuesById[701] = 'ACCOUNT_IMAGE_GALLERY_ITEMS_CLICK')] = 701;
    values[(valuesById[730] = 'ACCOUNT_CARD_IMAGE_GALLERY_ITEM_CLICK')] = 730;
    values[(valuesById[731] = 'ACCOUNT_CARD_IMAGE_GALLERY_ITEMS_CLICK')] = 731;
    values[(valuesById[780] = 'APP_IMAGE_GALLERY_ITEMS_CLICK')] = 780;
    return values;
  })();

  /**
   * IndexType is the search index type
   * @name proto_stats.IndexType
   * @enum {string}
   * @property {number} UNKNOWN_INDEX=0 UNKNOWN_INDEX value
   * @property {number} DAILY=1 DAILY value
   * @property {number} HOURLY=2 HOURLY value
   * @property {number} WEEKLY=3 WEEKLY value
   * @property {number} MONTHLY=4 MONTHLY value
   * @property {number} YEARLY=5 YEARLY value
   * @property {number} LAST_7_DAYS=10 LAST_7_DAYS value
   * @property {number} LAST_14_DAYS=11 LAST_14_DAYS value
   * @property {number} LAST_MONTH=12 LAST_MONTH value
   * @property {number} TIMELESS=90 TIMELESS value
   */
  proto_stats.IndexType = (function() {
    const valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = 'UNKNOWN_INDEX')] = 0;
    values[(valuesById[1] = 'DAILY')] = 1;
    values[(valuesById[2] = 'HOURLY')] = 2;
    values[(valuesById[3] = 'WEEKLY')] = 3;
    values[(valuesById[4] = 'MONTHLY')] = 4;
    values[(valuesById[5] = 'YEARLY')] = 5;
    values[(valuesById[10] = 'LAST_7_DAYS')] = 10;
    values[(valuesById[11] = 'LAST_14_DAYS')] = 11;
    values[(valuesById[12] = 'LAST_MONTH')] = 12;
    values[(valuesById[90] = 'TIMELESS')] = 90;
    return values;
  })();

  proto_stats.StatEvent = (function() {
    /**
     * Properties of a StatEvent.
     * @memberof proto_stats
     * @interface IStatEvent
     * @property {Array.<proto_stats.StatType>|null} [types] StatEvent types
     * @property {proto_stats.Platform|null} [platform] StatEvent platform
     * @property {number|Long|null} [timestamp] The UNIX timestamp (10-digit)
     * @property {proto_stats.IPageViewPayload|null} [pageView] StatEvent pageView
     * @property {proto_stats.ICardViewPayload|null} [cardView] StatEvent cardView
     * @property {proto_stats.ICardClickPayload|null} [cardClick] StatEvent cardClick
     * @property {proto_stats.ICardSubmitPayload|null} [cardSubmit] StatEvent cardSubmit
     * @property {proto_stats.IInstagramLinkClickPayload|null} [instagramLinkClick] StatEvent instagramLinkClick
     * @property {proto_stats.IInstagramFollowersPayload|null} [instagramFollowers] StatEvent instagramFollowers
     * @property {proto_stats.IYoutubeVideoPlayPayload|null} [youtubeVideoPlay] StatEvent youtubeVideoPlay
     * @property {proto_stats.ITwitterTweetClickPayload|null} [twitterTweetClick] StatEvent twitterTweetClick
     * @property {proto_stats.IImageGalleryItemClickPayload|null} [imageGalleryItemClick] StatEvent imageGalleryItemClick
     * @property {proto_stats.IIndexOptions|null} [indexOptions] Build search index options
     */

    /**
     * Constructs a new StatEvent.
     * @memberof proto_stats
     * @classdesc StatEvent is the pubsub transport message
     * @implements IStatEvent
     * @constructor
     * @param {proto_stats.IStatEvent=} [properties] Properties to set
     */
    function StatEvent(properties) {
      this.types = [];
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StatEvent types.
     * @member {Array.<proto_stats.StatType>} types
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.types = $util.emptyArray;

    /**
     * StatEvent platform.
     * @member {proto_stats.Platform} platform
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.platform = 0;

    /**
     * The UNIX timestamp (10-digit)
     * @member {number|Long} timestamp
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.timestamp = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * StatEvent pageView.
     * @member {proto_stats.IPageViewPayload|null|undefined} pageView
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.pageView = null;

    /**
     * StatEvent cardView.
     * @member {proto_stats.ICardViewPayload|null|undefined} cardView
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.cardView = null;

    /**
     * StatEvent cardClick.
     * @member {proto_stats.ICardClickPayload|null|undefined} cardClick
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.cardClick = null;

    /**
     * StatEvent cardSubmit.
     * @member {proto_stats.ICardSubmitPayload|null|undefined} cardSubmit
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.cardSubmit = null;

    /**
     * StatEvent instagramLinkClick.
     * @member {proto_stats.IInstagramLinkClickPayload|null|undefined} instagramLinkClick
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.instagramLinkClick = null;

    /**
     * StatEvent instagramFollowers.
     * @member {proto_stats.IInstagramFollowersPayload|null|undefined} instagramFollowers
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.instagramFollowers = null;

    /**
     * StatEvent youtubeVideoPlay.
     * @member {proto_stats.IYoutubeVideoPlayPayload|null|undefined} youtubeVideoPlay
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.youtubeVideoPlay = null;

    /**
     * StatEvent twitterTweetClick.
     * @member {proto_stats.ITwitterTweetClickPayload|null|undefined} twitterTweetClick
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.twitterTweetClick = null;

    /**
     * StatEvent imageGalleryItemClick.
     * @member {proto_stats.IImageGalleryItemClickPayload|null|undefined} imageGalleryItemClick
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.imageGalleryItemClick = null;

    /**
     * Build search index options
     * @member {proto_stats.IIndexOptions|null|undefined} indexOptions
     * @memberof proto_stats.StatEvent
     * @instance
     */
    StatEvent.prototype.indexOptions = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * The event stat payload
     * @member {"pageView"|"cardView"|"cardClick"|"cardSubmit"|"instagramLinkClick"|"instagramFollowers"|"youtubeVideoPlay"|"twitterTweetClick"|"imageGalleryItemClick"|undefined} payload
     * @memberof proto_stats.StatEvent
     * @instance
     */
    Object.defineProperty(StatEvent.prototype, 'payload', {
      get: $util.oneOfGetter(
        ($oneOfFields = [
          'pageView',
          'cardView',
          'cardClick',
          'cardSubmit',
          'instagramLinkClick',
          'instagramFollowers',
          'youtubeVideoPlay',
          'twitterTweetClick',
          'imageGalleryItemClick',
        ]),
      ),
      set: $util.oneOfSetter($oneOfFields),
    });

    /**
     * Creates a new StatEvent instance using the specified properties.
     * @function create
     * @memberof proto_stats.StatEvent
     * @static
     * @param {proto_stats.IStatEvent=} [properties] Properties to set
     * @returns {proto_stats.StatEvent} StatEvent instance
     */
    StatEvent.create = function create(properties) {
      return new StatEvent(properties);
    };

    /**
     * Encodes the specified StatEvent message. Does not implicitly {@link proto_stats.StatEvent.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.StatEvent
     * @static
     * @param {proto_stats.IStatEvent} message StatEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StatEvent.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.types != null && message.types.length) {
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
        for (let i = 0; i < message.types.length; ++i) writer.int32(message.types[i]);
        writer.ldelim();
      }
      if (message.platform != null && message.hasOwnProperty('platform'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.platform);
      if (message.timestamp != null && message.hasOwnProperty('timestamp'))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.timestamp);
      if (message.pageView != null && message.hasOwnProperty('pageView'))
        $root.proto_stats.PageViewPayload.encode(
          message.pageView,
          writer.uint32(/* id 20, wireType 2 =*/ 162).fork(),
        ).ldelim();
      if (message.cardView != null && message.hasOwnProperty('cardView'))
        $root.proto_stats.CardViewPayload.encode(
          message.cardView,
          writer.uint32(/* id 40, wireType 2 =*/ 322).fork(),
        ).ldelim();
      if (message.cardClick != null && message.hasOwnProperty('cardClick'))
        $root.proto_stats.CardClickPayload.encode(
          message.cardClick,
          writer.uint32(/* id 41, wireType 2 =*/ 330).fork(),
        ).ldelim();
      if (message.cardSubmit != null && message.hasOwnProperty('cardSubmit'))
        $root.proto_stats.CardSubmitPayload.encode(
          message.cardSubmit,
          writer.uint32(/* id 42, wireType 2 =*/ 338).fork(),
        ).ldelim();
      if (message.instagramLinkClick != null && message.hasOwnProperty('instagramLinkClick'))
        $root.proto_stats.InstagramLinkClickPayload.encode(
          message.instagramLinkClick,
          writer.uint32(/* id 60, wireType 2 =*/ 482).fork(),
        ).ldelim();
      if (message.instagramFollowers != null && message.hasOwnProperty('instagramFollowers'))
        $root.proto_stats.InstagramFollowersPayload.encode(
          message.instagramFollowers,
          writer.uint32(/* id 61, wireType 2 =*/ 490).fork(),
        ).ldelim();
      if (message.youtubeVideoPlay != null && message.hasOwnProperty('youtubeVideoPlay'))
        $root.proto_stats.YoutubeVideoPlayPayload.encode(
          message.youtubeVideoPlay,
          writer.uint32(/* id 70, wireType 2 =*/ 562).fork(),
        ).ldelim();
      if (message.twitterTweetClick != null && message.hasOwnProperty('twitterTweetClick'))
        $root.proto_stats.TwitterTweetClickPayload.encode(
          message.twitterTweetClick,
          writer.uint32(/* id 80, wireType 2 =*/ 642).fork(),
        ).ldelim();
      if (message.imageGalleryItemClick != null && message.hasOwnProperty('imageGalleryItemClick'))
        $root.proto_stats.ImageGalleryItemClickPayload.encode(
          message.imageGalleryItemClick,
          writer.uint32(/* id 90, wireType 2 =*/ 722).fork(),
        ).ldelim();
      if (message.indexOptions != null && message.hasOwnProperty('indexOptions'))
        $root.proto_stats.IndexOptions.encode(
          message.indexOptions,
          writer.uint32(/* id 200, wireType 2 =*/ 1602).fork(),
        ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified StatEvent message, length delimited. Does not implicitly {@link proto_stats.StatEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.StatEvent
     * @static
     * @param {proto_stats.IStatEvent} message StatEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StatEvent.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StatEvent message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.StatEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.StatEvent} StatEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StatEvent.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.StatEvent();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.types && message.types.length)) message.types = [];
            if ((tag & 7) === 2) {
              let end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.types.push(reader.int32());
            } else message.types.push(reader.int32());
            break;
          case 2:
            message.platform = reader.int32();
            break;
          case 3:
            message.timestamp = reader.uint64();
            break;
          case 20:
            message.pageView = $root.proto_stats.PageViewPayload.decode(reader, reader.uint32());
            break;
          case 40:
            message.cardView = $root.proto_stats.CardViewPayload.decode(reader, reader.uint32());
            break;
          case 41:
            message.cardClick = $root.proto_stats.CardClickPayload.decode(reader, reader.uint32());
            break;
          case 42:
            message.cardSubmit = $root.proto_stats.CardSubmitPayload.decode(
              reader,
              reader.uint32(),
            );
            break;
          case 60:
            message.instagramLinkClick = $root.proto_stats.InstagramLinkClickPayload.decode(
              reader,
              reader.uint32(),
            );
            break;
          case 61:
            message.instagramFollowers = $root.proto_stats.InstagramFollowersPayload.decode(
              reader,
              reader.uint32(),
            );
            break;
          case 70:
            message.youtubeVideoPlay = $root.proto_stats.YoutubeVideoPlayPayload.decode(
              reader,
              reader.uint32(),
            );
            break;
          case 80:
            message.twitterTweetClick = $root.proto_stats.TwitterTweetClickPayload.decode(
              reader,
              reader.uint32(),
            );
            break;
          case 90:
            message.imageGalleryItemClick = $root.proto_stats.ImageGalleryItemClickPayload.decode(
              reader,
              reader.uint32(),
            );
            break;
          case 200:
            message.indexOptions = $root.proto_stats.IndexOptions.decode(reader, reader.uint32());
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StatEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.StatEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.StatEvent} StatEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StatEvent.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StatEvent message.
     * @function verify
     * @memberof proto_stats.StatEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StatEvent.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      let properties = {};
      if (message.types != null && message.hasOwnProperty('types')) {
        if (!Array.isArray(message.types)) return 'types: array expected';
        for (let i = 0; i < message.types.length; ++i)
          switch (message.types[i]) {
            default:
              return 'types: enum value[] expected';
            case 0:
            case 1:
            case 20:
            case 21:
            case 50:
            case 100:
            case 101:
            case 102:
            case 110:
            case 130:
            case 131:
            case 132:
            case 140:
            case 141:
            case 142:
            case 143:
            case 170:
            case 171:
            case 172:
            case 200:
            case 201:
            case 205:
            case 206:
            case 240:
            case 241:
            case 242:
            case 300:
            case 301:
            case 302:
            case 310:
            case 400:
            case 500:
            case 501:
            case 550:
            case 551:
            case 580:
            case 600:
            case 601:
            case 650:
            case 651:
            case 680:
            case 700:
            case 701:
            case 730:
            case 731:
            case 780:
              break;
          }
      }
      if (message.platform != null && message.hasOwnProperty('platform'))
        switch (message.platform) {
          default:
            return 'platform: enum value expected';
          case 0:
          case 1:
            break;
        }
      if (message.timestamp != null && message.hasOwnProperty('timestamp'))
        if (
          !$util.isInteger(message.timestamp) &&
          !(
            message.timestamp &&
            $util.isInteger(message.timestamp.low) &&
            $util.isInteger(message.timestamp.high)
          )
        )
          return 'timestamp: integer|Long expected';
      if (message.pageView != null && message.hasOwnProperty('pageView')) {
        properties.payload = 1;
        {
          let error = $root.proto_stats.PageViewPayload.verify(message.pageView);
          if (error) return 'pageView.' + error;
        }
      }
      if (message.cardView != null && message.hasOwnProperty('cardView')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.CardViewPayload.verify(message.cardView);
          if (error) return 'cardView.' + error;
        }
      }
      if (message.cardClick != null && message.hasOwnProperty('cardClick')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.CardClickPayload.verify(message.cardClick);
          if (error) return 'cardClick.' + error;
        }
      }
      if (message.cardSubmit != null && message.hasOwnProperty('cardSubmit')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.CardSubmitPayload.verify(message.cardSubmit);
          if (error) return 'cardSubmit.' + error;
        }
      }
      if (message.instagramLinkClick != null && message.hasOwnProperty('instagramLinkClick')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.InstagramLinkClickPayload.verify(
            message.instagramLinkClick,
          );
          if (error) return 'instagramLinkClick.' + error;
        }
      }
      if (message.instagramFollowers != null && message.hasOwnProperty('instagramFollowers')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.InstagramFollowersPayload.verify(
            message.instagramFollowers,
          );
          if (error) return 'instagramFollowers.' + error;
        }
      }
      if (message.youtubeVideoPlay != null && message.hasOwnProperty('youtubeVideoPlay')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.YoutubeVideoPlayPayload.verify(message.youtubeVideoPlay);
          if (error) return 'youtubeVideoPlay.' + error;
        }
      }
      if (message.twitterTweetClick != null && message.hasOwnProperty('twitterTweetClick')) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.TwitterTweetClickPayload.verify(message.twitterTweetClick);
          if (error) return 'twitterTweetClick.' + error;
        }
      }
      if (
        message.imageGalleryItemClick != null &&
        message.hasOwnProperty('imageGalleryItemClick')
      ) {
        if (properties.payload === 1) return 'payload: multiple values';
        properties.payload = 1;
        {
          let error = $root.proto_stats.ImageGalleryItemClickPayload.verify(
            message.imageGalleryItemClick,
          );
          if (error) return 'imageGalleryItemClick.' + error;
        }
      }
      if (message.indexOptions != null && message.hasOwnProperty('indexOptions')) {
        let error = $root.proto_stats.IndexOptions.verify(message.indexOptions);
        if (error) return 'indexOptions.' + error;
      }
      return null;
    };

    /**
     * Creates a StatEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.StatEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.StatEvent} StatEvent
     */
    StatEvent.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.StatEvent) return object;
      let message = new $root.proto_stats.StatEvent();
      if (object.types) {
        if (!Array.isArray(object.types))
          throw TypeError('.proto_stats.StatEvent.types: array expected');
        message.types = [];
        for (let i = 0; i < object.types.length; ++i)
          switch (object.types[i]) {
            default:
            case 'UNKNOWN_STAT_TYPE':
            case 0:
              message.types[i] = 0;
              break;
            case 'USER_EVENTS':
            case 1:
              message.types[i] = 1;
              break;
            case 'ACCOUNT_PAGE_VIEW':
            case 20:
              message.types[i] = 20;
              break;
            case 'APP_ACCOUNTS_PAGE_VIEW':
            case 21:
              message.types[i] = 21;
              break;
            case 'ACCOUNT_PAGE_VIEW_UNIQUE':
            case 50:
              message.types[i] = 50;
              break;
            case 'ACCOUNT_CARD_VIEW':
            case 100:
              message.types[i] = 100;
              break;
            case 'ACCOUNT_CARDS_VIEW':
            case 101:
              message.types[i] = 101;
              break;
            case 'APP_CARDS_VIEW':
            case 102:
              message.types[i] = 102;
              break;
            case 'ACCOUNT_CARD_VIEW_UNIQUE':
            case 110:
              message.types[i] = 110;
              break;
            case 'ACCOUNT_CARD_CLICK':
            case 130:
              message.types[i] = 130;
              break;
            case 'ACCOUNT_CARDS_CLICK':
            case 131:
              message.types[i] = 131;
              break;
            case 'APP_CARDS_CLICK':
            case 132:
              message.types[i] = 132;
              break;
            case 'ACCOUNT_CARD_CTA_CLICK':
            case 140:
              message.types[i] = 140;
              break;
            case 'ACCOUNT_CARD_URL_CLICK':
            case 141:
              message.types[i] = 141;
              break;
            case 'ACCOUNT_CARDS_CTA_CLICK':
            case 142:
              message.types[i] = 142;
              break;
            case 'ACCOUNT_CARDS_URL_CLICK':
            case 143:
              message.types[i] = 143;
              break;
            case 'ACCOUNT_CARD_CLICK_UNIQUE':
            case 170:
              message.types[i] = 170;
              break;
            case 'ACCOUNT_CARD_CTA_CLICK_UNIQUE':
            case 171:
              message.types[i] = 171;
              break;
            case 'ACCOUNT_CARD_URL_CLICK_UNIQUE':
            case 172:
              message.types[i] = 172;
              break;
            case 'ACCOUNT_INSTAGRAM_LINKS_CLICK':
            case 200:
              message.types[i] = 200;
              break;
            case 'APP_INSTAGRAM_LINKS_CLICK':
            case 201:
              message.types[i] = 201;
              break;
            case 'ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK':
            case 205:
              message.types[i] = 205;
              break;
            case 'ACCOUNT_INSTAGRAM_LINK_URL_CLICK':
            case 206:
              message.types[i] = 206;
              break;
            case 'ACCOUNT_INSTAGRAM_LINKS_CLICK_UNIQUE':
            case 240:
              message.types[i] = 240;
              break;
            case 'ACCOUNT_INSTAGRAM_LINK_PHOTO_CLICK_UNIQUE':
            case 241:
              message.types[i] = 241;
              break;
            case 'ACCOUNT_INSTAGRAM_LINK_URL_CLICK_UNIQUE':
            case 242:
              message.types[i] = 242;
              break;
            case 'ACCOUNT_CARD_SUBMIT':
            case 300:
              message.types[i] = 300;
              break;
            case 'ACCOUNT_CARDS_SUBMIT':
            case 301:
              message.types[i] = 301;
              break;
            case 'APP_CARDS_SUBMIT':
            case 302:
              message.types[i] = 302;
              break;
            case 'ACCOUNT_CARD_SUBMIT_UNIQUE':
            case 310:
              message.types[i] = 310;
              break;
            case 'ACCOUNT_INSTAGRAM_FOLLOWERS':
            case 400:
              message.types[i] = 400;
              break;
            case 'ACCOUNT_YOUTUBE_VIDEO_PLAYS':
            case 500:
              message.types[i] = 500;
              break;
            case 'ACCOUNT_YOUTUBE_VIDEOS_PLAYS':
            case 501:
              message.types[i] = 501;
              break;
            case 'ACCOUNT_CARD_YOUTUBE_VIDEO_PLAYS':
            case 550:
              message.types[i] = 550;
              break;
            case 'ACCOUNT_CARD_YOUTUBE_VIDEOS_PLAYS':
            case 551:
              message.types[i] = 551;
              break;
            case 'APP_YOUTUBE_VIDEOS_PLAYS':
            case 580:
              message.types[i] = 580;
              break;
            case 'ACCOUNT_TWITTER_TWEET_CLICK':
            case 600:
              message.types[i] = 600;
              break;
            case 'ACCOUNT_TWITTER_TWEETS_CLICK':
            case 601:
              message.types[i] = 601;
              break;
            case 'ACCOUNT_CARD_TWITTER_TWEET_CLICK':
            case 650:
              message.types[i] = 650;
              break;
            case 'ACCOUNT_CARD_TWITTER_TWEETS_CLICK':
            case 651:
              message.types[i] = 651;
              break;
            case 'APP_TWITTER_TWEETS_CLICK':
            case 680:
              message.types[i] = 680;
              break;
            case 'ACCOUNT_IMAGE_GALLERY_ITEM_CLICK':
            case 700:
              message.types[i] = 700;
              break;
            case 'ACCOUNT_IMAGE_GALLERY_ITEMS_CLICK':
            case 701:
              message.types[i] = 701;
              break;
            case 'ACCOUNT_CARD_IMAGE_GALLERY_ITEM_CLICK':
            case 730:
              message.types[i] = 730;
              break;
            case 'ACCOUNT_CARD_IMAGE_GALLERY_ITEMS_CLICK':
            case 731:
              message.types[i] = 731;
              break;
            case 'APP_IMAGE_GALLERY_ITEMS_CLICK':
            case 780:
              message.types[i] = 780;
              break;
          }
      }
      switch (object.platform) {
        case 'UNKNOWN_PLATFORM':
        case 0:
          message.platform = 0;
          break;
        case 'TB':
        case 1:
          message.platform = 1;
          break;
        default:
          message.platform = 1;
          break;
      }
      if (object.timestamp != null)
        if ($util.Long)
          (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
        else if (typeof object.timestamp === 'string')
          message.timestamp = parseInt(object.timestamp, 10);
        else if (typeof object.timestamp === 'number') message.timestamp = object.timestamp;
        else if (typeof object.timestamp === 'object')
          message.timestamp = new $util.LongBits(
            object.timestamp.low >>> 0,
            object.timestamp.high >>> 0,
          ).toNumber(true);
      if (object.pageView != null) {
        if (typeof object.pageView !== 'object')
          throw TypeError('.proto_stats.StatEvent.pageView: object expected');
        message.pageView = $root.proto_stats.PageViewPayload.fromObject(object.pageView);
      }
      if (object.cardView != null) {
        if (typeof object.cardView !== 'object')
          throw TypeError('.proto_stats.StatEvent.cardView: object expected');
        message.cardView = $root.proto_stats.CardViewPayload.fromObject(object.cardView);
      }
      if (object.cardClick != null) {
        if (typeof object.cardClick !== 'object')
          throw TypeError('.proto_stats.StatEvent.cardClick: object expected');
        message.cardClick = $root.proto_stats.CardClickPayload.fromObject(object.cardClick);
      }
      if (object.cardSubmit != null) {
        if (typeof object.cardSubmit !== 'object')
          throw TypeError('.proto_stats.StatEvent.cardSubmit: object expected');
        message.cardSubmit = $root.proto_stats.CardSubmitPayload.fromObject(object.cardSubmit);
      }
      if (object.instagramLinkClick != null) {
        if (typeof object.instagramLinkClick !== 'object')
          throw TypeError('.proto_stats.StatEvent.instagramLinkClick: object expected');
        message.instagramLinkClick = $root.proto_stats.InstagramLinkClickPayload.fromObject(
          object.instagramLinkClick,
        );
      }
      if (object.instagramFollowers != null) {
        if (typeof object.instagramFollowers !== 'object')
          throw TypeError('.proto_stats.StatEvent.instagramFollowers: object expected');
        message.instagramFollowers = $root.proto_stats.InstagramFollowersPayload.fromObject(
          object.instagramFollowers,
        );
      }
      if (object.youtubeVideoPlay != null) {
        if (typeof object.youtubeVideoPlay !== 'object')
          throw TypeError('.proto_stats.StatEvent.youtubeVideoPlay: object expected');
        message.youtubeVideoPlay = $root.proto_stats.YoutubeVideoPlayPayload.fromObject(
          object.youtubeVideoPlay,
        );
      }
      if (object.twitterTweetClick != null) {
        if (typeof object.twitterTweetClick !== 'object')
          throw TypeError('.proto_stats.StatEvent.twitterTweetClick: object expected');
        message.twitterTweetClick = $root.proto_stats.TwitterTweetClickPayload.fromObject(
          object.twitterTweetClick,
        );
      }
      if (object.imageGalleryItemClick != null) {
        if (typeof object.imageGalleryItemClick !== 'object')
          throw TypeError('.proto_stats.StatEvent.imageGalleryItemClick: object expected');
        message.imageGalleryItemClick = $root.proto_stats.ImageGalleryItemClickPayload.fromObject(
          object.imageGalleryItemClick,
        );
      }
      if (object.indexOptions != null) {
        if (typeof object.indexOptions !== 'object')
          throw TypeError('.proto_stats.StatEvent.indexOptions: object expected');
        message.indexOptions = $root.proto_stats.IndexOptions.fromObject(object.indexOptions);
      }
      return message;
    };

    /**
     * Creates a plain object from a StatEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.StatEvent
     * @static
     * @param {proto_stats.StatEvent} message StatEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StatEvent.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.arrays || options.defaults) object.types = [];
      if (options.defaults) {
        object.platform = options.enums === String ? 'UNKNOWN_PLATFORM' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.timestamp =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.timestamp = options.longs === String ? '0' : 0;
        object.indexOptions = null;
      }
      if (message.types && message.types.length) {
        object.types = [];
        for (let j = 0; j < message.types.length; ++j)
          object.types[j] =
            options.enums === String
              ? $root.proto_stats.StatType[message.types[j]]
              : message.types[j];
      }
      if (message.platform != null && message.hasOwnProperty('platform'))
        object.platform =
          options.enums === String
            ? $root.proto_stats.Platform[message.platform]
            : message.platform;
      if (message.timestamp != null && message.hasOwnProperty('timestamp'))
        if (typeof message.timestamp === 'number')
          object.timestamp =
            options.longs === String ? String(message.timestamp) : message.timestamp;
        else
          object.timestamp =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.timestamp)
              : options.longs === Number
              ? new $util.LongBits(
                  message.timestamp.low >>> 0,
                  message.timestamp.high >>> 0,
                ).toNumber(true)
              : message.timestamp;
      if (message.pageView != null && message.hasOwnProperty('pageView')) {
        object.pageView = $root.proto_stats.PageViewPayload.toObject(message.pageView, options);
        if (options.oneofs) object.payload = 'pageView';
      }
      if (message.cardView != null && message.hasOwnProperty('cardView')) {
        object.cardView = $root.proto_stats.CardViewPayload.toObject(message.cardView, options);
        if (options.oneofs) object.payload = 'cardView';
      }
      if (message.cardClick != null && message.hasOwnProperty('cardClick')) {
        object.cardClick = $root.proto_stats.CardClickPayload.toObject(message.cardClick, options);
        if (options.oneofs) object.payload = 'cardClick';
      }
      if (message.cardSubmit != null && message.hasOwnProperty('cardSubmit')) {
        object.cardSubmit = $root.proto_stats.CardSubmitPayload.toObject(
          message.cardSubmit,
          options,
        );
        if (options.oneofs) object.payload = 'cardSubmit';
      }
      if (message.instagramLinkClick != null && message.hasOwnProperty('instagramLinkClick')) {
        object.instagramLinkClick = $root.proto_stats.InstagramLinkClickPayload.toObject(
          message.instagramLinkClick,
          options,
        );
        if (options.oneofs) object.payload = 'instagramLinkClick';
      }
      if (message.instagramFollowers != null && message.hasOwnProperty('instagramFollowers')) {
        object.instagramFollowers = $root.proto_stats.InstagramFollowersPayload.toObject(
          message.instagramFollowers,
          options,
        );
        if (options.oneofs) object.payload = 'instagramFollowers';
      }
      if (message.youtubeVideoPlay != null && message.hasOwnProperty('youtubeVideoPlay')) {
        object.youtubeVideoPlay = $root.proto_stats.YoutubeVideoPlayPayload.toObject(
          message.youtubeVideoPlay,
          options,
        );
        if (options.oneofs) object.payload = 'youtubeVideoPlay';
      }
      if (message.twitterTweetClick != null && message.hasOwnProperty('twitterTweetClick')) {
        object.twitterTweetClick = $root.proto_stats.TwitterTweetClickPayload.toObject(
          message.twitterTweetClick,
          options,
        );
        if (options.oneofs) object.payload = 'twitterTweetClick';
      }
      if (
        message.imageGalleryItemClick != null &&
        message.hasOwnProperty('imageGalleryItemClick')
      ) {
        object.imageGalleryItemClick = $root.proto_stats.ImageGalleryItemClickPayload.toObject(
          message.imageGalleryItemClick,
          options,
        );
        if (options.oneofs) object.payload = 'imageGalleryItemClick';
      }
      if (message.indexOptions != null && message.hasOwnProperty('indexOptions'))
        object.indexOptions = $root.proto_stats.IndexOptions.toObject(
          message.indexOptions,
          options,
        );
      return object;
    };

    /**
     * Converts this StatEvent to JSON.
     * @function toJSON
     * @memberof proto_stats.StatEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StatEvent.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return StatEvent;
  })();

  proto_stats.IndexOptions = (function() {
    /**
     * Properties of an IndexOptions.
     * @memberof proto_stats
     * @interface IIndexOptions
     * @property {Array.<proto_stats.IndexType>|null} [indexes] IndexOptions indexes
     * @property {boolean|null} [storeEventLog] IndexOptions storeEventLog
     * @property {boolean|null} [batching] IndexOptions batching
     * @property {number|Long|null} [identityId] IndexOptions identityId
     * @property {string|null} [identityKey] IndexOptions identityKey
     */

    /**
     * Constructs a new IndexOptions.
     * @memberof proto_stats
     * @classdesc Represents an IndexOptions.
     * @implements IIndexOptions
     * @constructor
     * @param {proto_stats.IIndexOptions=} [properties] Properties to set
     */
    function IndexOptions(properties) {
      this.indexes = [];
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * IndexOptions indexes.
     * @member {Array.<proto_stats.IndexType>} indexes
     * @memberof proto_stats.IndexOptions
     * @instance
     */
    IndexOptions.prototype.indexes = $util.emptyArray;

    /**
     * IndexOptions storeEventLog.
     * @member {boolean} storeEventLog
     * @memberof proto_stats.IndexOptions
     * @instance
     */
    IndexOptions.prototype.storeEventLog = false;

    /**
     * IndexOptions batching.
     * @member {boolean} batching
     * @memberof proto_stats.IndexOptions
     * @instance
     */
    IndexOptions.prototype.batching = false;

    /**
     * IndexOptions identityId.
     * @member {number|Long} identityId
     * @memberof proto_stats.IndexOptions
     * @instance
     */
    IndexOptions.prototype.identityId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * IndexOptions identityKey.
     * @member {string} identityKey
     * @memberof proto_stats.IndexOptions
     * @instance
     */
    IndexOptions.prototype.identityKey = '';

    /**
     * Creates a new IndexOptions instance using the specified properties.
     * @function create
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {proto_stats.IIndexOptions=} [properties] Properties to set
     * @returns {proto_stats.IndexOptions} IndexOptions instance
     */
    IndexOptions.create = function create(properties) {
      return new IndexOptions(properties);
    };

    /**
     * Encodes the specified IndexOptions message. Does not implicitly {@link proto_stats.IndexOptions.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {proto_stats.IIndexOptions} message IndexOptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IndexOptions.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.indexes != null && message.indexes.length) {
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
        for (let i = 0; i < message.indexes.length; ++i) writer.int32(message.indexes[i]);
        writer.ldelim();
      }
      if (message.storeEventLog != null && message.hasOwnProperty('storeEventLog'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.storeEventLog);
      if (message.batching != null && message.hasOwnProperty('batching'))
        writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.batching);
      if (message.identityId != null && message.hasOwnProperty('identityId'))
        writer.uint32(/* id 10, wireType 0 =*/ 80).uint64(message.identityId);
      if (message.identityKey != null && message.hasOwnProperty('identityKey'))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.identityKey);
      return writer;
    };

    /**
     * Encodes the specified IndexOptions message, length delimited. Does not implicitly {@link proto_stats.IndexOptions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {proto_stats.IIndexOptions} message IndexOptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IndexOptions.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IndexOptions message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.IndexOptions} IndexOptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IndexOptions.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.IndexOptions();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (!(message.indexes && message.indexes.length)) message.indexes = [];
            if ((tag & 7) === 2) {
              let end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.indexes.push(reader.int32());
            } else message.indexes.push(reader.int32());
            break;
          case 2:
            message.storeEventLog = reader.bool();
            break;
          case 3:
            message.batching = reader.bool();
            break;
          case 10:
            message.identityId = reader.uint64();
            break;
          case 11:
            message.identityKey = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an IndexOptions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.IndexOptions} IndexOptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IndexOptions.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IndexOptions message.
     * @function verify
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IndexOptions.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.indexes != null && message.hasOwnProperty('indexes')) {
        if (!Array.isArray(message.indexes)) return 'indexes: array expected';
        for (let i = 0; i < message.indexes.length; ++i)
          switch (message.indexes[i]) {
            default:
              return 'indexes: enum value[] expected';
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 10:
            case 11:
            case 12:
            case 90:
              break;
          }
      }
      if (message.storeEventLog != null && message.hasOwnProperty('storeEventLog'))
        if (typeof message.storeEventLog !== 'boolean') return 'storeEventLog: boolean expected';
      if (message.batching != null && message.hasOwnProperty('batching'))
        if (typeof message.batching !== 'boolean') return 'batching: boolean expected';
      if (message.identityId != null && message.hasOwnProperty('identityId'))
        if (
          !$util.isInteger(message.identityId) &&
          !(
            message.identityId &&
            $util.isInteger(message.identityId.low) &&
            $util.isInteger(message.identityId.high)
          )
        )
          return 'identityId: integer|Long expected';
      if (message.identityKey != null && message.hasOwnProperty('identityKey'))
        if (!$util.isString(message.identityKey)) return 'identityKey: string expected';
      return null;
    };

    /**
     * Creates an IndexOptions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.IndexOptions} IndexOptions
     */
    IndexOptions.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.IndexOptions) return object;
      let message = new $root.proto_stats.IndexOptions();
      if (object.indexes) {
        if (!Array.isArray(object.indexes))
          throw TypeError('.proto_stats.IndexOptions.indexes: array expected');
        message.indexes = [];
        for (let i = 0; i < object.indexes.length; ++i)
          switch (object.indexes[i]) {
            default:
            case 'UNKNOWN_INDEX':
            case 0:
              message.indexes[i] = 0;
              break;
            case 'DAILY':
            case 1:
              message.indexes[i] = 1;
              break;
            case 'HOURLY':
            case 2:
              message.indexes[i] = 2;
              break;
            case 'WEEKLY':
            case 3:
              message.indexes[i] = 3;
              break;
            case 'MONTHLY':
            case 4:
              message.indexes[i] = 4;
              break;
            case 'YEARLY':
            case 5:
              message.indexes[i] = 5;
              break;
            case 'LAST_7_DAYS':
            case 10:
              message.indexes[i] = 10;
              break;
            case 'LAST_14_DAYS':
            case 11:
              message.indexes[i] = 11;
              break;
            case 'LAST_MONTH':
            case 12:
              message.indexes[i] = 12;
              break;
            case 'TIMELESS':
            case 90:
              message.indexes[i] = 90;
              break;
          }
      }
      if (object.storeEventLog != null) message.storeEventLog = Boolean(object.storeEventLog);
      if (object.batching != null) message.batching = Boolean(object.batching);
      if (object.identityId != null)
        if ($util.Long)
          (message.identityId = $util.Long.fromValue(object.identityId)).unsigned = true;
        else if (typeof object.identityId === 'string')
          message.identityId = parseInt(object.identityId, 10);
        else if (typeof object.identityId === 'number') message.identityId = object.identityId;
        else if (typeof object.identityId === 'object')
          message.identityId = new $util.LongBits(
            object.identityId.low >>> 0,
            object.identityId.high >>> 0,
          ).toNumber(true);
      if (object.identityKey != null) message.identityKey = String(object.identityKey);
      return message;
    };

    /**
     * Creates a plain object from an IndexOptions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.IndexOptions
     * @static
     * @param {proto_stats.IndexOptions} message IndexOptions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IndexOptions.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.arrays || options.defaults) object.indexes = [];
      if (options.defaults) {
        object.storeEventLog = false;
        object.batching = false;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.identityId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.identityId = options.longs === String ? '0' : 0;
        object.identityKey = '';
      }
      if (message.indexes && message.indexes.length) {
        object.indexes = [];
        for (let j = 0; j < message.indexes.length; ++j)
          object.indexes[j] =
            options.enums === String
              ? $root.proto_stats.IndexType[message.indexes[j]]
              : message.indexes[j];
      }
      if (message.storeEventLog != null && message.hasOwnProperty('storeEventLog'))
        object.storeEventLog = message.storeEventLog;
      if (message.batching != null && message.hasOwnProperty('batching'))
        object.batching = message.batching;
      if (message.identityId != null && message.hasOwnProperty('identityId'))
        if (typeof message.identityId === 'number')
          object.identityId =
            options.longs === String ? String(message.identityId) : message.identityId;
        else
          object.identityId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.identityId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.identityId.low >>> 0,
                  message.identityId.high >>> 0,
                ).toNumber(true)
              : message.identityId;
      if (message.identityKey != null && message.hasOwnProperty('identityKey'))
        object.identityKey = message.identityKey;
      return object;
    };

    /**
     * Converts this IndexOptions to JSON.
     * @function toJSON
     * @memberof proto_stats.IndexOptions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IndexOptions.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IndexOptions;
  })();

  proto_stats.PageViewPayload = (function() {
    /**
     * Properties of a PageViewPayload.
     * @memberof proto_stats
     * @interface IPageViewPayload
     * @property {number|Long|null} [accountId] PageViewPayload accountId
     */

    /**
     * Constructs a new PageViewPayload.
     * @memberof proto_stats
     * @classdesc Represents a PageViewPayload.
     * @implements IPageViewPayload
     * @constructor
     * @param {proto_stats.IPageViewPayload=} [properties] Properties to set
     */
    function PageViewPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PageViewPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.PageViewPayload
     * @instance
     */
    PageViewPayload.prototype.accountId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * Creates a new PageViewPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {proto_stats.IPageViewPayload=} [properties] Properties to set
     * @returns {proto_stats.PageViewPayload} PageViewPayload instance
     */
    PageViewPayload.create = function create(properties) {
      return new PageViewPayload(properties);
    };

    /**
     * Encodes the specified PageViewPayload message. Does not implicitly {@link proto_stats.PageViewPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {proto_stats.IPageViewPayload} message PageViewPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageViewPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      return writer;
    };

    /**
     * Encodes the specified PageViewPayload message, length delimited. Does not implicitly {@link proto_stats.PageViewPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {proto_stats.IPageViewPayload} message PageViewPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PageViewPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PageViewPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.PageViewPayload} PageViewPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageViewPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.PageViewPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PageViewPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.PageViewPayload} PageViewPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PageViewPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PageViewPayload message.
     * @function verify
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PageViewPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      return null;
    };

    /**
     * Creates a PageViewPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.PageViewPayload} PageViewPayload
     */
    PageViewPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.PageViewPayload) return object;
      let message = new $root.proto_stats.PageViewPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a PageViewPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.PageViewPayload
     * @static
     * @param {proto_stats.PageViewPayload} message PageViewPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PageViewPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults)
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      return object;
    };

    /**
     * Converts this PageViewPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.PageViewPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PageViewPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return PageViewPayload;
  })();

  proto_stats.CardViewPayload = (function() {
    /**
     * Properties of a CardViewPayload.
     * @memberof proto_stats
     * @interface ICardViewPayload
     * @property {number|Long|null} [accountId] CardViewPayload accountId
     * @property {number|Long|null} [cardId] CardViewPayload cardId
     */

    /**
     * Constructs a new CardViewPayload.
     * @memberof proto_stats
     * @classdesc Represents a CardViewPayload.
     * @implements ICardViewPayload
     * @constructor
     * @param {proto_stats.ICardViewPayload=} [properties] Properties to set
     */
    function CardViewPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CardViewPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.CardViewPayload
     * @instance
     */
    CardViewPayload.prototype.accountId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * CardViewPayload cardId.
     * @member {number|Long} cardId
     * @memberof proto_stats.CardViewPayload
     * @instance
     */
    CardViewPayload.prototype.cardId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * Creates a new CardViewPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {proto_stats.ICardViewPayload=} [properties] Properties to set
     * @returns {proto_stats.CardViewPayload} CardViewPayload instance
     */
    CardViewPayload.create = function create(properties) {
      return new CardViewPayload(properties);
    };

    /**
     * Encodes the specified CardViewPayload message. Does not implicitly {@link proto_stats.CardViewPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {proto_stats.ICardViewPayload} message CardViewPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CardViewPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.cardId);
      return writer;
    };

    /**
     * Encodes the specified CardViewPayload message, length delimited. Does not implicitly {@link proto_stats.CardViewPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {proto_stats.ICardViewPayload} message CardViewPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CardViewPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CardViewPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.CardViewPayload} CardViewPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CardViewPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.CardViewPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.cardId = reader.uint64();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CardViewPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.CardViewPayload} CardViewPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CardViewPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CardViewPayload message.
     * @function verify
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CardViewPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (
          !$util.isInteger(message.cardId) &&
          !(
            message.cardId &&
            $util.isInteger(message.cardId.low) &&
            $util.isInteger(message.cardId.high)
          )
        )
          return 'cardId: integer|Long expected';
      return null;
    };

    /**
     * Creates a CardViewPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.CardViewPayload} CardViewPayload
     */
    CardViewPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.CardViewPayload) return object;
      let message = new $root.proto_stats.CardViewPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.cardId != null)
        if ($util.Long) (message.cardId = $util.Long.fromValue(object.cardId)).unsigned = true;
        else if (typeof object.cardId === 'string') message.cardId = parseInt(object.cardId, 10);
        else if (typeof object.cardId === 'number') message.cardId = object.cardId;
        else if (typeof object.cardId === 'object')
          message.cardId = new $util.LongBits(
            object.cardId.low >>> 0,
            object.cardId.high >>> 0,
          ).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a CardViewPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.CardViewPayload
     * @static
     * @param {proto_stats.CardViewPayload} message CardViewPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CardViewPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.cardId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.cardId = options.longs === String ? '0' : 0;
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (typeof message.cardId === 'number')
          object.cardId = options.longs === String ? String(message.cardId) : message.cardId;
        else
          object.cardId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cardId)
              : options.longs === Number
              ? new $util.LongBits(message.cardId.low >>> 0, message.cardId.high >>> 0).toNumber(
                  true,
                )
              : message.cardId;
      return object;
    };

    /**
     * Converts this CardViewPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.CardViewPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CardViewPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CardViewPayload;
  })();

  proto_stats.CardClickPayload = (function() {
    /**
     * Properties of a CardClickPayload.
     * @memberof proto_stats
     * @interface ICardClickPayload
     * @property {number|Long|null} [accountId] CardClickPayload accountId
     * @property {number|Long|null} [cardId] CardClickPayload cardId
     * @property {number|Long|null} [ctaId] CardClickPayload ctaId
     * @property {string|null} [url] CardClickPayload url
     */

    /**
     * Constructs a new CardClickPayload.
     * @memberof proto_stats
     * @classdesc Represents a CardClickPayload.
     * @implements ICardClickPayload
     * @constructor
     * @param {proto_stats.ICardClickPayload=} [properties] Properties to set
     */
    function CardClickPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CardClickPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.CardClickPayload
     * @instance
     */
    CardClickPayload.prototype.accountId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * CardClickPayload cardId.
     * @member {number|Long} cardId
     * @memberof proto_stats.CardClickPayload
     * @instance
     */
    CardClickPayload.prototype.cardId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * CardClickPayload ctaId.
     * @member {number|Long} ctaId
     * @memberof proto_stats.CardClickPayload
     * @instance
     */
    CardClickPayload.prototype.ctaId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * CardClickPayload url.
     * @member {string} url
     * @memberof proto_stats.CardClickPayload
     * @instance
     */
    CardClickPayload.prototype.url = '';

    /**
     * Creates a new CardClickPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {proto_stats.ICardClickPayload=} [properties] Properties to set
     * @returns {proto_stats.CardClickPayload} CardClickPayload instance
     */
    CardClickPayload.create = function create(properties) {
      return new CardClickPayload(properties);
    };

    /**
     * Encodes the specified CardClickPayload message. Does not implicitly {@link proto_stats.CardClickPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {proto_stats.ICardClickPayload} message CardClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CardClickPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.cardId);
      if (message.ctaId != null && message.hasOwnProperty('ctaId'))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.ctaId);
      if (message.url != null && message.hasOwnProperty('url'))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.url);
      return writer;
    };

    /**
     * Encodes the specified CardClickPayload message, length delimited. Does not implicitly {@link proto_stats.CardClickPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {proto_stats.ICardClickPayload} message CardClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CardClickPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CardClickPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.CardClickPayload} CardClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CardClickPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.CardClickPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.cardId = reader.uint64();
            break;
          case 3:
            message.ctaId = reader.uint64();
            break;
          case 10:
            message.url = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CardClickPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.CardClickPayload} CardClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CardClickPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CardClickPayload message.
     * @function verify
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CardClickPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (
          !$util.isInteger(message.cardId) &&
          !(
            message.cardId &&
            $util.isInteger(message.cardId.low) &&
            $util.isInteger(message.cardId.high)
          )
        )
          return 'cardId: integer|Long expected';
      if (message.ctaId != null && message.hasOwnProperty('ctaId'))
        if (
          !$util.isInteger(message.ctaId) &&
          !(
            message.ctaId &&
            $util.isInteger(message.ctaId.low) &&
            $util.isInteger(message.ctaId.high)
          )
        )
          return 'ctaId: integer|Long expected';
      if (message.url != null && message.hasOwnProperty('url'))
        if (!$util.isString(message.url)) return 'url: string expected';
      return null;
    };

    /**
     * Creates a CardClickPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.CardClickPayload} CardClickPayload
     */
    CardClickPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.CardClickPayload) return object;
      let message = new $root.proto_stats.CardClickPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.cardId != null)
        if ($util.Long) (message.cardId = $util.Long.fromValue(object.cardId)).unsigned = true;
        else if (typeof object.cardId === 'string') message.cardId = parseInt(object.cardId, 10);
        else if (typeof object.cardId === 'number') message.cardId = object.cardId;
        else if (typeof object.cardId === 'object')
          message.cardId = new $util.LongBits(
            object.cardId.low >>> 0,
            object.cardId.high >>> 0,
          ).toNumber(true);
      if (object.ctaId != null)
        if ($util.Long) (message.ctaId = $util.Long.fromValue(object.ctaId)).unsigned = true;
        else if (typeof object.ctaId === 'string') message.ctaId = parseInt(object.ctaId, 10);
        else if (typeof object.ctaId === 'number') message.ctaId = object.ctaId;
        else if (typeof object.ctaId === 'object')
          message.ctaId = new $util.LongBits(
            object.ctaId.low >>> 0,
            object.ctaId.high >>> 0,
          ).toNumber(true);
      if (object.url != null) message.url = String(object.url);
      return message;
    };

    /**
     * Creates a plain object from a CardClickPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.CardClickPayload
     * @static
     * @param {proto_stats.CardClickPayload} message CardClickPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CardClickPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.cardId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.cardId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.ctaId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.ctaId = options.longs === String ? '0' : 0;
        object.url = '';
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (typeof message.cardId === 'number')
          object.cardId = options.longs === String ? String(message.cardId) : message.cardId;
        else
          object.cardId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cardId)
              : options.longs === Number
              ? new $util.LongBits(message.cardId.low >>> 0, message.cardId.high >>> 0).toNumber(
                  true,
                )
              : message.cardId;
      if (message.ctaId != null && message.hasOwnProperty('ctaId'))
        if (typeof message.ctaId === 'number')
          object.ctaId = options.longs === String ? String(message.ctaId) : message.ctaId;
        else
          object.ctaId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.ctaId)
              : options.longs === Number
              ? new $util.LongBits(message.ctaId.low >>> 0, message.ctaId.high >>> 0).toNumber(true)
              : message.ctaId;
      if (message.url != null && message.hasOwnProperty('url')) object.url = message.url;
      return object;
    };

    /**
     * Converts this CardClickPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.CardClickPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CardClickPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CardClickPayload;
  })();

  proto_stats.CardSubmitPayload = (function() {
    /**
     * Properties of a CardSubmitPayload.
     * @memberof proto_stats
     * @interface ICardSubmitPayload
     * @property {number|Long|null} [accountId] CardSubmitPayload accountId
     * @property {number|Long|null} [cardId] CardSubmitPayload cardId
     */

    /**
     * Constructs a new CardSubmitPayload.
     * @memberof proto_stats
     * @classdesc Represents a CardSubmitPayload.
     * @implements ICardSubmitPayload
     * @constructor
     * @param {proto_stats.ICardSubmitPayload=} [properties] Properties to set
     */
    function CardSubmitPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CardSubmitPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.CardSubmitPayload
     * @instance
     */
    CardSubmitPayload.prototype.accountId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * CardSubmitPayload cardId.
     * @member {number|Long} cardId
     * @memberof proto_stats.CardSubmitPayload
     * @instance
     */
    CardSubmitPayload.prototype.cardId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * Creates a new CardSubmitPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {proto_stats.ICardSubmitPayload=} [properties] Properties to set
     * @returns {proto_stats.CardSubmitPayload} CardSubmitPayload instance
     */
    CardSubmitPayload.create = function create(properties) {
      return new CardSubmitPayload(properties);
    };

    /**
     * Encodes the specified CardSubmitPayload message. Does not implicitly {@link proto_stats.CardSubmitPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {proto_stats.ICardSubmitPayload} message CardSubmitPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CardSubmitPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.cardId);
      return writer;
    };

    /**
     * Encodes the specified CardSubmitPayload message, length delimited. Does not implicitly {@link proto_stats.CardSubmitPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {proto_stats.ICardSubmitPayload} message CardSubmitPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CardSubmitPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CardSubmitPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.CardSubmitPayload} CardSubmitPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CardSubmitPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.CardSubmitPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.cardId = reader.uint64();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CardSubmitPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.CardSubmitPayload} CardSubmitPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CardSubmitPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CardSubmitPayload message.
     * @function verify
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CardSubmitPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (
          !$util.isInteger(message.cardId) &&
          !(
            message.cardId &&
            $util.isInteger(message.cardId.low) &&
            $util.isInteger(message.cardId.high)
          )
        )
          return 'cardId: integer|Long expected';
      return null;
    };

    /**
     * Creates a CardSubmitPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.CardSubmitPayload} CardSubmitPayload
     */
    CardSubmitPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.CardSubmitPayload) return object;
      let message = new $root.proto_stats.CardSubmitPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.cardId != null)
        if ($util.Long) (message.cardId = $util.Long.fromValue(object.cardId)).unsigned = true;
        else if (typeof object.cardId === 'string') message.cardId = parseInt(object.cardId, 10);
        else if (typeof object.cardId === 'number') message.cardId = object.cardId;
        else if (typeof object.cardId === 'object')
          message.cardId = new $util.LongBits(
            object.cardId.low >>> 0,
            object.cardId.high >>> 0,
          ).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a CardSubmitPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.CardSubmitPayload
     * @static
     * @param {proto_stats.CardSubmitPayload} message CardSubmitPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CardSubmitPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.cardId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.cardId = options.longs === String ? '0' : 0;
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (typeof message.cardId === 'number')
          object.cardId = options.longs === String ? String(message.cardId) : message.cardId;
        else
          object.cardId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cardId)
              : options.longs === Number
              ? new $util.LongBits(message.cardId.low >>> 0, message.cardId.high >>> 0).toNumber(
                  true,
                )
              : message.cardId;
      return object;
    };

    /**
     * Converts this CardSubmitPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.CardSubmitPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CardSubmitPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CardSubmitPayload;
  })();

  proto_stats.InstagramLinkClickPayload = (function() {
    /**
     * Properties of an InstagramLinkClickPayload.
     * @memberof proto_stats
     * @interface IInstagramLinkClickPayload
     * @property {number|Long|null} [accountId] InstagramLinkClickPayload accountId
     * @property {string|null} [url] InstagramLinkClickPayload url
     * @property {string|null} [instagramPhotoId] InstagramLinkClickPayload instagramPhotoId
     * @property {string|null} [instagramPermalink] InstagramLinkClickPayload instagramPermalink
     * @property {proto_stats.InstagramLinkClickPayload.ViewType|null} [viewType] InstagramLinkClickPayload viewType
     */

    /**
     * Constructs a new InstagramLinkClickPayload.
     * @memberof proto_stats
     * @classdesc Represents an InstagramLinkClickPayload.
     * @implements IInstagramLinkClickPayload
     * @constructor
     * @param {proto_stats.IInstagramLinkClickPayload=} [properties] Properties to set
     */
    function InstagramLinkClickPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * InstagramLinkClickPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.InstagramLinkClickPayload
     * @instance
     */
    InstagramLinkClickPayload.prototype.accountId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0;

    /**
     * InstagramLinkClickPayload url.
     * @member {string} url
     * @memberof proto_stats.InstagramLinkClickPayload
     * @instance
     */
    InstagramLinkClickPayload.prototype.url = '';

    /**
     * InstagramLinkClickPayload instagramPhotoId.
     * @member {string} instagramPhotoId
     * @memberof proto_stats.InstagramLinkClickPayload
     * @instance
     */
    InstagramLinkClickPayload.prototype.instagramPhotoId = '';

    /**
     * InstagramLinkClickPayload instagramPermalink.
     * @member {string} instagramPermalink
     * @memberof proto_stats.InstagramLinkClickPayload
     * @instance
     */
    InstagramLinkClickPayload.prototype.instagramPermalink = '';

    /**
     * InstagramLinkClickPayload viewType.
     * @member {proto_stats.InstagramLinkClickPayload.ViewType} viewType
     * @memberof proto_stats.InstagramLinkClickPayload
     * @instance
     */
    InstagramLinkClickPayload.prototype.viewType = 0;

    /**
     * Creates a new InstagramLinkClickPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {proto_stats.IInstagramLinkClickPayload=} [properties] Properties to set
     * @returns {proto_stats.InstagramLinkClickPayload} InstagramLinkClickPayload instance
     */
    InstagramLinkClickPayload.create = function create(properties) {
      return new InstagramLinkClickPayload(properties);
    };

    /**
     * Encodes the specified InstagramLinkClickPayload message. Does not implicitly {@link proto_stats.InstagramLinkClickPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {proto_stats.IInstagramLinkClickPayload} message InstagramLinkClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramLinkClickPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.url != null && message.hasOwnProperty('url'))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.url);
      if (message.instagramPhotoId != null && message.hasOwnProperty('instagramPhotoId'))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.instagramPhotoId);
      if (message.instagramPermalink != null && message.hasOwnProperty('instagramPermalink'))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.instagramPermalink);
      if (message.viewType != null && message.hasOwnProperty('viewType'))
        writer.uint32(/* id 20, wireType 0 =*/ 160).int32(message.viewType);
      return writer;
    };

    /**
     * Encodes the specified InstagramLinkClickPayload message, length delimited. Does not implicitly {@link proto_stats.InstagramLinkClickPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {proto_stats.IInstagramLinkClickPayload} message InstagramLinkClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramLinkClickPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InstagramLinkClickPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.InstagramLinkClickPayload} InstagramLinkClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramLinkClickPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.InstagramLinkClickPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 10:
            message.url = reader.string();
            break;
          case 11:
            message.instagramPhotoId = reader.string();
            break;
          case 12:
            message.instagramPermalink = reader.string();
            break;
          case 20:
            message.viewType = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an InstagramLinkClickPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.InstagramLinkClickPayload} InstagramLinkClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramLinkClickPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InstagramLinkClickPayload message.
     * @function verify
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InstagramLinkClickPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.url != null && message.hasOwnProperty('url'))
        if (!$util.isString(message.url)) return 'url: string expected';
      if (message.instagramPhotoId != null && message.hasOwnProperty('instagramPhotoId'))
        if (!$util.isString(message.instagramPhotoId)) return 'instagramPhotoId: string expected';
      if (message.instagramPermalink != null && message.hasOwnProperty('instagramPermalink'))
        if (!$util.isString(message.instagramPermalink))
          return 'instagramPermalink: string expected';
      if (message.viewType != null && message.hasOwnProperty('viewType'))
        switch (message.viewType) {
          default:
            return 'viewType: enum value expected';
          case 0:
          case 1:
          case 2:
            break;
        }
      return null;
    };

    /**
     * Creates an InstagramLinkClickPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.InstagramLinkClickPayload} InstagramLinkClickPayload
     */
    InstagramLinkClickPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.InstagramLinkClickPayload) return object;
      let message = new $root.proto_stats.InstagramLinkClickPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.url != null) message.url = String(object.url);
      if (object.instagramPhotoId != null)
        message.instagramPhotoId = String(object.instagramPhotoId);
      if (object.instagramPermalink != null)
        message.instagramPermalink = String(object.instagramPermalink);
      switch (object.viewType) {
        case 'UNKNOWN':
        case 0:
          message.viewType = 0;
          break;
        case 'LIST':
        case 1:
          message.viewType = 1;
          break;
        case 'GRID':
        case 2:
          message.viewType = 2;
          break;
        default:
          message.viewType = 2;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from an InstagramLinkClickPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.InstagramLinkClickPayload
     * @static
     * @param {proto_stats.InstagramLinkClickPayload} message InstagramLinkClickPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InstagramLinkClickPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        object.url = '';
        object.instagramPhotoId = '';
        object.instagramPermalink = '';
        object.viewType = options.enums === String ? 'UNKNOWN' : 0;
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.url != null && message.hasOwnProperty('url')) object.url = message.url;
      if (message.instagramPhotoId != null && message.hasOwnProperty('instagramPhotoId'))
        object.instagramPhotoId = message.instagramPhotoId;
      if (message.instagramPermalink != null && message.hasOwnProperty('instagramPermalink'))
        object.instagramPermalink = message.instagramPermalink;
      if (message.viewType != null && message.hasOwnProperty('viewType'))
        object.viewType =
          options.enums === String
            ? $root.proto_stats.InstagramLinkClickPayload.ViewType[message.viewType]
            : message.viewType;
      return object;
    };

    /**
     * Converts this InstagramLinkClickPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.InstagramLinkClickPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InstagramLinkClickPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * ViewType enum.
     * @name proto_stats.InstagramLinkClickPayload.ViewType
     * @enum {string}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} LIST=1 LIST value
     * @property {number} GRID=2 GRID value
     */
    InstagramLinkClickPayload.ViewType = (function() {
      const valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = 'UNKNOWN')] = 0;
      values[(valuesById[1] = 'LIST')] = 1;
      values[(valuesById[2] = 'GRID')] = 2;
      return values;
    })();

    return InstagramLinkClickPayload;
  })();

  proto_stats.InstagramFollowersPayload = (function() {
    /**
     * Properties of an InstagramFollowersPayload.
     * @memberof proto_stats
     * @interface IInstagramFollowersPayload
     * @property {number|Long|null} [accountId] InstagramFollowersPayload accountId
     * @property {string|null} [instagramUserId] InstagramFollowersPayload instagramUserId
     * @property {number|Long|null} [instagramFollowerCount] InstagramFollowersPayload instagramFollowerCount
     */

    /**
     * Constructs a new InstagramFollowersPayload.
     * @memberof proto_stats
     * @classdesc Represents an InstagramFollowersPayload.
     * @implements IInstagramFollowersPayload
     * @constructor
     * @param {proto_stats.IInstagramFollowersPayload=} [properties] Properties to set
     */
    function InstagramFollowersPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * InstagramFollowersPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.InstagramFollowersPayload
     * @instance
     */
    InstagramFollowersPayload.prototype.accountId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0;

    /**
     * InstagramFollowersPayload instagramUserId.
     * @member {string} instagramUserId
     * @memberof proto_stats.InstagramFollowersPayload
     * @instance
     */
    InstagramFollowersPayload.prototype.instagramUserId = '';

    /**
     * InstagramFollowersPayload instagramFollowerCount.
     * @member {number|Long} instagramFollowerCount
     * @memberof proto_stats.InstagramFollowersPayload
     * @instance
     */
    InstagramFollowersPayload.prototype.instagramFollowerCount = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0;

    /**
     * Creates a new InstagramFollowersPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {proto_stats.IInstagramFollowersPayload=} [properties] Properties to set
     * @returns {proto_stats.InstagramFollowersPayload} InstagramFollowersPayload instance
     */
    InstagramFollowersPayload.create = function create(properties) {
      return new InstagramFollowersPayload(properties);
    };

    /**
     * Encodes the specified InstagramFollowersPayload message. Does not implicitly {@link proto_stats.InstagramFollowersPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {proto_stats.IInstagramFollowersPayload} message InstagramFollowersPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramFollowersPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.instagramUserId != null && message.hasOwnProperty('instagramUserId'))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.instagramUserId);
      if (
        message.instagramFollowerCount != null &&
        message.hasOwnProperty('instagramFollowerCount')
      )
        writer.uint32(/* id 10, wireType 0 =*/ 80).uint64(message.instagramFollowerCount);
      return writer;
    };

    /**
     * Encodes the specified InstagramFollowersPayload message, length delimited. Does not implicitly {@link proto_stats.InstagramFollowersPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {proto_stats.IInstagramFollowersPayload} message InstagramFollowersPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InstagramFollowersPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InstagramFollowersPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.InstagramFollowersPayload} InstagramFollowersPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramFollowersPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.InstagramFollowersPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.instagramUserId = reader.string();
            break;
          case 10:
            message.instagramFollowerCount = reader.uint64();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an InstagramFollowersPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.InstagramFollowersPayload} InstagramFollowersPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InstagramFollowersPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InstagramFollowersPayload message.
     * @function verify
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InstagramFollowersPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.instagramUserId != null && message.hasOwnProperty('instagramUserId'))
        if (!$util.isString(message.instagramUserId)) return 'instagramUserId: string expected';
      if (
        message.instagramFollowerCount != null &&
        message.hasOwnProperty('instagramFollowerCount')
      )
        if (
          !$util.isInteger(message.instagramFollowerCount) &&
          !(
            message.instagramFollowerCount &&
            $util.isInteger(message.instagramFollowerCount.low) &&
            $util.isInteger(message.instagramFollowerCount.high)
          )
        )
          return 'instagramFollowerCount: integer|Long expected';
      return null;
    };

    /**
     * Creates an InstagramFollowersPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.InstagramFollowersPayload} InstagramFollowersPayload
     */
    InstagramFollowersPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.InstagramFollowersPayload) return object;
      let message = new $root.proto_stats.InstagramFollowersPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.instagramUserId != null) message.instagramUserId = String(object.instagramUserId);
      if (object.instagramFollowerCount != null)
        if ($util.Long)
          (message.instagramFollowerCount = $util.Long.fromValue(
            object.instagramFollowerCount,
          )).unsigned = true;
        else if (typeof object.instagramFollowerCount === 'string')
          message.instagramFollowerCount = parseInt(object.instagramFollowerCount, 10);
        else if (typeof object.instagramFollowerCount === 'number')
          message.instagramFollowerCount = object.instagramFollowerCount;
        else if (typeof object.instagramFollowerCount === 'object')
          message.instagramFollowerCount = new $util.LongBits(
            object.instagramFollowerCount.low >>> 0,
            object.instagramFollowerCount.high >>> 0,
          ).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from an InstagramFollowersPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.InstagramFollowersPayload
     * @static
     * @param {proto_stats.InstagramFollowersPayload} message InstagramFollowersPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InstagramFollowersPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        object.instagramUserId = '';
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.instagramFollowerCount =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.instagramFollowerCount = options.longs === String ? '0' : 0;
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.instagramUserId != null && message.hasOwnProperty('instagramUserId'))
        object.instagramUserId = message.instagramUserId;
      if (
        message.instagramFollowerCount != null &&
        message.hasOwnProperty('instagramFollowerCount')
      )
        if (typeof message.instagramFollowerCount === 'number')
          object.instagramFollowerCount =
            options.longs === String
              ? String(message.instagramFollowerCount)
              : message.instagramFollowerCount;
        else
          object.instagramFollowerCount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.instagramFollowerCount)
              : options.longs === Number
              ? new $util.LongBits(
                  message.instagramFollowerCount.low >>> 0,
                  message.instagramFollowerCount.high >>> 0,
                ).toNumber(true)
              : message.instagramFollowerCount;
      return object;
    };

    /**
     * Converts this InstagramFollowersPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.InstagramFollowersPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InstagramFollowersPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return InstagramFollowersPayload;
  })();

  proto_stats.YoutubeVideoPlayPayload = (function() {
    /**
     * Properties of a YoutubeVideoPlayPayload.
     * @memberof proto_stats
     * @interface IYoutubeVideoPlayPayload
     * @property {number|Long|null} [accountId] YoutubeVideoPlayPayload accountId
     * @property {number|Long|null} [cardId] YoutubeVideoPlayPayload cardId
     * @property {string|null} [videoId] YoutubeVideoPlayPayload videoId
     */

    /**
     * Constructs a new YoutubeVideoPlayPayload.
     * @memberof proto_stats
     * @classdesc Represents a YoutubeVideoPlayPayload.
     * @implements IYoutubeVideoPlayPayload
     * @constructor
     * @param {proto_stats.IYoutubeVideoPlayPayload=} [properties] Properties to set
     */
    function YoutubeVideoPlayPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * YoutubeVideoPlayPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @instance
     */
    YoutubeVideoPlayPayload.prototype.accountId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * YoutubeVideoPlayPayload cardId.
     * @member {number|Long} cardId
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @instance
     */
    YoutubeVideoPlayPayload.prototype.cardId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * YoutubeVideoPlayPayload videoId.
     * @member {string} videoId
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @instance
     */
    YoutubeVideoPlayPayload.prototype.videoId = '';

    /**
     * Creates a new YoutubeVideoPlayPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {proto_stats.IYoutubeVideoPlayPayload=} [properties] Properties to set
     * @returns {proto_stats.YoutubeVideoPlayPayload} YoutubeVideoPlayPayload instance
     */
    YoutubeVideoPlayPayload.create = function create(properties) {
      return new YoutubeVideoPlayPayload(properties);
    };

    /**
     * Encodes the specified YoutubeVideoPlayPayload message. Does not implicitly {@link proto_stats.YoutubeVideoPlayPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {proto_stats.IYoutubeVideoPlayPayload} message YoutubeVideoPlayPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    YoutubeVideoPlayPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.cardId);
      if (message.videoId != null && message.hasOwnProperty('videoId'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.videoId);
      return writer;
    };

    /**
     * Encodes the specified YoutubeVideoPlayPayload message, length delimited. Does not implicitly {@link proto_stats.YoutubeVideoPlayPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {proto_stats.IYoutubeVideoPlayPayload} message YoutubeVideoPlayPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    YoutubeVideoPlayPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a YoutubeVideoPlayPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.YoutubeVideoPlayPayload} YoutubeVideoPlayPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    YoutubeVideoPlayPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.YoutubeVideoPlayPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.cardId = reader.uint64();
            break;
          case 3:
            message.videoId = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a YoutubeVideoPlayPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.YoutubeVideoPlayPayload} YoutubeVideoPlayPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    YoutubeVideoPlayPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a YoutubeVideoPlayPayload message.
     * @function verify
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    YoutubeVideoPlayPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (
          !$util.isInteger(message.cardId) &&
          !(
            message.cardId &&
            $util.isInteger(message.cardId.low) &&
            $util.isInteger(message.cardId.high)
          )
        )
          return 'cardId: integer|Long expected';
      if (message.videoId != null && message.hasOwnProperty('videoId'))
        if (!$util.isString(message.videoId)) return 'videoId: string expected';
      return null;
    };

    /**
     * Creates a YoutubeVideoPlayPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.YoutubeVideoPlayPayload} YoutubeVideoPlayPayload
     */
    YoutubeVideoPlayPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.YoutubeVideoPlayPayload) return object;
      let message = new $root.proto_stats.YoutubeVideoPlayPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.cardId != null)
        if ($util.Long) (message.cardId = $util.Long.fromValue(object.cardId)).unsigned = true;
        else if (typeof object.cardId === 'string') message.cardId = parseInt(object.cardId, 10);
        else if (typeof object.cardId === 'number') message.cardId = object.cardId;
        else if (typeof object.cardId === 'object')
          message.cardId = new $util.LongBits(
            object.cardId.low >>> 0,
            object.cardId.high >>> 0,
          ).toNumber(true);
      if (object.videoId != null) message.videoId = String(object.videoId);
      return message;
    };

    /**
     * Creates a plain object from a YoutubeVideoPlayPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @static
     * @param {proto_stats.YoutubeVideoPlayPayload} message YoutubeVideoPlayPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    YoutubeVideoPlayPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.cardId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.cardId = options.longs === String ? '0' : 0;
        object.videoId = '';
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (typeof message.cardId === 'number')
          object.cardId = options.longs === String ? String(message.cardId) : message.cardId;
        else
          object.cardId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cardId)
              : options.longs === Number
              ? new $util.LongBits(message.cardId.low >>> 0, message.cardId.high >>> 0).toNumber(
                  true,
                )
              : message.cardId;
      if (message.videoId != null && message.hasOwnProperty('videoId'))
        object.videoId = message.videoId;
      return object;
    };

    /**
     * Converts this YoutubeVideoPlayPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.YoutubeVideoPlayPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    YoutubeVideoPlayPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return YoutubeVideoPlayPayload;
  })();

  proto_stats.TwitterTweetClickPayload = (function() {
    /**
     * Properties of a TwitterTweetClickPayload.
     * @memberof proto_stats
     * @interface ITwitterTweetClickPayload
     * @property {number|Long|null} [accountId] TwitterTweetClickPayload accountId
     * @property {number|Long|null} [cardId] TwitterTweetClickPayload cardId
     * @property {string|null} [tweetId] TwitterTweetClickPayload tweetId
     */

    /**
     * Constructs a new TwitterTweetClickPayload.
     * @memberof proto_stats
     * @classdesc Represents a TwitterTweetClickPayload.
     * @implements ITwitterTweetClickPayload
     * @constructor
     * @param {proto_stats.ITwitterTweetClickPayload=} [properties] Properties to set
     */
    function TwitterTweetClickPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TwitterTweetClickPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.TwitterTweetClickPayload
     * @instance
     */
    TwitterTweetClickPayload.prototype.accountId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * TwitterTweetClickPayload cardId.
     * @member {number|Long} cardId
     * @memberof proto_stats.TwitterTweetClickPayload
     * @instance
     */
    TwitterTweetClickPayload.prototype.cardId = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * TwitterTweetClickPayload tweetId.
     * @member {string} tweetId
     * @memberof proto_stats.TwitterTweetClickPayload
     * @instance
     */
    TwitterTweetClickPayload.prototype.tweetId = '';

    /**
     * Creates a new TwitterTweetClickPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {proto_stats.ITwitterTweetClickPayload=} [properties] Properties to set
     * @returns {proto_stats.TwitterTweetClickPayload} TwitterTweetClickPayload instance
     */
    TwitterTweetClickPayload.create = function create(properties) {
      return new TwitterTweetClickPayload(properties);
    };

    /**
     * Encodes the specified TwitterTweetClickPayload message. Does not implicitly {@link proto_stats.TwitterTweetClickPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {proto_stats.ITwitterTweetClickPayload} message TwitterTweetClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TwitterTweetClickPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.cardId);
      if (message.tweetId != null && message.hasOwnProperty('tweetId'))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.tweetId);
      return writer;
    };

    /**
     * Encodes the specified TwitterTweetClickPayload message, length delimited. Does not implicitly {@link proto_stats.TwitterTweetClickPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {proto_stats.ITwitterTweetClickPayload} message TwitterTweetClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TwitterTweetClickPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TwitterTweetClickPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.TwitterTweetClickPayload} TwitterTweetClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TwitterTweetClickPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.TwitterTweetClickPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.cardId = reader.uint64();
            break;
          case 3:
            message.tweetId = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TwitterTweetClickPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.TwitterTweetClickPayload} TwitterTweetClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TwitterTweetClickPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TwitterTweetClickPayload message.
     * @function verify
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TwitterTweetClickPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (
          !$util.isInteger(message.cardId) &&
          !(
            message.cardId &&
            $util.isInteger(message.cardId.low) &&
            $util.isInteger(message.cardId.high)
          )
        )
          return 'cardId: integer|Long expected';
      if (message.tweetId != null && message.hasOwnProperty('tweetId'))
        if (!$util.isString(message.tweetId)) return 'tweetId: string expected';
      return null;
    };

    /**
     * Creates a TwitterTweetClickPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.TwitterTweetClickPayload} TwitterTweetClickPayload
     */
    TwitterTweetClickPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.TwitterTweetClickPayload) return object;
      let message = new $root.proto_stats.TwitterTweetClickPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.cardId != null)
        if ($util.Long) (message.cardId = $util.Long.fromValue(object.cardId)).unsigned = true;
        else if (typeof object.cardId === 'string') message.cardId = parseInt(object.cardId, 10);
        else if (typeof object.cardId === 'number') message.cardId = object.cardId;
        else if (typeof object.cardId === 'object')
          message.cardId = new $util.LongBits(
            object.cardId.low >>> 0,
            object.cardId.high >>> 0,
          ).toNumber(true);
      if (object.tweetId != null) message.tweetId = String(object.tweetId);
      return message;
    };

    /**
     * Creates a plain object from a TwitterTweetClickPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.TwitterTweetClickPayload
     * @static
     * @param {proto_stats.TwitterTweetClickPayload} message TwitterTweetClickPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TwitterTweetClickPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.cardId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.cardId = options.longs === String ? '0' : 0;
        object.tweetId = '';
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (typeof message.cardId === 'number')
          object.cardId = options.longs === String ? String(message.cardId) : message.cardId;
        else
          object.cardId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cardId)
              : options.longs === Number
              ? new $util.LongBits(message.cardId.low >>> 0, message.cardId.high >>> 0).toNumber(
                  true,
                )
              : message.cardId;
      if (message.tweetId != null && message.hasOwnProperty('tweetId'))
        object.tweetId = message.tweetId;
      return object;
    };

    /**
     * Converts this TwitterTweetClickPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.TwitterTweetClickPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TwitterTweetClickPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return TwitterTweetClickPayload;
  })();

  proto_stats.ImageGalleryItemClickPayload = (function() {
    /**
     * Properties of an ImageGalleryItemClickPayload.
     * @memberof proto_stats
     * @interface IImageGalleryItemClickPayload
     * @property {number|Long|null} [accountId] ImageGalleryItemClickPayload accountId
     * @property {number|Long|null} [cardId] ImageGalleryItemClickPayload cardId
     * @property {number|Long|null} [imageId] ImageGalleryItemClickPayload imageId
     * @property {string|null} [url] ImageGalleryItemClickPayload url
     */

    /**
     * Constructs a new ImageGalleryItemClickPayload.
     * @memberof proto_stats
     * @classdesc Represents an ImageGalleryItemClickPayload.
     * @implements IImageGalleryItemClickPayload
     * @constructor
     * @param {proto_stats.IImageGalleryItemClickPayload=} [properties] Properties to set
     */
    function ImageGalleryItemClickPayload(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImageGalleryItemClickPayload accountId.
     * @member {number|Long} accountId
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @instance
     */
    ImageGalleryItemClickPayload.prototype.accountId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0;

    /**
     * ImageGalleryItemClickPayload cardId.
     * @member {number|Long} cardId
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @instance
     */
    ImageGalleryItemClickPayload.prototype.cardId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0;

    /**
     * ImageGalleryItemClickPayload imageId.
     * @member {number|Long} imageId
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @instance
     */
    ImageGalleryItemClickPayload.prototype.imageId = $util.Long
      ? $util.Long.fromBits(0, 0, true)
      : 0;

    /**
     * ImageGalleryItemClickPayload url.
     * @member {string} url
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @instance
     */
    ImageGalleryItemClickPayload.prototype.url = '';

    /**
     * Creates a new ImageGalleryItemClickPayload instance using the specified properties.
     * @function create
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {proto_stats.IImageGalleryItemClickPayload=} [properties] Properties to set
     * @returns {proto_stats.ImageGalleryItemClickPayload} ImageGalleryItemClickPayload instance
     */
    ImageGalleryItemClickPayload.create = function create(properties) {
      return new ImageGalleryItemClickPayload(properties);
    };

    /**
     * Encodes the specified ImageGalleryItemClickPayload message. Does not implicitly {@link proto_stats.ImageGalleryItemClickPayload.verify|verify} messages.
     * @function encode
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {proto_stats.IImageGalleryItemClickPayload} message ImageGalleryItemClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImageGalleryItemClickPayload.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.accountId);
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.cardId);
      if (message.imageId != null && message.hasOwnProperty('imageId'))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.imageId);
      if (message.url != null && message.hasOwnProperty('url'))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.url);
      return writer;
    };

    /**
     * Encodes the specified ImageGalleryItemClickPayload message, length delimited. Does not implicitly {@link proto_stats.ImageGalleryItemClickPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {proto_stats.IImageGalleryItemClickPayload} message ImageGalleryItemClickPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImageGalleryItemClickPayload.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImageGalleryItemClickPayload message from the specified reader or buffer.
     * @function decode
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {proto_stats.ImageGalleryItemClickPayload} ImageGalleryItemClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImageGalleryItemClickPayload.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.proto_stats.ImageGalleryItemClickPayload();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.accountId = reader.uint64();
            break;
          case 2:
            message.cardId = reader.uint64();
            break;
          case 3:
            message.imageId = reader.uint64();
            break;
          case 10:
            message.url = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ImageGalleryItemClickPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {proto_stats.ImageGalleryItemClickPayload} ImageGalleryItemClickPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImageGalleryItemClickPayload.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImageGalleryItemClickPayload message.
     * @function verify
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImageGalleryItemClickPayload.verify = function verify(message) {
      if (typeof message !== 'object' || message === null) return 'object expected';
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (
          !$util.isInteger(message.accountId) &&
          !(
            message.accountId &&
            $util.isInteger(message.accountId.low) &&
            $util.isInteger(message.accountId.high)
          )
        )
          return 'accountId: integer|Long expected';
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (
          !$util.isInteger(message.cardId) &&
          !(
            message.cardId &&
            $util.isInteger(message.cardId.low) &&
            $util.isInteger(message.cardId.high)
          )
        )
          return 'cardId: integer|Long expected';
      if (message.imageId != null && message.hasOwnProperty('imageId'))
        if (
          !$util.isInteger(message.imageId) &&
          !(
            message.imageId &&
            $util.isInteger(message.imageId.low) &&
            $util.isInteger(message.imageId.high)
          )
        )
          return 'imageId: integer|Long expected';
      if (message.url != null && message.hasOwnProperty('url'))
        if (!$util.isString(message.url)) return 'url: string expected';
      return null;
    };

    /**
     * Creates an ImageGalleryItemClickPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {proto_stats.ImageGalleryItemClickPayload} ImageGalleryItemClickPayload
     */
    ImageGalleryItemClickPayload.fromObject = function fromObject(object) {
      if (object instanceof $root.proto_stats.ImageGalleryItemClickPayload) return object;
      let message = new $root.proto_stats.ImageGalleryItemClickPayload();
      if (object.accountId != null)
        if ($util.Long)
          (message.accountId = $util.Long.fromValue(object.accountId)).unsigned = true;
        else if (typeof object.accountId === 'string')
          message.accountId = parseInt(object.accountId, 10);
        else if (typeof object.accountId === 'number') message.accountId = object.accountId;
        else if (typeof object.accountId === 'object')
          message.accountId = new $util.LongBits(
            object.accountId.low >>> 0,
            object.accountId.high >>> 0,
          ).toNumber(true);
      if (object.cardId != null)
        if ($util.Long) (message.cardId = $util.Long.fromValue(object.cardId)).unsigned = true;
        else if (typeof object.cardId === 'string') message.cardId = parseInt(object.cardId, 10);
        else if (typeof object.cardId === 'number') message.cardId = object.cardId;
        else if (typeof object.cardId === 'object')
          message.cardId = new $util.LongBits(
            object.cardId.low >>> 0,
            object.cardId.high >>> 0,
          ).toNumber(true);
      if (object.imageId != null)
        if ($util.Long) (message.imageId = $util.Long.fromValue(object.imageId)).unsigned = true;
        else if (typeof object.imageId === 'string') message.imageId = parseInt(object.imageId, 10);
        else if (typeof object.imageId === 'number') message.imageId = object.imageId;
        else if (typeof object.imageId === 'object')
          message.imageId = new $util.LongBits(
            object.imageId.low >>> 0,
            object.imageId.high >>> 0,
          ).toNumber(true);
      if (object.url != null) message.url = String(object.url);
      return message;
    };

    /**
     * Creates a plain object from an ImageGalleryItemClickPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @static
     * @param {proto_stats.ImageGalleryItemClickPayload} message ImageGalleryItemClickPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImageGalleryItemClickPayload.toObject = function toObject(message, options) {
      if (!options) options = {};
      let object = {};
      if (options.defaults) {
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.accountId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.accountId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.cardId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.cardId = options.longs === String ? '0' : 0;
        if ($util.Long) {
          let long = new $util.Long(0, 0, true);
          object.imageId =
            options.longs === String
              ? long.toString()
              : options.longs === Number
              ? long.toNumber()
              : long;
        } else object.imageId = options.longs === String ? '0' : 0;
        object.url = '';
      }
      if (message.accountId != null && message.hasOwnProperty('accountId'))
        if (typeof message.accountId === 'number')
          object.accountId =
            options.longs === String ? String(message.accountId) : message.accountId;
        else
          object.accountId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.accountId)
              : options.longs === Number
              ? new $util.LongBits(
                  message.accountId.low >>> 0,
                  message.accountId.high >>> 0,
                ).toNumber(true)
              : message.accountId;
      if (message.cardId != null && message.hasOwnProperty('cardId'))
        if (typeof message.cardId === 'number')
          object.cardId = options.longs === String ? String(message.cardId) : message.cardId;
        else
          object.cardId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cardId)
              : options.longs === Number
              ? new $util.LongBits(message.cardId.low >>> 0, message.cardId.high >>> 0).toNumber(
                  true,
                )
              : message.cardId;
      if (message.imageId != null && message.hasOwnProperty('imageId'))
        if (typeof message.imageId === 'number')
          object.imageId = options.longs === String ? String(message.imageId) : message.imageId;
        else
          object.imageId =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.imageId)
              : options.longs === Number
              ? new $util.LongBits(message.imageId.low >>> 0, message.imageId.high >>> 0).toNumber(
                  true,
                )
              : message.imageId;
      if (message.url != null && message.hasOwnProperty('url')) object.url = message.url;
      return object;
    };

    /**
     * Converts this ImageGalleryItemClickPayload to JSON.
     * @function toJSON
     * @memberof proto_stats.ImageGalleryItemClickPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImageGalleryItemClickPayload.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImageGalleryItemClickPayload;
  })();

  return proto_stats;
})());

export { $root as default };

import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import { Crop, FeaturedPhoto, UnavailablePhoto, PhotoAlt } from './styles';

export default compose(
  //
  branch((props) => !props.photo, renderNothing),
)(({ photo, onLinkClick }) => (
  <FeaturedPhoto key={photo.id} width={1}>
    <a
      href={photo.href}
      onClick={onLinkClick}
      data-photo-id={photo.id}
      data-photo-permalink={photo.permalink}
    >
      <Crop style={{ backgroundImage: `url(${photo.src})` }} data-src={photo.src}>
        {!photo.src && (
          <UnavailablePhoto>
            <div>This Photo Is Temporarily Unavailable</div>
            <PhotoAlt>{photo.href}</PhotoAlt>
          </UnavailablePhoto>
        )}
      </Crop>
    </a>
  </FeaturedPhoto>
));

import React from 'react';
import styled from 'styled-components/macro';
import { Text, Heading } from 'components/web-ui';
import View from 'components/ui/view';
import * as Icons from 'components/ui/icons';
import { Swipeable } from 'components/ui';
import { compose, withHandlers, withProps } from 'recompose';

const Layout = styled(View)`
  display: flex;
  flex-direction: column;
  > * {
    flex: 1;
  }
`;

const Action = styled(Swipeable)`
  color: white;
  text-decoration: none;
`;

const UpArrow = styled(Icons.UpArrow)`
  opacity: 0.75;
`;

const WebsiteCard = ({
  ctas,
  cta,
  handleSwipeUp,
  backgroundColor,
  isOwner,
  isAuthenticated,
  ...props
}) => {
  return (
    <Layout {...props} src={props.backgroundMediaUrl} style={{ backgroundColor }} full>
      <View flex center>
        {props.title && <Heading fontSize={5} children={props.title} />}
      </View>
      {props.description && <Text my={2} fontSize={1} children={props.description} />}
      {cta && (
        <Action flex="column" center onSwipeUp={handleSwipeUp}>
          <UpArrow />
          <View py={2}>{cta.title}</View>
        </Action>
      )}
    </Layout>
  );
};

export default compose(
  withProps((props) => ({
    cta: props.ctas[0] || props.cta,
  })),
  withHandlers({
    handleSwipeUp: (props) => () => (window.location = props.cta.url),
  }),
)(WebsiteCard);

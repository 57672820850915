import { graphql } from '@apollo/react-hoc';
import { publicAccountQuery } from './queries';

const withAccount = graphql(publicAccountQuery, {
  options: (props) => ({
    variables: { username: props.username },
    fetchPolicy: 'cache-first',
  }),
  props: (props) => {
    const { data } = props;
    const { publicAccount, ...request } = data;
    const account = publicAccount && publicAccount.enabled ? publicAccount : null;

    return {
      request,
      account,
    };
  },
});

export { withAccount };
export default withAccount;

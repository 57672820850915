import styled from 'styled-components/macro';

const Svg = styled.svg``;

Svg.defaultProps = {
  version: '1.1',
  viewBox: '0 0 512 512',
  fill: 'currentColor',
  width: '1.25em',
};

export default Svg;

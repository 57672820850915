import React from 'react';
import CardLayout from 'components/story-card/ui/card-layout';
import { Message } from 'components/story-card/error-card/styled';

const ErrorCard = () => (
  <CardLayout full>
    <Message>
      Wups! Something's not quite right here. We're still working on this card so stay tuned for
      updates. For now, try swiping left or right to check out our other content.
    </Message>
  </CardLayout>
);

export default ErrorCard;

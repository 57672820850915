require('url-search-params-polyfill');

const { compose, lifecycle } = require('recompose');
const Cookies = require('js-cookie');

// This component takes the query string from the URL, parses it into an object,
// and stores it in a cookie. This should only be used once, and at the top of
// the component tree

module.exports = compose(
  lifecycle({
    componentDidMount() {
      const cookieObj = {};

      // Exit early if there's no query params
      if (!window.location.search.replace('?', '')) return;

      const queryParamsObj = [...new URLSearchParams(document.location.search).entries()].reduce(
        (q, [k, v]) => Object.assign(q, { [k]: v }),
        {},
      );

      // Tapfiliate URLs use ref=affilateName so we can infer the source
      // if the ref param is present
      if (queryParamsObj.ref) {
        cookieObj.campaign = queryParamsObj.ref;
        cookieObj.source = 'affiliate';
      }

      // Campaign is something specific, like an affiliate or partner's name
      // Source is something generic, like affiliate / partner
      // Note that if we have both `ref` and `source` / `campaign` params, the explicit
      // params take precedence.

      if (!cookieObj.campaign) {
        cookieObj.campaign =
          queryParamsObj.campaign || queryParamsObj.coupon || window.document.referrer || null;
      }

      if (!cookieObj.source) {
        let source = 'direct';
        if (queryParamsObj.coupon) {
          source = 'coupon';
        } else if (queryParamsObj.source) {
          source = queryParamsObj.source;
        }
        cookieObj.source = source;
      }

      // We'll rename the coupon to discountCode
      cookieObj.discountCode = queryParamsObj.coupon;

      // Add any customization token
      if (queryParamsObj.token) {
        cookieObj.token = queryParamsObj.token;
      }

      Cookies.set('initialQueryParams', cookieObj, { expires: 30 });
      console.debug(`Set JSON cookie: ${JSON.stringify(cookieObj)}`);
    },
  }),
);

import getUsername from 'lib/get-username';
import { withProps, branch, renderComponent } from 'recompose';
import { Page404 } from 'components/errors';

export const withUsername = withProps((ownerProps) => {
  const username = getUsername();
  return { username };
});

export const requiresUsername = branch(({ username, ...props }) => {
  return !username;
}, renderComponent(Page404));

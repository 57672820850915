import { loader } from 'graphql.macro';

export const mailingListSubscribeMutation = loader('./mailing-list-subscribe.gql');
export const mailingListUnsubscribeMutation = loader('./mailing-list-unsubscribe.gql');
export const subscribeMailingListMutation = loader('./subscribe-mailing-list.gql');

export default {
  mailingListSubscribeMutation,
  mailingListUnsubscribeMutation,
  subscribeMailingListMutation,
};

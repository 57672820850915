import config from 'config';
import * as Sentry from '@sentry/browser';

const initialize = () => {
  const headRootElement = document.querySelector('head');
  const preConnectElement = document.createElement('link');
  preConnectElement.setAttribute('rel', 'preconnect');
  preConnectElement.setAttribute('href', config.statsCFHost);
  preConnectElement.setAttribute('crossorigin', true);
  headRootElement.appendChild(preConnectElement);
};

const endpoint = (eventName) => {
  return `${config.statsCFHost}${config.statsCFPathPrefix}${eventName}`;
};

const collect = (eventName, payload) => {
  try {
    const url = endpoint(eventName);
    const buffer = payload.constructor.encode(payload).finish();
    const base64 = btoa(String.fromCharCode.apply(null, buffer));
    const blob = new Blob([base64], {
      type: 'text/plain; charset=UTF-8',
    });
    navigator.sendBeacon(url, blob);
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }
};

const pageView = (payload) => collect('pageView', payload);
const cardView = (payload) => collect('cardView', payload);
const cardClick = (payload) => collect('cardClick', payload);
const cardSubmit = (payload) => collect('cardSubmit', payload);
const youtubeVideoPlay = (payload) => collect('youtubeVideoPlay', payload);
const imageGalleryItemClick = (payload) => collect('imageGalleryItemClick', payload);
const instagramLinkClick = (payload) => collect('instagramLinkClick', payload);
const twitterTweetClick = (payload) => collect('twitterTweetClick', payload);

export default {
  initialize,
  endpoint,
  pageView,
  cardView,
  cardClick,
  cardSubmit,
  youtubeVideoPlay,
  imageGalleryItemClick,
  instagramLinkClick,
  twitterTweetClick,
};

import { useState } from 'react';

const key = {
  state: 'gdpr_accepted',
  timestamp: 'gdpr_timestamp',
};

function consentDecisionDate() {
  return new Date(Number(localStorage.getItem(key.timestamp)) || 0);
}

function isDecisionMade(updatedTime) {
  const currentTime = new Date();
  const diff = Math.abs(currentTime - updatedTime);
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days <= 10;
}

function currentGdprState() {
  return localStorage.getItem(key.state) === 'true';
}

function useGdprConsent() {
  const [decisionMade, setDecisionMade] = useState(isDecisionMade(consentDecisionDate()));
  const [accepted, setAccepted] = useState(currentGdprState());

  const updateAcceptedHandler = (accepted) => {
    const currentTimestamp = Date.now();
    localStorage.setItem(key.state, accepted);
    localStorage.setItem(key.timestamp, currentTimestamp);

    setAccepted(accepted);
    setDecisionMade(true);
  };

  return [accepted, decisionMade, updateAcceptedHandler];
}

export default useGdprConsent;

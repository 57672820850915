import { connect } from 'react-redux';
import { compose, withProps, withState, withHandlers } from 'recompose';
import withCollectYoutubePlay from 'hocs/with-collect-youtube-play';
import { withYouTubeVideos } from 'lib/graphql';

const withHasVideos = withProps(({ data: { youtubeVideos, loading } }) => {
  return {
    hasLoadedVideos: !loading,
    hasVideos: youtubeVideos && youtubeVideos.length > 0,
    videos: youtubeVideos,
  };
});

const withReduxState = connect((state) => ({ activeIndex: state.views.activeIndex }));

const withVideoPlayerHandlers = withHandlers({
  onVideoPlay: ({ account, id, recordPlayStat, sendVideoPlay, setRecordPlayStat }) => (
    activeVideo,
  ) => {
    if (!recordPlayStat) return;
    setRecordPlayStat(false);
    sendVideoPlay(account.id, id, activeVideo.id);
  },
  changeVideo: ({ setActiveVideo, setRecordPlayStat }) => (id) => {
    setActiveVideo(id);
    setRecordPlayStat(true);
  },
});

export default compose(
  withYouTubeVideos,
  withReduxState,
  withHasVideos,
  withState('activeVideo', 'setActiveVideo', {}),
  withState('videoIframeIsLoading', 'setVideoIsLoading', true),
  withState('recordPlayStat', 'setRecordPlayStat', true),
  withCollectYoutubePlay,
  withVideoPlayerHandlers,
);

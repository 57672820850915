import { Button } from 'components/web-ui';
import styled from 'styled-components/macro';

const EditModeButton = styled(Button)`
  position: absolute;
  right: 12px;
  top: 16px;
  z-index: 20;
  padding-left: 15px;
  padding-right: 15px;
  color: #c6c6c6;
  background-color: rgba(52, 21, 82, 0.7);
  border-radius: 50px;
  height: 38px;
  font-size: 14px;

  :hover,
  :focus,
  :active {
    box-shadow: none;
    background-color: rgba(52, 21, 82);
  }
`;
export { EditModeButton };

/**
 * 💥 TRIGGERS are user-envoked actions
 *    – may have side effects
 *    - cannot be a side effect
 * => NAMESPACE.TRIGGERS.NAME = 'string'
 *
 * ⚡ REQUESTS are network requests to external resources
 *    - may have side effects
 *    - can be a side effect
 * => NAMESPACE.REQUESTS.NAME = 'string'
 * => NAMESPACE.REQUESTS.NAME.STATE = 'string:state'
 *
 * ✏️ ACTIONS are mutations on the store
 *    - may not have side effects
 *    - may be a side effect
 * => NAMESPACE.ACTIONS.NAME = 'string'
 *
 *
 */
export const ACTIONS = {
  VIEWS: {
    MERGE: '✏️ viewstates:merge',
    SET: '✏️ viewstates:set',
    UNSET: '✏️ viewstates:unset',
  },
};

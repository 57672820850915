import React from 'react';
import PropTypes from 'prop-types';
import View from './view';
import styled from 'styled-components/macro';
import { compose, defaultProps, withState, withHandlers, setPropTypes } from 'recompose';

const Swipeable = styled(
  ({
    handleTouchCancel,
    handleTouchEnd,
    handleTouchMove,
    handleTouchStart,
    onTouchCancel,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    touched,
    setTouched,
    touchStartX,
    setTouchStartX,
    touchStartY,
    setTouchStartY,
    touchEndX,
    setTouchEndX,
    touchEndY,
    setTouchEndY,
    onSwipeUp,
    onSwipeDown,
    onSwipeLeft,
    onSwipeRight,
    ...props
  }) => (
    <View
      onTouchCancel={handleTouchCancel}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      {...props}
    />
  ),
)`
  opacity: ${(props) => (props.touched ? 0.5 : 1)};
`;

export default compose(
  setPropTypes({
    threshold: PropTypes.number,

    onSwipeUp: PropTypes.func,
    onSwipeDown: PropTypes.func,
    onSwipeLeft: PropTypes.func,
    onSwipeRight: PropTypes.func,

    onTouchCancel: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchStart: PropTypes.func,
  }),
  defaultProps({
    onTouchCancel: (e) => process.env.NODE_ENV === 'development' && console.log(e.nativeEvent.type),
    onTouchEnd: (e) => process.env.NODE_ENV === 'development' && console.log(e.nativeEvent.type),
    onTouchMove: (e) => process.env.NODE_ENV === 'development' && console.log(e.nativeEvent.type),
    onTouchStart: (e) => process.env.NODE_ENV === 'development' && console.log(e.nativeEvent.type),

    threshold: 100,
  }),
  withState('touched', 'setTouched', false),
  withState('touchStartX', 'setTouchStartX', 0),
  withState('touchStartY', 'setTouchStartY', 0),
  withState('touchEndX', 'setTouchEndX', 0),
  withState('touchEndY', 'setTouchEndY', 0),
  withHandlers({
    handleTouchCancel: (props) => (e) => {
      props.setTouchStartX(false);
      props.setTouchStartY(false);
      props.setTouched(false);
      props.onTouchEnd(e);
    },
    handleTouchEnd: (props) => (e) => {
      const { onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown } = props;
      const deltaX = props.touchEndX - props.touchStartX;
      const deltaY = props.touchEndY - props.touchStartY;

      // left
      if (onSwipeLeft && deltaX < -props.threshold) {
        onSwipeLeft();
      }

      // right
      if (onSwipeRight && deltaX > props.threshold) {
        onSwipeRight();
      }

      // up
      if (onSwipeUp && deltaY < -props.threshold) {
        onSwipeUp();
      }

      // down
      if (onSwipeDown && deltaY > props.threshold) {
        onSwipeDown();
      }

      // Cleanup
      props.setTouchStartX(false);
      props.setTouchStartY(false);
      props.setTouched(false);
      props.onTouchEnd(e);
    },
    handleTouchMove: (props) => (e) => {
      const { touches } = e.nativeEvent;
      props.setTouchEndX(touches[0].clientX);
      props.setTouchEndY(touches[0].clientY);
      props.onTouchMove(e);
    },
    handleTouchStart: (props) => (e) => {
      const { timeStamp, touches } = e.nativeEvent;
      props.setTouched(timeStamp);
      props.setTouchStartX(touches[0].clientX);
      props.setTouchStartY(touches[0].clientY);
      props.onTouchStart(e);
    },
  }),
)(Swipeable);

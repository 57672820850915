import * as Recompose from 'lib/recompose';
import { withMailingListSubscribeHandler } from 'lib/graphql';
import { withMailingListUnsubscribeHandler } from 'lib/graphql';
import withCollectCardSubmitMutation from 'hocs/with-collect-card-submit';

const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const withState = Recompose.withStateHandlers(
  ({
    emailAddress = '',
    emailFieldError = null,
    isWaiting = false,
    temporarySubscription = { isSubscribed: false },
  }) => ({
    emailAddress,
    emailFieldError,
    isWaiting,
    temporarySubscription,
  }),
  {
    setEmailAddress: (props) => (evt) => ({
      emailAddress: evt.target.value,
    }),
    setTemporarySubscription: (props) => (temporarySubscription) => ({
      temporarySubscription,
    }),
    setEmailFieldError: (props) => (message) => ({ emailFieldError: message }),
    setIsWaiting: (props) => (isWaiting) => ({ isWaiting }),
  },
);

const withCardProps = Recompose.withProps(
  ({ emailAddress, ctas, temporarySubscription }) => ({
    cta:
      ctas.length > 0
        ? ctas[0]
        : {
            title: 'Sign up',
            message: 'Thanks for signing up!',
            url: '',
          },
    subscription: temporarySubscription,
    isEmailAddressValid: emailRegex.test(emailAddress),
  }),
);

export default Recompose.compose(
  withState,
  withCollectCardSubmitMutation,
  withMailingListSubscribeHandler,
  withMailingListUnsubscribeHandler,
  withCardProps,
  Recompose.withHandlers({
    handleSubscribeClick: (props) => async (evt) => {
      const {
        account,
        emailAddress,
        id,
        mailingList,
        sendCardSubmit,
        setEmailFieldError,
        setIsWaiting,
        setTemporarySubscription,
        subscribe,
        username,
      } = props;
      const { mergeFields } = (mailingList || {});
      const useBrowserAction = Array.isArray(mergeFields) && mergeFields.filter(f => f.required).length > 0;

      if (useBrowserAction) {
        return;
      }
      evt.preventDefault();

      setIsWaiting(true);
      sendCardSubmit(account.id, id);
      const response = await subscribe(id, emailAddress, username);

      if (response.hasErrors) {
        setEmailFieldError(response.errorMessage);
        setIsWaiting(false);
        return;
      }

      setTemporarySubscription({ emailAddress, isSubscribed: true });
      setIsWaiting(false);
    },
  }),
);

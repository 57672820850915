import { graphql } from '@apollo/react-hoc';
import { publicAccountProfilePicQuery } from './queries';

const withAccountProfilePic = graphql(publicAccountProfilePicQuery, {
  options: ({ username }) => ({ variables: { username } }),
  props: ({ data: { publicAccount, ...request } }) => ({
    request,
    account: publicAccount,
  }),
});

export { withAccountProfilePic };
export default withAccountProfilePic;

import React from 'react';
import Svg from './svg';
import { withProps } from 'recompose';

export default withProps({
  viewBox: '0 0 250 250',
  children: (
    <path d="M125 250a125 125 0 1 1 0-250 125 125 0 0 1 0 250zm-6.8-119.4a5.1 5.1 0 0 0-5 5v32.7c0 2.8 2.2 5.1 5 5.1h5.7c2.8 0 5-2.3 5-5v-32.7c0-2.9-2.2-5.1-5-5.1h-5.7zm16.3 21.9c0 3.3.6 6.5 1.9 9.5a21.5 21.5 0 0 0 10.4 10.6 26 26 0 0 0 11.4 2.4c7.1 0 12.9-2.1 17.3-6.3 4.4-4.3 6.5-9.7 6.5-16.3 0-6.6-2.1-12-6.5-16.3-4.3-4.2-10-6.3-17-6.3-3.6 0-6.9.5-9.8 1.6A21.4 21.4 0 0 0 136 144c-1 3-1.6 5.7-1.6 8.4zm15.9.2c0-2.6.7-4.7 2.2-6.3a7.5 7.5 0 0 1 5.7-2.4c2.2 0 4.1.8 5.6 2.4a8.5 8.5 0 0 1 2.3 6.2 7.8 7.8 0 0 1-8 8.1c-2.3 0-4.1-.7-5.6-2.2a7.7 7.7 0 0 1-2.2-5.8zm-80 15.6c0 2.8 2.2 5.1 5 5.1h16.2a25 25 0 0 0 6-.6c2.2-.5 4-1.4 5.4-2.5 1.5-1 2.6-2.3 3.4-3.7.8-1.5 1.2-3.2 1.2-5.2s-.4-3.6-1.2-5a9.3 9.3 0 0 0-3.2-3.5 16 16 0 0 0-4.4-1.7c1.7-.4 3-1.2 4.1-2.2a9 9 0 0 0 2.7-6.7c0-3-1-5.5-2.9-7.6-2.5-2.8-6-4.1-10.8-4.1H75.4a5.1 5.1 0 0 0-5 5v32.7zm14.2-4.4v-7.2h2.6c1.8 0 3 .3 3.8.7 1 .6 1.5 1.6 1.5 3 0 .8-.2 1.6-.6 2.1-.4.6-1 1-1.5 1.1-.6.2-1.7.3-3.3.3h-2.5zm0-16.7V141h1.8c1.6 0 2.7.1 3.2.5.9.6 1.3 1.4 1.3 2.5 0 .7-.1 1.3-.5 2-.3.5-.8.9-1.3 1-.4.2-1.4.3-2.8.3h-1.7zm64.2-34.4c0 2.8 2.3 5 5 5h5.1c2.8 0 5.1-2.2 5.1-5v-2.6c0-2.8 2.3-5.1 5.1-5.1h.4c3.1 0 5.6-.3 7.6-.8 1.4-.4 2.6-1 3.4-1.6 1.6-1.1 2.9-2.7 4-4.8 1-2.1 1.5-4.6 1.5-7.4 0-2.2-.3-4-.9-5.7a13.8 13.8 0 0 0-5.4-7c-1.2-.8-2.7-1.5-4.5-2-1.8-.5-4.5-.8-8-.8h-13.3a5.1 5.1 0 0 0-5.1 5.1v32.7zm15-18.8V87h1.8c2 0 3.5.3 4.2.9.8.6 1.1 1.4 1.1 2.6 0 1.4-.5 2.5-1.7 3.1-.6.4-1.8.5-3.7.5h-1.7zm-64.2 17a5.1 5.1 0 0 0 4.8 6.9h6.5c1.6 0 3.1-1.2 3.5-2.8l.2-.8c.3-1.1 1.3-2 2.5-2h7.8c1.2 0 2.2.9 2.5 2l.2.8a3.7 3.7 0 0 0 3.5 2.8h6.6a5.1 5.1 0 0 0 4.8-6.9l-11.6-32.6A5 5 0 0 0 126 75h-10a5 5 0 0 0-4.8 3.3L99.6 111zM118 99.6l1.8-6.6a1.4 1.4 0 0 1 2.7 0l1.8 6.6H118zm-32.6 18.3c2.8 0 5.1-2.3 5.1-5.1V94c0-2.9 2.3-5.1 5.1-5.1h.6c2.8 0 5.1-2.3 5.1-5.1V80c0-2.8-2.3-5.1-5-5.1H69a5.1 5.1 0 0 0-5.1 5.1v3.8c0 2.8 2.3 5 5.1 5h.6c2.8 0 5.1 2.3 5.1 5.2v18.7c0 2.8 2.3 5 5.1 5h5.4z" />
  ),
})(Svg);

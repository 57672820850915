import React from 'react';
import styled from 'styled-components/macro';
import { Text } from 'components/web-ui';
import View from 'components/ui/view';
import SubscribeButton from './subscribe-button';
import Tweets from './tweets';
import Error from './error';
import { Query } from '@apollo/react-components';
import Spinner from 'components/ui/spinner';
import { DescriptionSmall, Layout, TitleSmall } from '../styles';
import throttleEventHandler from 'lib/throttle-event-handler';
import { fixLineBreakSpacers } from 'lib/utils';
import { getTweetsPayloadQuery } from 'lib/graphql/queries';
import { compose, withHandlers } from 'recompose';
import withCollectTwitterTweetClick from 'hocs/with-collect-twitter-tweet-click';

const FieldsContainer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 10px 0 0 0;
  padding: 0 5px;
`;

const Scrollable = styled(View)`
  padding-top: 68px;
`;

const HeaderTwitterHandle = styled(Text)`
  font-size: 13px;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
`;

function TwitterCard(props) {
  const {
    buttonColor,
    description,
    onContentScroll,
    onTweetClick,
    title,
    twitter: { username: twitterUsername, feed },
    sendTwitterTweetClick,
    ...rest
  } = props;

  return (
    <Layout {...rest} full>
      <Scrollable scroll onScroll={throttleEventHandler(onContentScroll, 500, { leading: true })}>
        {title && <TitleSmall children={fixLineBreakSpacers(title)} />}
        {description && <DescriptionSmall children={fixLineBreakSpacers(description)} />}

        <Query query={getTweetsPayloadQuery} variables={{ username: twitterUsername, feed }}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner />;

            // Handle a variety of cases relating to errors that can be returned by
            // the Twitter API.
            const statusCode = data.tweetsPayload.statusCode;
            if (error) return <Error statusCode={statusCode} />;
            if (statusCode !== 200)
              return <Error statusCode={statusCode} username={twitterUsername} />;
            if (data.tweetsPayload.result.length === 0)
              return <Error statusCode={statusCode} username={twitterUsername} isEmpty />;

            return (
              <FieldsContainer>
                <HeaderTwitterHandle children={`${feed} by @${twitterUsername}`} />
                <SubscribeButton
                  backgroundColor={buttonColor || '#059FF5'}
                  href={`https://twitter.com/intent/follow?screen_name=${encodeURIComponent(
                    twitterUsername,
                  )}`}
                />
                <Tweets
                  tweets={data.tweetsPayload.result}
                  twitterUsername={twitterUsername}
                  onTweetClick={onTweetClick}
                />
              </FieldsContainer>
            );
          }}
        </Query>
      </Scrollable>
    </Layout>
  );
}

export default compose(
  withCollectTwitterTweetClick,
  withHandlers({
    onTweetClick: (props) => (evt) => {
      evt.preventDefault();
      const { account, id: cardId, sendTwitterTweetClick } = props;
      const { id: tweetId } = evt.currentTarget.dataset;

      sendTwitterTweetClick(account.id, cardId, tweetId);

      window.open(evt.currentTarget.href, '_blank');
    },
  }),
)(TwitterCard);

import React from 'react';
import * as Sentry from '@sentry/browser';
import { compose, withState, lifecycle, branch, renderComponent } from 'recompose';
import ErrorMessage from 'components/errors/error-message';

const ErrorComponent = (props) => {
  return props.component ? <props.component /> : <ErrorMessage />;
};

export default compose(
  withState('hasError', 'setHasError', false),
  lifecycle({
    componentDidCatch(error, info) {
      this.props.setHasError(true);
      Sentry.captureException(error, info);
    },
  }),
  branch((props) => props.hasError, renderComponent(ErrorComponent)),
)((props) => props.children);

function Page404() {
  const { host, pathname, protocol } = window.location;
  if (pathname && pathname.length > 1) {
    const username = pathname.substring(1);
    window.location = `${protocol}//${host}/@${username}`;
  }
  return null;
}

Page404.defaultProps = {
  title: 'Well that was unexpected',
  message: 'This page doesnʼt seem to exist!',
};

export default Page404;

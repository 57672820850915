import React from 'react';
import styled from 'styled-components/macro';
import { CopyWrapper, Crop, Description, ImageWrapper as BaseImageWrapper, Title } from './styled';
import TextTruncate from 'react-text-truncate';

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ImageWrapper = styled(BaseImageWrapper)`
  width: ${(props) => {
    switch (props.view) {
      case 'grid_1':
        return '100%';
      case 'grid_2':
        return 'calc(50% - 2.5px)';
      case 'grid_3':
        return 'calc(33.333333% - 3.333333px)';
      default:
        return '100%';
    }
  }};
`;

export default ({ images, handleClick, view }) => {
  if (!['grid_1', 'grid_2', 'grid_3'].includes(view)) return null;
  return (
    <ImagesContainer>
      {images.map((image) => (
        <ImageWrapper view={view} key={image.id} onClick={(event) => handleClick(image)}>
          <Crop style={{ backgroundImage: `url(${image.imageUrl})` }}>
            <CopyWrapper>
              <Title>
                <TextTruncate line={1} truncateText="…" text={image.title} />
              </Title>
              <Description>
                <TextTruncate line={2} truncateText="…" text={image.description} />
              </Description>
            </CopyWrapper>
          </Crop>
        </ImageWrapper>
      ))}
    </ImagesContainer>
  );
};

export function convertHexToRgba(hex) {
  // Seems this can happen on the links card. We need to pass it through.
  if (hex === 'transparent') return hex;

  const channels = hex.slice(1).match(/.{1,2}/g);

  // Return transparent if hex is empty or invalid
  if (!Array.isArray(channels)) return 'transparent';

  // Do nothing if we have a 6 charcter hex colour
  if (channels.length === 3) return hex;

  // Otherwise convert #RRGGBBaa into rgba(R, G, B, a)
  const percentage = (value) => parseFloat(parseInt((parseInt(value, 16) / 255) * 1000, 10) / 1000);
  const alpha = percentage(channels[3]);
  const rgb = channels.slice(0, 3).map((value) => parseInt(value, 16));
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
}

import styled from 'styled-components/macro';

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  font-size: 21px;
  font-weight: bold;
  color: white;
`;

export { Message };

import { Button } from 'components/web-ui';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 12px 24px;
  z-index: 999;
`;

const Title = styled.h2`
  font-size: 17px;
  font-weight: 900;
`;

const ConsentMessage = styled.p`
  font-size: 16px;
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const AcceptButton = styled(Button)`
  padding: 14px 20px;
  color: white;
  background-color: rgba(52, 21, 82);
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;

  :hover,
  :focus,
  :active {
    box-shadow: none;
    background-color: rgba(52, 21, 82, 0.7);
  }
`;

const DeclineButton = styled(Button)`
  margin-left: 6px;
  padding: 14px 20px;
  color: #999;
  background-color: #e9e9e9;
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;

  :hover,
  :focus,
  :active {
    box-shadow: none;
    background-color: #f3f3f3;
  }
`;

export { ActionBar, AcceptButton, DeclineButton, Container, Title, ConsentMessage };

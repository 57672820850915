import { format, parseJSON } from 'date-fns';

function parseDate(date) {
  if (typeof date === 'number') {
    if (date.toString().length < 13) {
      const delta = 13 - date.toString().length;
      const magnitude = Math.pow(10, delta);
      return new Date(date * magnitude);
    }
  }

  return parseJSON(date);
}

function formatDate(date, fmt = 'MM/dd/yyyy') {
  if (typeof date === 'string') {
    throw new Error(
      'formatDate: type error; date argument is a string, please parse with parseDate before attempting to format',
    );
  }

  if (typeof fmt !== 'string') {
    throw new Error('formatDate: type error; format argument must be a string');
  }

  return format(date, fmt);
}

export { parseDate, formatDate };

export default {
  parseDate,
  formatDate,
};

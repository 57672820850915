import styled from 'styled-components/macro';
import View from 'components/ui/view';
import CardLayout from 'components/story-card/ui/card-layout';

export const Layout = styled(CardLayout)`
  position: relative;
  text-shadow: none;
`;

// This needs to take up all available height to push any CTA down while loading
export const Scrollable = styled(View)`
  padding-top: 68px;
  min-height: 100%;
  /*
   We want the child of this container (the photos) to take up all available
   height so we must set this parent to display: flex;
    */
  display: flex;
  flex-direction: column;
`;

export const Crop = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.09);
  background-size: cover;
  background-position: center center;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const UnavailablePhoto = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 0.9em;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);
`;

export const PhotoAlt = styled.span`
  max-width: 100%;
  margin-top: 10px;

  font-size: 0.8em;
  white-space: normal;
  word-break: break-word;
  color: rgba(255, 255, 255, 0.7);
`;

export const FeaturedPhoto = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;

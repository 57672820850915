import * as Recompose from 'recompose';
import { withUsername } from 'hocs';
import { withAccount } from 'lib/graphql';
import withCollectPageView from 'hocs/with-collect-page-view';
import withCollectCardView from 'hocs/with-collect-card-view';
import { User404, Page404 } from 'components/errors';
import { sendFacebookPixelCardView } from 'lib/facebook-pixel';
import GoogleAnalytics from 'lib/google-analytics';
import CurrentAccount from 'lib/current-account';
import withComingSoonCard from './with-coming-soon-card';

export default Recompose.compose(
  Recompose.defaultProps({
    cards: [],
    position: 0,
  }),
  withUsername,
  withAccount,
  withCollectPageView,
  withCollectCardView,
  Recompose.branch((props) => !props.username, Recompose.renderComponent(Page404)),
  Recompose.branch((props) => (props.request || {}).loading, Recompose.renderNothing),
  Recompose.branch((props) => !props.account, Recompose.renderComponent(User404)),

  Recompose.withProps((props) => {
    const { account, username } = props;
    const cards = account.defaultDeck.publicCards;
    const isOwnerAuthenticated = CurrentAccount.getCurrentAccount() === username;

    return { cards, isOwnerAuthenticated, accountId: account.id };
  }),

  withComingSoonCard,

  Recompose.withHandlers({
    handleSendPageView: (props) => () => {
      const { accountId, sendPageView } = props;
      sendPageView(accountId);
    },

    handleSendCardView: (props) => (card) => {
      if (!card) return;
      const { account, accountId, sendCardView, username } = props;
      const { facebookPixelId, googleTrackingId } = account.profile;

      // Always send these events
      GoogleAnalytics.cardView(card); // Internal
      sendCardView(accountId, card.id); // Insights

      // Conditionally send these events, based on the user's access
      if (account.owner.features.hasThirdPartyAnalytics) {
        if (facebookPixelId) sendFacebookPixelCardView(card, username); // Customer
        if (googleTrackingId) GoogleAnalytics.cardView(card, username); // Customer
      }
    },
  }),
);

import React from 'react';
import { compose } from 'recompose';
import ProfileCard from './profile-card';
import InstagramCard from './instagram-card';
import SimpleCard from './simple-card';
import WebsiteCard from './website-card';
import LinksCard from './links-card';
import DiscoverCard from './discover-card';
import MailingListCard from './mailing-list-card';
import TwitterCard from './twitter-card';
import YoutubeCard from './youtube-card';
import ImageGalleryCard from './image-gallery-card';
import ErrorCard from './error-card';
import { withUsername } from 'hocs';
import { withAccount } from 'lib/graphql';
import { withErrorBoundary, withErrorComponent } from 'components/errors';

const StoryCard = ({ kind, ...props }) => {
  switch (kind) {
    case 'profile':
      return <ProfileCard {...props} />;

    case 'twitter':
      return <TwitterCard {...props} />;

    case 'instagram':
      return <InstagramCard {...props} />;

    case 'simple':
      return <SimpleCard {...props} />;

    case 'website':
      return <WebsiteCard {...props} />;

    case 'links':
      return <LinksCard {...props} />;

    case 'mailing-list':
      return <MailingListCard {...props} />;

    case 'tap.bio':
      return console.error('tap.bio card no longer exists, please replace with discover card');

    case 'discover':
      return <DiscoverCard {...props} />;

    case 'youtube':
      return <YoutubeCard {...props} />;

    case 'image-gallery':
      return <ImageGalleryCard {...props} />;

    default:
      return <SimpleCard {...props} />;
  }
};

export default compose(
  //
  withUsername,
  withAccount,
  withErrorComponent(ErrorCard),
  withErrorBoundary,
)(StoryCard);

import { branch, renderComponent, compose } from 'recompose';
import Spinner from 'components/ui/spinner';
import { User404 } from 'components/errors';

const requiresAccountLoaded = compose(
  branch(({ request }) => request && request.loading, renderComponent(Spinner)),
  branch(({ account }) => !account, renderComponent(User404)),
);

export { requiresAccountLoaded };
export default requiresAccountLoaded;

import * as Recompose from 'lib/recompose';
import withCollectCardLinkClick from 'hocs/with-collect-card-click';
import resolveCtaUrl from '@tap-bio/core/utils/resolve-cta-url';

export default Recompose.compose(
  Recompose.mapProps((props) => {
    const {
      id,
      account,
      backgroundColor,
      backgroundColorSolid,
      backgroundMediaUrl,
      buttonColor,
      description,
      editable,
      enabled,
      hasBackgroundGradient,
      hidden,
      moveable,
      title,
      profile,
    } = props;

    const friends = props.friends.map((friend) => {
      const { id, featuredText, position } = friend;
      const { profile, ...account } = friend.account;
      return {
        ...account,
        ...profile,
        id,
        featuredText,
        position,
      };
    });

    return {
      id,
      account,
      profile,
      friends,
      title,
      description,
      backgroundColor,
      backgroundColorSolid,
      backgroundMediaUrl,
      buttonColor,
      hasBackgroundGradient,
      editable,
      enabled,
      hidden,
      moveable,
    };
  }),

  Recompose.mapProps((props) => {
    const friends = props.friends.sort(function(a, b) {
      return a.position - b.position;
    });

    return {
      ...props,
      friends,
    };
  }),

  withCollectCardLinkClick,
  Recompose.withHandlers({
    onCtaClick: (props) => (altCta) => {
      const { cta, id: cardId } = props;
      const { id: accountId } = props.account;
      const { id: ctaId, url } = cta || altCta;
      props.sendCardClick(accountId, cardId, ctaId, url);
      window.location = resolveCtaUrl(url);
    },
  }),
);

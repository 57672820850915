import { withHandlers } from 'recompose';
import { CardClickPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendCardClick: (props) => (accountId, cardId, ctaId, url) => {
    const payload = CardClickPayload.create({
      accountId,
      cardId,
      ctaId,
      url,
    });
    StatsCollector.cardClick(payload);
  },
});

import React from 'react';
import styled from 'styled-components/macro';
import CardLayout from '../ui/card-layout';
import BaseSpinner from 'components/ui/spinner';

const Layout = styled(CardLayout)`
  padding: 0 10px;
  position: relative;
  text-shadow: none;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  min-height: 40px;
  width: 100%;
`;

const MainVideoContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background-color: black;

  > .youtube-video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const FieldsContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  max-height: 100%;
  padding-top: 68px;

  /* This is a fix for this bug - https://s3.amazonaws.com/timothyfletcher/medullary-semicha-vulgarize-quadruplet.mp4 */
  transform: translate3d(0, 0, 0);
`;

const Icon = styled.div`
  position: absolute;
  right: 18px;
  top: 30px;
  path {
    fill: #ffffff;
  }
`;

const Spinner = styled.div`
  position: absolute;
`.withComponent((props) => <BaseSpinner {...props} />);

export { Buttons, FieldsContainer, Icon, Layout, MainVideoContainer, Spinner };

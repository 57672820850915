const trimProtocol = (str) => {
  try {
    const { host, pathname } = new URL(str);
    return host + pathname;
  } catch (_) {
    return str;
  }
};

const isUrlValid = (str = '') => {
  const regex = /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  return regex.test(str.toLowerCase());
};

module.exports = isUrlValid;
module.exports.isUrlValid = isUrlValid;
module.exports.trimProtocol = trimProtocol;

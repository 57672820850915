import { ACTIONS } from './constants';

const initialState = {
  profileHeaderVisible: false,
  swiperReady: false,
  activeIndex: 0,
};

export const views = (state = initialState, action) => {
  switch (action.type) {
    // Merge in a key or set of keys
    case ACTIONS.VIEWS.MERGE:
      return { ...state, ...action.payload };

    // Unset a key
    case ACTIONS.VIEWS.UNSET:
      return { ...state, [action.payload]: initialState[action.payload] };

    default:
      return state;
  }
};

import React from 'react';
import config from 'config';
import PropTypes from 'prop-types';
import * as Styled from './styled';
import CardLayout from '../ui/card-layout';
import { CtaButtonContainerFixed } from '../styles';
import GoogleAnalytics from 'lib/google-analytics';
import FriendsList from './friends-list';

function DiscoverCardComponent(props) {
  const { cta, onCtaClick, ...rest } = props;

  return (
    <CardLayout {...rest} full>
      <Styled.Scrollable scroll>
        <Styled.Header>
          <Styled.CardHeading children={props.title} />
          <Styled.CardSubHeading children={props.description} />
        </Styled.Header>
        <Styled.Content>
          <FriendsList friends={props.friends} />
        </Styled.Content>

        <CtaButtonContainerFixed>
          <Styled.CtaButton
            bg={props.brandColor}
            children="Get your Tap Bio"
            onClick={() => {
              GoogleAnalytics.ctaClick({ kind: 'discover' }, props.cta); // Internal
              props.onCtaClick(props.cta);
            }}
          />
        </CtaButtonContainerFixed>
      </Styled.Scrollable>
    </CardLayout>
  );
}

DiscoverCardComponent.propTypes = {
  friends: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onCtaClick: PropTypes.func.isRequired,
};

DiscoverCardComponent.defaultProps = {
  friends: [],
  cta: {
    url: `${config.landingHost}/@tap.bio`,
  },
};

export { DiscoverCardComponent };
export default DiscoverCardComponent;

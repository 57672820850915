import React from 'react';
import styled from 'styled-components/macro';
import { Crop, UnavailablePhoto, PhotoAlt } from './styles';

const Grid = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-auto-rows: min-content;
  min-height: 600px;

  grid-template-columns: ${(props) => {
    switch (props.view) {
      case 'grid_1':
        return '1fr';
      case 'grid_2':
        return '1fr 1fr';
      case 'grid_3':
        return '1fr 1fr 1fr';
      default:
        return '1fr 1fr';
    }
  }};
`;

const Photo = ({ photo, onLinkClick }) => (
  <a
    href={photo.href}
    onClick={onLinkClick}
    data-photo-id={photo.id}
    data-photo-permalink={photo.permalink}
    data-photo-media-url={photo.src}
  >
    <Crop
      style={{ backgroundImage: `url(${photo.src})` }}
      data-src={photo.src}
      data-href={photo.href}
    >
      {!photo.src && (
        <UnavailablePhoto>
          <div>This Photo Is Temporarily Unavailable</div>
          <PhotoAlt>{photo.href}</PhotoAlt>
        </UnavailablePhoto>
      )}
    </Crop>
  </a>
);

const Photos = (props) => (
  <Grid view={props.config.view}>
    {props.photos.map((photo) => (
      <Photo key={photo.id} photo={photo} onLinkClick={props.onLinkClick} />
    ))}
  </Grid>
);

export default Photos;

import React from 'react';

function Tiktok(props) {
  return (
    <svg viewBox="-32 0 512 512" {...props}>
      <path d="M432.73438 112.46484c-53.7422 0-97.46485-43.72265-97.46485-97.46484 0-8.28516-6.71875-15-15-15H239.9336c-8.28515 0-15 6.71484-15 15v329.36719c0 31.59375-25.70312 57.29687-57.30078 57.29687-31.59375 0-57.29687-25.70312-57.29687-57.29687 0-31.59766 25.70312-57.30078 57.29687-57.30078 8.28516 0 15-6.71485 15-15v-80.33594c0-8.28125-6.71484-15-15-15C75.19921 176.73047 0 251.93359 0 344.36719S75.19922 512 167.63281 512c92.4336 0 167.63672-75.19922 167.63672-167.63281V198.57422c29.85547 15.91797 63.07422 24.22656 97.46485 24.22656 8.28515 0 15-6.71484 15-15v-80.33594c0-8.28125-6.71485-15-15-15zm-15 79.71485c-32.02344-2.66406-62.4336-13.85156-88.70704-32.75-4.5664-3.28906-10.58984-3.74219-15.60156-1.17188-5.00781 2.5625-8.15625 7.71875-8.15625 13.34766v172.76172C305.26953 420.2578 243.52343 482 167.63281 482S30 420.25781 30 344.36719c0-70.82422 53.77344-129.32813 122.63281-136.82422v50.8125c-41.01562 7.13281-72.29687 42.98437-72.29687 86.01172 0 48.13672 39.16015 87.30078 87.29687 87.30078 48.14063 0 87.30078-39.16406 87.30078-87.30078V30h51.21094c6.8711 58.32031 53.26953 104.71875 111.58984 111.58984zm0 0" />
    </svg>
  );
}

Tiktok.defaultProps = {
  fill: 'currentColor',
  width: 24,
  height: 24,
};

export default Tiktok;

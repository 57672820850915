import React from 'react';
import { FullPage } from './full-page';

const User404 = ({ username, message, title }) => (
  <FullPage
    bg="#33154E"
    message={username ? `We can't seem to find a bio for @${username}` : message}
    title={title}
  />
);

User404.defaultProps = {
  title: 'Well that was unexpected',
  message: 'We canʼt seem to find that personʼs profile',
};

export default User404;

import React from 'react';
import { withProps } from 'recompose';
import Svg from './svg';

export default withProps({
  viewBox: '0 0 24 24',
  children: (
    <path d="M14.095 10.316 22.286 1h-1.94L13.23 9.088 7.551 1H1l8.59 12.231L1 23h1.94l7.51-8.543 6 8.543H23l-8.905-12.684zm-2.658 3.022-.872-1.218L3.64 2.432h2.98l5.59 7.821.869 1.219 7.265 10.166h-2.982l-5.926-8.3z" />
  ),
})(Svg);

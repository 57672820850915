import { withHandlers } from 'recompose';
import { YoutubeVideoPlayPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendVideoPlay: (props) => (accountId, cardId, videoId) => {
    const payload = YoutubeVideoPlayPayload.create({
      accountId,
      cardId,
      videoId,
    });
    StatsCollector.youtubeVideoPlay(payload);
  },
});

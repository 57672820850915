import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Text } from 'components/web-ui';

const Prompt = styled.div`
  text-align: center;
  line-height: 1.4;
  font-style: italic;

  & > span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
  }
`;

const MorePrompt = () => (
  <Prompt>
    <span>more</span>
  </Prompt>
);

const truncate = (lines) => css`
  overflow: hidden;
  display: box;
  display: -webkit-box;
  line-clamp: ${lines};
  -webkit-line-clamp: ${lines};
  box-orient: vertical;
  -webkit-box-orient: vertical;
`;

const TextBox = styled(({ truncated, ...props }) => <Text {...props} />)`
  font-size: 16px;
  ${(p) => p.truncated && truncate(3)};

  @media (min-height: 600px) {
    font-size: 18px;
  }

  @media (min-height: 700px) {
    font-size: 20px;
  }

  @media (min-height: 700px) {
    ${(p) => p.truncated && truncate(4)};
  }
`;

const Truncated = ({ truncated, children, ...props }) => {
  return (
    <React.Fragment>
      <TextBox truncated={truncated} children={children} />
      {truncated && <MorePrompt />}
    </React.Fragment>
  );
};

Truncated.defaultProps = {
  truncated: false,
};

export default Truncated;

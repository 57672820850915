import React from 'react';
import ErrorBoundary from './error-boundary';
import { withProps } from 'recompose';

const withErrorBoundary = (Component) => (props) => {
  const { component, ...rest } = props;
  return (
    <ErrorBoundary component={component}>
      <Component {...rest} />
    </ErrorBoundary>
  );
};

const withErrorComponent = (component) =>
  withProps(() => ({
    component,
  }));

export { withErrorBoundary, withErrorComponent };

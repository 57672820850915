import React from 'react';
import styled from 'styled-components/macro';
import { compose, defaultProps } from 'recompose';

const Container = styled.div`
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: ${(props) => props.containerColor};
  margin: ${(props) => props.margin};
`;

const Checkmark = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);
`;

const Stem = styled.div`
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: ${(props) => props.tintColor};
  left: 11px;
  top: 6px;
`;

const Kick = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: ${(props) => props.tintColor};
  left: 8px;
  top: 12px;
`;

export default compose(
  defaultProps({
    margin: 0,
    containerColor: '#EEEEEE',
    tintColor: '#000000',
  }),
)((props) => (
  <Container {...props}>
    <Checkmark {...props}>
      <Stem {...props} />
      <Kick {...props} />
    </Checkmark>
  </Container>
));

import * as Sentry from '@sentry/browser';
import { graphql } from '@apollo/react-hoc';
import { mailingListUnsubscribeMutation } from 'lib/graphql/mutations';

export const withMailingListUnsubscribeHandler = graphql(mailingListUnsubscribeMutation, {
  props: ({ mutate, ownProps }) => ({
    unsubscribe: async (cardId, emailAddress) => {
      try {
        return await mutate({
          mailingListUnsubscribeMutation,
          variables: { cardId, emailAddress },
        });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
  }),
});

export default withMailingListUnsubscribeHandler;

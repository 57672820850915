"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Title = exports.Name = exports.Featured = exports.Metadata = exports.Layout = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var srcSet = function srcSet(props) {
  if (!props.backgroundImage.startsWith('http')) return props.backgroundImage;
  var backgroundImageUrl = props.backgroundImage.split('?')[0];
  return ['linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(255,255,255,0.1))', "url(".concat(backgroundImageUrl, "?w=600&auto=format)"), "url(".concat(backgroundImageUrl, "?w=300&blur=200&px=16&auto=format&sat=-50)")].join(',');
};

var Layout = _styledComponents["default"].div.withConfig({
  displayName: "styled__Layout",
  componentId: "sc-1aaisv5-0"
})(["flex:1;display:flex;flex-direction:column;background-image:", ";border-radius:6px;width:100%;position:relative;overflow:hidden;background-size:cover;background-position:center center;:active{opacity:0.75;}"], srcSet);

exports.Layout = Layout;

var Metadata = _styledComponents["default"].div.withConfig({
  displayName: "styled__Metadata",
  componentId: "sc-1aaisv5-1"
})(["position:absolute;bottom:1em;left:1em;text-align:left;text-shadow:0 1px 1px rgba(0,0,0,0.16);"]);

exports.Metadata = Metadata;

var Name = _styledComponents["default"].h2.withConfig({
  displayName: "styled__Name",
  componentId: "sc-1aaisv5-2"
})(["margin:0;color:white;font-size:15px;line-height:1.25em;"]);

exports.Name = Name;

var Featured = _styledComponents["default"].p.withConfig({
  displayName: "styled__Featured",
  componentId: "sc-1aaisv5-3"
})(["margin:0;text-transform:uppercase;font-size:9px;color:#dbd5d5;text-shadow:0 2px 4px rgba(0,0,0,0.5);"]);

exports.Featured = Featured;

var Title = _styledComponents["default"].p.withConfig({
  displayName: "styled__Title",
  componentId: "sc-1aaisv5-4"
})(["color:rgba(255,255,255,0.6);font-size:13px;line-height:1;margin:0;"]);

exports.Title = Title;
var _default = {
  Layout: Layout,
  Metadata: Metadata,
  Featured: Featured,
  Name: Name,
  Title: Title
};
exports["default"] = _default;
import localStorage from './storages/localstorage';

const TOKEN_KEY_NAME = 'tb_auth_token';
const TOKEN_KEY_VERSION = 'v1';

const Authentication = {
  key() {
    if (process.env.NODE_ENV !== 'production') {
      return `${process.env.NODE_ENV}_${TOKEN_KEY_NAME}_${TOKEN_KEY_VERSION}`;
    }
    return `${TOKEN_KEY_NAME}_${TOKEN_KEY_VERSION}`;
  },

  isAuthenticated() {
    return !!this.getToken();
  },

  authenticate(token, expires = 365) {
    if (token) {
      localStorage.set(this.key(), token);
    }
  },

  getToken() {
    return localStorage.get(this.key());
  },

  logOut() {
    localStorage.remove(this.key());
  },
};

export default Authentication;

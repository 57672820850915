const isEmailValid = require('@tap-bio/core/validators/email');
const isUrlValid = require('@tap-bio/core/validators/url');
const isTelValid = require('@tap-bio/core/validators/tel');

const telProtocol = 'tel:';

function normalizeTelPrefix(tel) {
  let value = tel.replace(/\*/g, '');

  if (!/^(tel:)?\+[\d]{1,}\*/.test(value)) {
    if (value.startsWith(telProtocol)) {
      if (value[telProtocol.length] !== '+') {
        value = value.substring(0, telProtocol.length)
          + '+'
          + value.substring(telProtocol.length, value.length);
      }
    } else {
      if (value[0] !== '+') {
        value = `+${value}`;
      }
    }
  }
  return value;
}

function stripLeadingSlashes(url = '') {
  return url.replace(/^[/]{1,}/, '');
}

function appendProtocol(url = '') {
  if (isTelValid(url)) return 'tel:' + normalizeTelPrefix(url);
  if (isEmailValid(url)) return 'mailto:' + stripLeadingSlashes(url);
  if (isUrlValid(url)) return 'http://' + stripLeadingSlashes(url);

  return url;
}

function hasProtocol(url = '') {
  url = url.toLowerCase();

  if (url.startsWith('http:')) return true;
  if (url.startsWith('https:')) return true;
  if (url.startsWith('mailto:')) return true;
  if (url.startsWith('tel:')) return true;

  return false;
}

function resolveCtaUrl(url) {
  if (/^(tel:)?\+?[\d]{1,}\*/.test(url)) url = normalizeTelPrefix(url);
  if (!hasProtocol(url)) url = appendProtocol(url);

  return url;
}

module.exports = resolveCtaUrl;
module.exports.resolveCtaUrl = resolveCtaUrl;

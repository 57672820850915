import tinycolor from 'tinycolor2';

function contrastAdjust(inputColor, value = 20) {
  const color = tinycolor(inputColor);
  const luminance = color.getLuminance();

  if (luminance < 0.5) {
    color.lighten(value);
  } else {
    color.darken(value);
  }
  return color.toRgbString();
}

function darken(inputColor, value = 10) {
  const color = tinycolor(inputColor);
  color.darken(value);
  return color.toRgbString();
}

export { contrastAdjust };
export { darken };

export default {
  contrastAdjust,
  darken,
};

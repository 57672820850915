import React from 'react';
import styled from 'styled-components/macro';
import { withHandlers } from 'recompose';

const Link = styled.a`
  color: white;
  width: 41%;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.backgroundColor};
  font-weight: 700;
  border-radius: 99999px;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
`.withComponent(({ backgroundColor, children, ...props }) => <a {...props} children={children} />);

const OpenInAppButton = ({ backgroundColor, handleClick }) => (
  <Link backgroundColor={backgroundColor} children="Open In-app" onClick={handleClick} />
);

/**
 *  How to manually test this code in Android
 *    1. Find a REAL android device. I used a Nexus 5 running Android 6.0.1
 *    2. Ensure you're running the server as detailed in README.md
 *    3. Disable the YouTube app. This is a two step process. First, you must downgrade it to the base factory installed version. Then you can disable it. The best way to do this seems to be via `Settings > Apps > YouTube > Disable`.
 *    4. Now you can click the 'Open in App' button and it should open in the browser (as a fallback)
 *    5. Next you should re-enable the YouTube app and try the process again. Clicking the button should open the video in the YouTube app.
 */

const withPlatformLink = withHandlers({
  handleClick: (props) => (event) => {
    event.preventDefault();
    const browserLocation = `https://www.youtube.com/watch/?v=${props.video.id}`;
    const appLocation = `vnd.youtube://www.youtube.com/watch?v=${props.video.id}`;
    if (/Android/i.test(window.navigator.userAgent)) {
      window.location = appLocation;
      setTimeout(function() {
        window.location = browserLocation;
      }, 25);
    } else {
      // On iOS this loads in either the browser or app depending on the user's Universal Link
      // settings
      window.location = browserLocation;
    }
  },
});

export default withPlatformLink(OpenInAppButton);

import storage from './storage';

const CURRENT_ACCOUNT_KEY_NAME = 'tb_current_account';

const CurrentAccount = {
  getCurrentAccount() {
    return storage.get(CURRENT_ACCOUNT_KEY_NAME);
  },

  setCurrentAccount(account) {
    if (account) {
      storage.set(CURRENT_ACCOUNT_KEY_NAME, account);
    }
  },

  hasCurrentAccount() {
    return !!this.getCurrentAccount();
  },
};

export default CurrentAccount;

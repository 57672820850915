import React from 'react';
import styled from 'styled-components/macro';
import { parseDate, formatDate } from 'lib/date';

const Container = styled.div`
  color: white;
  font-size: 13px;
  margin-top: 5px;
  line-height: 1.3;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  text-align: left;
`;

const Title = styled.span`
  color: white;
  font-weight: 600;
`;

const PublishedAt = styled.span`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 200;
  margin-left: 8px;
`;

const VideoMeta = (props) => {
  const { video } = props;
  const parsedDate = parseDate(video.publishedAt);
  const publishedAt = formatDate(parsedDate, 'MMMM d, yyyy');

  return (
    <Container key={video.id}>
      <Title children={video.title} />
      <PublishedAt children={publishedAt} />
    </Container>
  );
};

export default VideoMeta;

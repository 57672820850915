import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import compose from 'lib/compose';
import { shouldUpdate, omitProps, withProps } from 'lib/recompose';
import { withAccount } from 'lib/graphql';
import { withUsername } from 'hocs/username';
import StoryCard from 'components/story-card';
import StorySlider from '@tap-bio/ui/patterns/story-slider';

const peekaboo = keyframes`
  from { transform: translate3d(0, 0, 0); }
  50% { transform: translate3d(-50%, 0, 0); }
  to { transform: translate3d(0, 0, 0); }
`;

const StyledStorySlider = styled(StorySlider)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Card = styled(StoryCard)`
  width: 100%;
  height: 100%;
  animation: ${(p) => p.peekabooAnimation && peekaboo} 1s ease 1s;
`.withComponent(omitProps('peekabooAnimation')(StoryCard));

function SwipeableDeck(props) {
  const {
    children,
    username,
    account,
    onSwiperInit,
    onSlideChangeEnd,
    onContentScroll,
    isOwnerAuthenticated,
    peekabooAnimation,
  } = props;

  const cards = children.map((card, index) => {
    const { backgroundZoom, backgroundXPosition, backgroundYPosition, ...cardProps } = card;
    return (
      <Card
        {...cardProps}
        cardIndexInDeck={index}
        isOwnerAuthenticated={isOwnerAuthenticated}
        profile={account.profile}
        onContentScroll={onContentScroll}
        peekabooAnimation={peekabooAnimation}
      />
    );
  });

  return (
    <StyledStorySlider
      onInit={onSwiperInit}
      onSlideChangeTransitionEnd={onSlideChangeEnd}
      cards={cards}
      history={{ key: `@${username}/cards` }}
    />
  );
}

export default compose(
  withUsername,
  withAccount,

  withProps((props) => {
    const { publicCards } = props.account.defaultDeck;
    const peekabooAnimation = publicCards.length > 1;
    return { peekabooAnimation };
  }),

  shouldUpdate((props, nextProps) => {
    const { username, account } = props;
    const { username: nextUsername, account: nextAccount } = nextProps;

    let test = false;
    test = username !== nextUsername;
    test = JSON.stringify(account) !== JSON.stringify(nextAccount);

    return test;
  }),
)(SwipeableDeck);

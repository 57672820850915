import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  color: white;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.33);
  border-radius: 5px;
  margin: 100px auto 0 auto;
  padding: 10px;
`;

const NoVideos = (props) => <Container>This YouTube card has no videos at the moment.</Container>;

export default NoVideos;

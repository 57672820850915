const theme = {
  breakpoints: [32, 48, 64, 80],
  space: [0, 4, 8, 12, 16, 24, 32, 64, 128],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  weights: [400, 700],
  colors: {
    blue: '#459beb',
    darkBlue: '#126dee',
    darkPurple: '#570b99',
    gray10: '#031530',
    gray20: '#213149',
    gray30: '#404e62',
    gray40: '#5e6a7b',
    gray50: '#7c8694',
    gray60: '#9aa2ad',
    gray70: '#b9bfc6',
    gray80: '#d7dbdf',
    gray90: '#f5f7f8',
    purple: '#9013fe',
  },
  radius: 12,
  font: 'Roboto Regular, Roboto, sans-serif',
  monospace: '"SF Mono", "Roboto Mono", Menlo, monospace',
};

export default theme;

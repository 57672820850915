import { Heading } from 'components/web-ui';
import styled from 'styled-components/macro';
import verticalSmoothScrolling from 'css/vertical-smooth-scrolling-css.jsx';
import { CtaButton as CtaButtonBase } from '../styles';

export const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 70px;
  position: relative;
  height: 100%;
  ${verticalSmoothScrolling};
`;

export const Header = styled.header`
  margin-bottom: 5px;
`;

export const CardHeading = styled(Heading)`
  font-size: 20px;
  text-align: center;
  color: white;
  margin-top: 5px;
`;

export const CardSubHeading = styled(Heading)`
  text-align: center;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5px;
  margin-left: 60px;
  margin-right: 60px;
`;

export const Content = styled.div`
  display: flex;
  padding: 2px;
  padding-bottom: 50px;
  flex-shrink: 0;
`;

export const CardPadding = styled.div`
  padding: 160px 10px;
`;

export const CtaButton = styled(CtaButtonBase)`
  background-color: rgba(51, 21, 78, 0.88);
  border: 0;
`;

export default {
  Scrollable,
  Header,
  CardHeading,
  CardSubHeading,
  Content,
  CtaButton,
};

import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components/macro';
import propDeprecationWarning from './prop-deprecation-warning';

const Text = styled.p`
  margin: 0px;
  font-size: 1em;
  color: ${(p) => p.color || 'inherit'};
`;

const TextComponent = (props) => {
  if (props.m) propDeprecationWarning(props, 'm', 'Text');
  if (props.mt) propDeprecationWarning(props, 'mt', 'Text');
  if (props.mr) propDeprecationWarning(props, 'mr', 'Text');
  if (props.mb) propDeprecationWarning(props, 'mb', 'Text');
  if (props.ml) propDeprecationWarning(props, 'ml', 'Text');
  if (props.mx) propDeprecationWarning(props, 'mx', 'Text');
  if (props.my) propDeprecationWarning(props, 'my', 'Text');
  if (props.p) propDeprecationWarning(props, 'p', 'Text');
  if (props.pt) propDeprecationWarning(props, 'pt', 'Text');
  if (props.pr) propDeprecationWarning(props, 'pr', 'Text');
  if (props.pb) propDeprecationWarning(props, 'pb', 'Text');
  if (props.pl) propDeprecationWarning(props, 'pl', 'Text');
  if (props.px) propDeprecationWarning(props, 'px', 'Text');
  if (props.py) propDeprecationWarning(props, 'py', 'Text');
  if (props.fontSize) propDeprecationWarning(props, 'fontSize', 'Text');
  if (props.fontFamily) propDeprecationWarning(props, 'fontFamily', 'Text');
  if (props.fontWeight) propDeprecationWarning(props, 'fontWeight', 'Text');
  if (props.textAlign) propDeprecationWarning(props, 'textAlign', 'Text');
  if (props.lineHeight) propDeprecationWarning(props, 'lineHeight', 'Text');
  if (props.letterSpacing) propDeprecationWarning(props, 'letterSpacing', 'Text');
  if (props.center) propDeprecationWarning(props, 'center', 'Text');

  return <Text {...props} />;
};

Text.propTypes = {
  color: PropTypes.string,
};

export default TextComponent;

import React from 'react';
import styled from 'styled-components/macro';
import View from 'components/ui/view';
import Spinner from 'components/ui/spinner';
import Checkmark from 'components/ui/checkmark';
import CardLayout from 'components/story-card/ui/card-layout';
import { convertHexToRgba } from 'lib/color-utils';
import { Title, Description, CtaButton } from '../styles';
import { fixLineBreakSpacers } from 'lib/utils';

const Layout = styled(CardLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 20px 0 20px;
`;

const Scrollable = styled(View)`
  padding: 85px 20px 10px 20px;
  margin: -20px -20px 0 -20px;
`;

const TextInput = styled.input`
  margin-bottom: 10px;
  width: 100%;
  padding: 17px;
  border: 1.5px solid #ffffff;
  border-radius: 2em;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.35) 100%
  );
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  text-shadow: none;
  outline: none;

  ::placeholder {
    color: #ffffff;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  padding: 15px 20px;
  width: 100%;
  min-height: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  text-shadow: none;
  background-color: #ffffff;
  border-radius: 6px;
  overflow: none;
`;

const MessageHighlighted = styled.span`
  font-weight: 600;
  display: block;
`;

const ButtonSpinner = styled(Spinner)`
  height: 56px;
`;

const MailingListCard = ({
  buttonColor,
  cta,
  ctas,
  emailAddress,
  emailFieldError,
  handleSubscribeClick,
  isEmailAddressValid,
  isWaiting,
  mailingList,
  sendCardSubmit,
  setEmailAddress,
  setEmailFieldError,
  setIsWaiting,
  setTemporarySubscription,
  subscribe,
  subscription: { isSubscribed, emailAddress: subscriptionEmailAddress },
  subscriptionStatus,
  temporarySubscription,
  unsubscribe,
  ...props
}) => {
  const { url } = (mailingList || {});
  return (
    <Layout {...props} full>
      <Scrollable flex="column" scroll>
        {props.title && <Title children={fixLineBreakSpacers(props.title)} />}
        {props.description && <Description children={fixLineBreakSpacers(props.description)} />}
        {isSubscribed ? (
          <Message>
            <Checkmark
              margin="0 0 1em 0"
              containerColor={convertHexToRgba(buttonColor || props.profile.brandColor)}
              tintColor="#FFFFFF"
            />
            {cta.message} <MessageHighlighted>{subscriptionEmailAddress}</MessageHighlighted>
          </Message>
        ) : (
          <React.Fragment>
            <form action={url} method="POST">
              {emailFieldError && <Message>{emailFieldError}</Message>}
              <TextInput
                name="MERGE0"
                id="MERGE0"
                type="email"
                placeholder="Email address"
                value={emailAddress}
                onChange={setEmailAddress}
                spellCheck={false}
              />
              <CtaButton
                bg={convertHexToRgba(buttonColor || props.profile.brandColor)}
                children={isWaiting ? <ButtonSpinner /> : cta.title}
                disabled={!isEmailAddressValid}
                onClick={handleSubscribeClick}
              />
            </form>
          </React.Fragment>
        )}
      </Scrollable>
    </Layout>
  );
};

export default MailingListCard;

import React from 'react';
import styled from 'styled-components/macro';
import { View } from 'components/ui';
import { TapBioLogo } from 'components/ui/icons';

export const Layout = styled(View)`
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const H1 = styled.h1`
  font-size: 24px;
  text-transform: capitalize;
  line-height: 1;
  color: white;
  text-align: center;
`;

export const Text = styled.p`
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  margin: 5px 0;
`;

const Logo = styled(TapBioLogo)`
  width: 125px;
  fill: rgba(255, 255, 255, 0.33);
  margin-bottom: 3rem;
`;

export const FullPage = ({ message, title, username, ...props }) => (
  <Layout full flex {...props}>
    <Logo />
    <H1 children={title} />
    <Text children={message} />
  </Layout>
);

FullPage.defaultProps = {
  title: 'Well that was unexpected',
  message: 'Something went wrong!',
};

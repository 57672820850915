import styled from 'styled-components/macro';
import { darken, contrastAdjust } from 'components/web-ui/color-utils';

const Button = styled.button`
  font-weight: 400;
  padding-left: 32px;
  padding-right: 32px;
  background-color: ${(p) => p.bg};
  color: inherit;
  border: 0;

  &:hover {
    outline: none;
    background-color: ${(p) => darken(p.bg)};
  }

  &:active {
    outline: none;
    background-color: ${(p) => darken(p.bg)};
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${(p) => contrastAdjust(p.bg)};
  }
`;

export default Button;

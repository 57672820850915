import React from 'react';
import View from './view';
import styled from 'styled-components/macro';

const Layout = styled(View)`
  fill: ${(props) => props.fill};
`;

const Spinner = ({ width, height, ...props }) => (
  <Layout full flex center {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={width} height={height}>
      <circle transform="translate(8 0)" cx="0" cy="16" r="0">
        <animate
          attributeName="r"
          values="0; 4; 0; 0"
          dur="1.2s"
          repeatCount="indefinite"
          begin="0"
          keyTimes="0;0.2;0.7;1"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
          calcMode="spline"
        />
      </circle>
      <circle transform="translate(16 0)" cx="0" cy="16" r="0">
        <animate
          attributeName="r"
          values="0; 4; 0; 0"
          dur="1.2s"
          repeatCount="indefinite"
          begin="0.3"
          keyTimes="0;0.2;0.7;1"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
          calcMode="spline"
        />
      </circle>
      <circle transform="translate(24 0)" cx="0" cy="16" r="0">
        <animate
          attributeName="r"
          values="0; 4; 0; 0"
          dur="1.2s"
          repeatCount="indefinite"
          begin="0.6"
          keyTimes="0;0.2;0.7;1"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
          calcMode="spline"
        />
      </circle>
    </svg>
  </Layout>
);

Spinner.defaultProps = {
  fill: 'rgba(255, 255, 255, 0.9)',
  width: 48,
};

export default Spinner;

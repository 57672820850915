import React from 'react';
import styled from 'styled-components/macro';
import * as Recompose from 'lib/recompose';
import { isNull } from 'lib/utils';
import { connect } from 'store';
import { withUsername } from 'hocs';
import withCollectInstagramLinkClick from 'hocs/with-collect-instagram-photo-click';
import { withAccount, withInstagramFeed } from 'lib/graphql';
import Photos from './photos';
import LoadingErrorMessage from './loading-error-message';
import LoadingSpinner from './loading-spinner';
import EmptyFeedMessage from './empty-feed-message';
import FeaturedPhoto from './featured-photo';

const Container = styled.div`
  margin: 0 5px;
  padding-bottom: ${(p) => (p.hasCta ? '79px' : '0')};
`;

const InstagramFeed = (props) => (
  <Container hasCta={props.hasCta}>
    <EmptyFeedMessage visible={props.hasNoPhotos} />
    <LoadingSpinner visible={props.isLoading} />
    <LoadingErrorMessage
      visible={props.hasError}
      instagramHandle={props.instagramUserName}
      errorType={props.errorType}
    />
    <FeaturedPhoto photo={props.featuredPhoto} onLinkClick={props.onLinkClick} />
    <Photos photos={props.photos} onLinkClick={props.onLinkClick} config={props.config} />
  </Container>
);

export default Recompose.compose(
  connect((state) => ({
    swiperState: state.swiperState,
  })),
  Recompose.defaultProps({
    feed: {
      nextMaxId: null,
      photos: [],
    },
  }),
  withUsername,
  withAccount,
  withInstagramFeed,
  withCollectInstagramLinkClick,

  Recompose.withState('requestManager', 'setRequestManager', {
    requestCount: 0,
    maxRequestCount: 15, // 33 photos loaded per request = 495 photos searched to find 20 with links
    minRequiredPhotoCount: 20,
  }),

  Recompose.withProps(({ feed, request }) => {
    const errorType = feed.errorType;
    const hasNoPhotos = feed.photos.length === 0;
    const isLoading = request.loading;

    return {
      errorType,
      hasNoPhotos: hasNoPhotos && !isLoading && !errorType,
      hasError: errorType && !isLoading,
      instagramUserName: feed.username,
      isLoading: isLoading && !errorType,
    };
  }),

  Recompose.withProps(({ config, feed, loading, ...rest }) => {
    if (feed.photos.length && config.hasFeaturedImage) {
      return {
        featuredPhoto: feed.photos[0],
        photos: feed.photos.slice(1),
      };
    }
    return { photos: feed.photos };
  }),

  Recompose.withHandlers({
    onLinkClick: ({ sendInstagramLinkClick, account }) => (event) => {
      event.preventDefault();
      const {
        href: url,
        dataset: { photoId, photoPermalink },
      } = event.currentTarget;
      const { id: accountId } = account;
      sendInstagramLinkClick(accountId, url, photoId, photoPermalink);
      window.location = url;
    },
  }),

  Recompose.lifecycle({
    shouldComponentUpdate(nextProps) {
      // Cancel update if we're still loading
      if (nextProps.request.loading) {
        console.debug('Prevented update because network request is still in flight');
        return false;
      }

      // Continue with update if we're initiating a new lazy load
      if (nextProps.shouldLazyLoad && this.props.shouldLazyLoad !== nextProps.shouldLazyLoad) {
        console.debug('Continuing update because lazy load initiated');
        return true;
      }

      // Cancel update if we're still dealing with the same query
      const prevMaxId = this.props.feed.nextMaxId;
      const nextMaxId = nextProps.feed.nextMaxId;
      if (nextMaxId === prevMaxId) {
        // Continue with update when there's only a single page of photos
        if (isNull(nextMaxId)) return true;

        // Otherwise prevent the update
        console.debug(`Prevented update because nextMaxId is equal to prevMaxId - ${nextMaxId}`);
        return false;
      }

      return true;
    },

    componentDidUpdate(prevProps) {
      const {
        feed,
        requestManager: { requestCount, maxRequestCount, minRequiredPhotoCount },
      } = this.props;

      const hasLoadedAllPhotos = isNull(feed.nextMaxId);
      const hasLoadedMinimumNumberOfPhotos = minRequiredPhotoCount <= feed.photos.length;
      const hasMadeMaxRequestCount = requestCount >= maxRequestCount;

      if (hasLoadedAllPhotos) {
        console.debug(`All photos (${feed.photos.length}) loaded`);
        this.props.setShouldLazyLoad('disabled');
        return;
      }

      if (this.props.shouldLazyLoad) {
        console.debug(`Lazy load is enabled - loading photos from ${feed.nextMaxId}`);
        this.props.loadMoreEntries();
        return;
      }

      // Update the count of how many photos have been loaded and how many requests
      // have been made.
      console.debug(`Processing load photos request ${requestCount + 1}`);

      this.props.setRequestManager((state) => ({
        ...state,
        requestCount: state.requestCount + 1,
      }));

      if (hasLoadedMinimumNumberOfPhotos) {
        console.debug(`Minimum number of photos (${minRequiredPhotoCount}) loaded.`);
      } else {
        if (hasMadeMaxRequestCount) {
          console.debug(
            `Hit max requests (${maxRequestCount}) but only found ${feed.photos.length}/${minRequiredPhotoCount} min photos`,
          );
          return;
        }

        this.props.loadMoreEntries();
      }
    },
  }),
)(InstagramFeed);

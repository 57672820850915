import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { compose, mapProps, defaultProps, setPropTypes } from 'recompose';
import AnimatedBackground from './animated-background';
import verticalSmoothScrolling from 'css/vertical-smooth-scrolling-css.jsx';

const passThrough = css`
  pointer-events: none;
  & > * {
    pointer-events: initial;
  }
`;

const scrollable = css`
  ${verticalSmoothScrolling};
`;

const flex = (direction = 'row') => {
  if (direction === true) direction = 'row';

  return css`
    display: flex;
    flex-direction: ${direction};
  `;
};

const center = css`
  justify-content: center;
  align-items: center;
`;

class ViewBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showContentOverlay: false };
  }

  componentDidMount() {
    const showContentOverlay = this.element.offsetHeight < this.element.scrollHeight;
    this.setState({ showContentOverlay });
  }

  componentDidUpdate(prevProps) {
    const showContentOverlay = this.element.offsetHeight < this.element.scrollHeight;
    if (!this.props.scroll || !showContentOverlay || this.state.showContentOverlay) return;
    this.setState({ showContentOverlay: true });
  }

  render() {
    const {
      sendCardClick,
      renderHrefWithProtocol,
      backgroundAnimated,
      backgroundMediaUrl,
      backgroundColorSolid,
      shouldLazyLoad,
      setShouldLazyLoad,
      visible,
      transparent,
      fixed,
      absolute,
      relative,
      full,
      scroll,
      flex,
      pass,
      overflow,
      center,
      sticky,
      ...props
    } = this.props;

    return (
      <React.Fragment>
        {!backgroundColorSolid && backgroundAnimated && (
          <AnimatedBackground backgroundMediaUrl={backgroundMediaUrl} />
        )}
        <div {...props} ref={(element) => (this.element = element)} />
      </React.Fragment>
    );
  }
}

const View = styled(ViewBase)`
  top: 0;
  left: 0;
  pointer-events: initial;
  display: block;
  width: ${(p) => p.full};
  height: ${(p) => p.full};
  overflow: ${(p) => p.overflow};
  position: ${(p) => p.fixed};
  ${(p) => p.flex && flex(p.flex)};
  ${(p) => p.pass && passThrough};
  ${(p) => p.scroll && scrollable};
  ${(p) => p.center && center};
  ${(p) => p.bg && `background-color: ${p.bg}`};
`;

export default compose(
  setPropTypes({
    fixed: propTypes.bool,
    absolute: propTypes.bool,
    relative: propTypes.bool,
    full: propTypes.bool,
    scroll: propTypes.bool,
    flex: propTypes.oneOf(['row', 'column', true, false]),
    pass: propTypes.bool,
    overflow: propTypes.bool,
    center: propTypes.bool,
  }),
  defaultProps({
    scroll: false,
    fixed: false,
    absolute: false,
    relative: false,
    flex: false,
    full: false,
    pass: false,
    overflow: true,
    center: false,
  }),
  mapProps((props) => ({
    ...props,
    overflow: props.overflow ? null : 'hidden',
    fixed: props.fixed ? 'fixed' : props.absolute && 'absolute',
    full: props.full && '100%',
  })),
)(View);

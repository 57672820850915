import { graphql } from '@apollo/react-hoc';
import { publicInstagramFeedWithCardIdQuery } from './queries';

const withInstagramFeed = graphql(publicInstagramFeedWithCardIdQuery, {
  options: (props) => ({
    variables: { cardId: props.cardId, nextMaxId: props.feed.nextMaxId },
    notifyOnNetworkStatusChange: true,
    options: {
      notifyOnNetworkStatusChange: true,
    },
  }),

  props: ({ data: { publicInstagramFeedWithCardId, fetchMore, ...request }, ownProps }) => {
    return {
      request,
      feed: {
        ...ownProps.feed,
        ...publicInstagramFeedWithCardId,
        errorType: request.error ? request.error.graphQLErrors[0].type : null,
        username: request.error ? request.error.graphQLErrors[0].username : null,
      },
      loadMoreEntries() {
        return fetchMore({
          // query: ... (you can specify a different query. The previous query is used by default)
          variables: {
            nextMaxId: publicInstagramFeedWithCardId.nextMaxId,
          },
          notifyOnNetworkStatusChange: true,
          options: {
            // fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            // If we've just made a request, disable lazy load. It'll be re-enabled on the next
            // scroll. If we don't do this it'll iterate forever.
            ownProps.setShouldLazyLoad(false);

            return {
              request,
              ...previousResult,
              ...fetchMoreResult,
              publicInstagramFeedWithCardId: {
                ...previousResult.publicInstagramFeedWithCardId,
                ...fetchMoreResult.publicInstagramFeedWithCardId,
                photos: [
                  ...previousResult.publicInstagramFeedWithCardId.photos,
                  ...fetchMoreResult.publicInstagramFeedWithCardId.photos,
                ],
              },
            };
          },
        });
      },
    };
  },
});

export { withInstagramFeed };
export default withInstagramFeed;

import { withHandlers } from 'recompose';
import { InstagramLinkClickPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendInstagramLinkClick: (props) => (accountId, url, instagramPhotoId, instagramPermalink) => {
    const payload = InstagramLinkClickPayload.create({
      accountId,
      url,
      instagramPhotoId,
      instagramPermalink,
      viewType: InstagramLinkClickPayload.ViewType.GRID,
    });
    StatsCollector.instagramLinkClick(payload);
  },
});

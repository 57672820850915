import React from 'react';
import styled from 'styled-components/macro';
import Masonry from 'react-masonry-component';
import {
  CopyWrapper,
  Description,
  GutterSizer,
  Image,
  ImageFallback,
  ImageWrapper as BaseImageWrapper,
  Title,
} from './styled';
import TextTruncate from 'react-text-truncate';

const ImageWrapper = styled(BaseImageWrapper)`
  width: ${(props) => {
    switch (props.view) {
      case 'masonry_2':
        return 'calc(50% - 2.5px)'; // Gutter is 5px so each image should be 2.5px narrower.
      case 'masonry_3':
        return 'calc(33.3333333333% - 3.3333333333px)';
      default:
        return 'calc(50% - 2.5px)';
    }
  }};
`;

// min-height is required to push any CTA to the bottom and leave a gap for it
// when scrolled to the bottom.
const Container = styled.div`
  /*
  Expand to take up all available height
  Note that for this to work the parent must be a flex container
   */
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${(p) => (p.hasCta ? '74px' : '0')};
`;

export default ({ images, handleClick, view, hasCta }) => {
  if (!['masonry_2', 'masonry_3'].includes(view)) return null;

  return (
    <Container hasCta={hasCta}>
      <Masonry
        options={{
          gutter: '.gutter-sizer',
          horizontalOrder: true,
          transitionDuration: 0,
        }}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
      >
        {images.map((image) => (
          <ImageWrapper view={view} key={image.id} onClick={(event) => handleClick(image)}>
            {/*
            If the image's aspect ratio is null then the Image component will have a zero height.
            In this case we'll render the child ImageFallback component which provides the
            appropriate dimensions to 'expand' the Image component.
          */}
            <Image alt="A gallery image" imageUrl={image.imageUrl} aspectRatio={image.aspectRatio}>
              {!image.aspectRatio && <ImageFallback src={image.imageUrl} alt="" />}
            </Image>

            <GutterSizer className="gutter-sizer" />
            <CopyWrapper>
              <Title>
                <TextTruncate line={1} truncateText="…" text={image.title} />
              </Title>
              <Description>
                <TextTruncate line={2} truncateText="…" text={image.description} />
              </Description>
            </CopyWrapper>
          </ImageWrapper>
        ))}
      </Masonry>
    </Container>
  );
};

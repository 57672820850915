import { getYouTubeVideosQuery } from 'lib/graphql/queries';
import { graphql } from '@apollo/react-hoc';

export const withYouTubeVideos = graphql(getYouTubeVideosQuery, {
  options: ({ youtube: { username, uploadsPlaylistId, alternatePlaylistId } }) => ({
    variables: { username, uploadsPlaylistId, alternatePlaylistId },
  }),
});

export default withYouTubeVideos;

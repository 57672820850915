import { withHandlers } from 'recompose';
import { PageViewPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendPageView: (props) => (accountId) => {
    const payload = PageViewPayload.create({
      accountId,
    });
    StatsCollector.pageView(payload);
  },
});

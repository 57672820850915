import React from 'react';
import styled from 'styled-components/macro';
import * as Recompose from 'lib/recompose';
import Spinner from 'components/ui/spinner';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LoadingSpinner = (props) => {
  return (
    <Container>
      <Spinner fill="rgba(0,0,0,0.25)" />
    </Container>
  );
};

export default Recompose.branch((props) => !props.visible, Recompose.renderNothing)(LoadingSpinner);

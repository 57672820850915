import React from 'react';
import { compose, defaultProps } from 'lib/recompose';
import styled from 'styled-components/macro';
import { Text, Heading } from 'components/web-ui';
import View from 'components/ui/view';
import CardLayout from 'components/story-card/ui/card-layout';
import SocialMediaLink from './social-media-link';
import { trimProtocol } from '@tap-bio/core/validators/url';

const Scrollable = styled(View)`
  &::before {
    content: '';
    display: block;
    padding-bottom: 80%;
  }

  padding: 70px 8% 120px 8%;
  height: 100%;
`;

const Name = styled(Heading)`
  flex-shrink: 0;
  font-weight: bold;
  font-size: 30px;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.64);
  text-align: center;
  margin: 0;
`;

const Title = styled(Text)`
  flex-shrink: 0;
  margin: 8px 0;
  font-size: 16px;
  text-align: center;
`;

const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin: 15px 0px;
  flex-wrap: wrap;

  & > * {
    margin: 0 7px 10px 7px;
  }
`;

const Bio = styled.section`
  text-align: left;
  font-size: 19.5px;
  white-space: pre-line;
`;

const WebsiteLink = styled.a`
  color: #fff;
  display: block;

  :active {
    opacity: 0.75;
  }
`;

const ProfileCard = ({ buttonColor, ctas, showMore, setShowMore, profile, ...props }) => {
  return (
    <CardLayout {...props} profile={profile}>
      <Scrollable scroll>
        <Name children={profile.name} />
        <Title children={profile.title} />

        {profile.website && (
          <WebsiteLink href={profile.website} children={trimProtocol(profile.website)} />
        )}

        <SocialButtons>
          {ctas.map((cta) => (
            <SocialMediaLink
              key={cta.id}
              width={48}
              height={48}
              href={cta.url}
              icon={cta.title.toLowerCase()}
              onClick={(e) => {
                // Don't let the click go through to the Content component
                e.stopPropagation();
              }}
            />
          ))}
        </SocialButtons>

        <Bio>
          <Text children={profile.bio} />
        </Bio>
      </Scrollable>
    </CardLayout>
  );
};

export default compose(
  //
  defaultProps({ ctas: [] }),
)(ProfileCard);

import querystring from 'querystring';
import { withProps } from 'recompose';

export const hasBlendMode = (photoUrl) => {
  const params = photoUrl.split('?')[1];
  const qs = querystring.parse(params);
  return qs.blend && qs.bm !== 'normal' ? true : false;
};

export default withProps(({ backgroundMediaUrl, uploadedPhotoUrl, defaultBackgroundMediaUrl }) => {
  if (!backgroundMediaUrl && !uploadedPhotoUrl && !defaultBackgroundMediaUrl) return;
  const photoUrl = uploadedPhotoUrl || backgroundMediaUrl || defaultBackgroundMediaUrl;
  return { hasBlendMode: hasBlendMode(photoUrl) };
});

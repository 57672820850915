const { NODE_ENV, REACT_APP_DEPLOYMENT } = process.env;
const env = REACT_APP_DEPLOYMENT || NODE_ENV || 'development';

const config = require(`./${env}.json`);

let overrides = {};

try {
  overrides = require(`./${env}-overrides.json`);
} catch (e) {}

export default {
  env,
  ...config,
  ...overrides,
};

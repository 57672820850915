import { loader } from 'graphql.macro';

export const mailingListSubscriptionStatusQuery = loader('./mailing-list-subscription-status.gql');
export const getTweetsPayloadQuery = loader('./get-tweets-payload.gql');
export const getYouTubeVideosQuery = loader('./get-youtube-videos.gql');
export const publicAccountProfilePicQuery = loader('./public-account-profile-pic.gql');
export const publicAccountQuery = loader('./public-account.gql');
export const publicInstagramFeedWithCardIdQuery = loader(
  './public-instagram-feed-with-card-id.gql',
);

export default {
  getTweetsPayloadQuery,
  getYouTubeVideosQuery,
  publicAccountProfilePicQuery,
  publicAccountQuery,
  publicInstagramFeedWithCardIdQuery,
};

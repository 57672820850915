import React from 'react';
import { Helmet } from 'react-helmet';

const DocumentHead = ({ title, share }) => {
  return (
    <Helmet>
      <title children={title} />
      <meta property="fb:app_id" content="1467820943460899" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@tapbio" />
      <meta name="twitter:title" content={share.title} />
      <meta name="twitter:description" content={share.description} />
      <meta name="twitter:image" content={share.image} />
      <meta name="twitter:creator" content="@engle" />
      <meta property="og:site_name" content="Tap Bio" />
      <meta property="og:title" content={share.title} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={share.image} />
      <meta property="og:description" content={share.description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content={share.url} />
    </Helmet>
  );
};

DocumentHead.defaultProps = {
  title: 'Tap Bio',
  share: {
    title: 'Tap Bio',
    description: 'Meet Tap Bio. Your Instagram bio, better.',
    image: 'http://tap-bio-promo.imgix.net/share-img-01.jpg',
    url: 'https://tap.bio',
  },
};

export default DocumentHead;

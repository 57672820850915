import React from 'react';
import ResetStyles from 'components/web-ui/reset';
import GlobalStyle from './layout';
import { compose, pure } from 'recompose';
import StoryDeck from 'components/story-deck';
import GdprConsent from 'components/gdpr-consent';
import DocumentHead from './document-head';
import { withStoredParams } from '@tap-bio/core/hocs';

export const App = (props) => {
  return (
    <React.Fragment>
      <ResetStyles />
      <GlobalStyle />
      <DocumentHead />
      <StoryDeck absolute full fallback overflow />
      <GdprConsent />
    </React.Fragment>
  );
};

export default compose(pure, withStoredParams)(App);

import React from 'react';
import config from 'config';
import { useState, useCallback, useEffect } from 'react';
import { initializeFacebookPixel } from 'lib/facebook-pixel';
import GoogleAnalytics from 'lib/google-analytics';
import UserProfile from 'components/user-profile';
import Swiper from './swiper';
import * as Styled from './styled';

function StoryDeck(props) {
  const { username, cards, isOwnerAuthenticated, handleSendPageView, handleSendCardView } = props;
  const { facebookPixelId, googleTrackingId } = props.account.profile;
  const [headerIsVisible, setHeaderIsVisible] = useState(true);
  const [swiperInstance, setSwiperInstance] = useState({});

  useEffect(
    function initializeAnalytics() {
      if (facebookPixelId) initializeFacebookPixel(facebookPixelId);
      if (googleTrackingId) GoogleAnalytics.initialize(googleTrackingId, username);
    },
    [facebookPixelId, googleTrackingId, username],
  );

  const handleSwiperInit = useCallback(
    function(swiper) {
      setSwiperInstance(swiper);
      const card = cards[swiper.activeIndex];
      if (card) handleSendCardView(card);
      handleSendPageView();
    },
    [cards, handleSendPageView, handleSendCardView],
  );

  const handleSlideChangeEnd = useCallback(
    function(swiper) {
      const card = cards[swiper.activeIndex];
      const cardType = card && card.kind;
      if (card) handleSendCardView(card);
      if (cardType === 'profile') return setHeaderIsVisible(false);
      return setHeaderIsVisible(true);
    },
    [cards, setHeaderIsVisible, handleSendCardView],
  );

  const handleContentScroll = useCallback(
    function(event) {
      setHeaderIsVisible(event.target.scrollTop < 50);
    },
    [setHeaderIsVisible],
  );

  const handleUserProfileClick = useCallback(
    function() {
      const profileCardIndex = cards.findIndex((card) => card.kind === 'profile');
      swiperInstance.slideTo(profileCardIndex);
    },
    [cards, swiperInstance],
  );

  const handleAuthenticatedClick = useCallback(
    function() {
      window.location = `${config.dashboardHost}/accounts/${username}`;
    },
    [username],
  );

  return (
    <>
      <UserProfile onClick={handleUserProfileClick} visible={headerIsVisible} />
      {isOwnerAuthenticated && (
        <Styled.EditModeButton onClick={handleAuthenticatedClick} children="Edit mode" />
      )}
      <Swiper
        children={cards}
        isOwnerAuthenticated={isOwnerAuthenticated}
        onSwiperInit={handleSwiperInit}
        onSlideChangeEnd={handleSlideChangeEnd}
        onContentScroll={handleContentScroll}
      />
    </>
  );
}

export default StoryDeck;

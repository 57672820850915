import React from 'react';
import { compose } from 'lib/recompose';
import styled from 'styled-components/macro';
import throttleEventHandler from 'lib/throttle-event-handler';
import { fixLineBreakSpacers } from 'lib/utils';
import View from 'components/ui/view';
import withCollectCardLinkClick from 'hocs/with-collect-card-click';
import withHandleCtaClick from 'hocs/with-handle-cta-click';
import {
  CtaButton,
  CtaButtonContainer,
  DescriptionSmall,
  Layout as BaseLayout,
  TitleSmall,
} from '../styles';
import { convertHexToRgba } from 'lib/color-utils';

const Layout = styled(BaseLayout)`
  padding: 0 20px;
`;

const Scrollable = styled(View)`
  padding: 68px 20px 10px 20px;
  margin: 0 -20px;
`;

const LinksCard = (props) => {
  const { buttonColor, ctas, handleCtaClick, onContentScroll, ...rest } = props;

  return (
    <Layout {...rest} full>
      <Scrollable scroll onScroll={throttleEventHandler(onContentScroll, 500, { leading: true })}>
        {rest.title && <TitleSmall children={fixLineBreakSpacers(rest.title)} />}
        {rest.description && <DescriptionSmall children={fixLineBreakSpacers(rest.description)} />}

        <CtaButtonContainer mt={rest.title || (rest.description && '18px')}>
          {ctas.map((cta) => (
            <CtaButton
              bg={convertHexToRgba(buttonColor || rest.profile.brandColor)}
              children={cta.title}
              key={cta.id}
              onClick={() => handleCtaClick(cta)}
            />
          ))}
        </CtaButtonContainer>
      </Scrollable>
    </Layout>
  );
};

export default compose(withCollectCardLinkClick, withHandleCtaClick)(LinksCard);

import React from 'react';

export default (props) => (
  <svg width={176} height={124} viewBox="0 0 176 124" {...props}>
    <title>yt_icon_cymk_mono_light</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M172.322,19.363 C170.298,11.741 164.334,5.739 156.762,3.702 C143.037,0 88,0 88,0 C88,0 32.963,0 19.238,3.702 C11.666,5.739 5.702,11.741 3.678,19.363 C0,33.177 0,62 0,62 C0,62 0,90.822 3.678,104.637 C5.702,112.259 11.666,118.261 19.238,120.299 C32.963,124 88,124 88,124 C88,124 143.037,124 156.762,120.299 C164.334,118.261 170.298,112.259 172.322,104.637 C176,90.822 176,62 176,62 C176,62 176,33.177 172.322,19.363"
          fill="#33114F"
        />
      </g>
      <polyline
        id="youtube-icon-chevron"
        fill="#FFFFFE"
        points="70 88.169 70 35.831 116 62.001 70 88.169"
      />
    </g>
  </svg>
);

import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  color: white;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.33);
  border-radius: 5px;
  margin: 100px auto 0 auto;
  padding: 10px;
`;

function getMessage({ statusCode, username }) {
  switch (statusCode) {
    case 401:
      return `The @${username} account is protected`;

    case 404:
      return `The @${username} account does not exist`;

    default:
      return 'There was an error loading these Tweets';
  }
}

function Error(props) {
  let message = getMessage(props);

  if (props.isEmpty) {
    message = `The @${props.username} account has no tweets`;
  }

  if (!message) return null;

  return <Container children={message} />;
}

export default Error;

import React from 'react';
import Tweet from './tweet';

function Tweets(props) {
  const { tweets, twitterUsername, onTweetClick } = props;
  return tweets.map((tweet) => (
    <Tweet
      key={tweet.id}
      twitterUsername={twitterUsername}
      tweet={tweet}
      onTweetClick={onTweetClick}
    />
  ));
}

export default Tweets;

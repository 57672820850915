import React from 'react';
import compose from 'lib/compose';
import { withUsername } from 'hocs';
import { withAccount } from 'lib/graphql';
import styled from 'styled-components/macro';
import { Text } from 'components/web-ui';
import { View } from 'components/ui';
import ProfilePic from './profile-pic';

const Layout = styled(View)`
  padding: 16px 0 0 12px;
  display: flex;
  transform: translate3d(0, ${(p) => (p.visible ? 0 : '-100%')}, 0);
  opacity: ${(p) => (p.visible ? 1 : 0)};
  transition: transform 250ms ease, opacity 250ms ease;
`;

const Content = styled(View)`
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  margin-left: 10px;
  /* https://css-tricks.com/flexbox-truncated-text/ */
  min-width: 0;
`;

const Name = styled(Text)`
  font-size: 15px;
`;

const Title = styled(Text)`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 5px;

  /* Truncate the content to a single line using an ellipsis. */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserProfile = (props) => {
  const { account, onClick, visible } = props;

  if (!account) return null;

  return (
    <Layout pass sticky onClick={onClick} visible={visible}>
      <ProfilePic w="38px" />
      <Content>
        <Name children={account.profile.name} />
        <Title children={account.profile.title} />
      </Content>
    </Layout>
  );
};

export default compose(withUsername, withAccount)(UserProfile);

import React from 'react';
import styled from 'styled-components/macro';
import * as Recompose from 'lib/recompose';

const Layout = styled.div`
  color: white;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.33);
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
`;

const Text = styled.span`
  text-align: center;
  width: 85%;
  margin: 0 auto;
`;

const Link = styled.a`
  color: white;
`;

const LoadingErrorMessage = (props) => (
  <Layout>
    <Text children={props.errorMessage} />
  </Layout>
);

LoadingErrorMessage.defaultProps = {
  errorMessage: 'Sorry, we were not able to load any photos.',
};

export default Recompose.compose(
  Recompose.branch((props) => !props.visible, Recompose.renderNothing),
  Recompose.branch((props) => !props.errorType, Recompose.renderNothing),
  Recompose.withProps(({ instagramHandle, errorType }) => {
    const errorMessages = {
      InstagramRequestFailed: (
        <React.Fragment>
          Well that’s unexpected. Give{' '}
          <Link
            href={`https://www.instagram.com/${instagramHandle}/`}
            children={instagramHandle || 'them'}
          />{' '}
          a heads up their Instagram connection needs attention.
        </React.Fragment>
      ),
      InstagramAuthenticate: "Sorry, we couldn't access your Instagram account.",
    };

    return { errorMessage: errorMessages[errorType] };
  }),
  Recompose.pure,
)(LoadingErrorMessage);

import { withHandlers } from 'recompose';
import { ImageGalleryItemClickPayload } from 'lib/stats';
import StatsCollector from 'lib/stats-collector';

export default withHandlers({
  sendImageGalleryItemClick: (props) => (accountId, cardId, imageId, url) => {
    const payload = ImageGalleryItemClickPayload.create({
      accountId,
      cardId,
      imageId,
      url,
    });
    StatsCollector.imageGalleryItemClick(payload);
  },
});

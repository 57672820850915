import React from 'react';
import styled from 'styled-components/macro';
import * as Recompose from 'lib/recompose';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.33);
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
  height: 100px;
  width: calc(100% - 15px);
`;

const Text = styled.span`
  width: 90%;
  margin: 0 auto;
  text-align: center;
`;

const EmptyFeedMessage = (props) => {
  return (
    <Layout>
      <Text children="There are no photos to see at the moment, but check back soon." />
    </Layout>
  );
};

export default Recompose.branch((props) => !props.visible, Recompose.renderNothing)(
  EmptyFeedMessage,
);

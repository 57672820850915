/**
 * A set of CSS helper functions for use
 * anywhere JS is available
 */

/**
 * @example toEm(768)
 *          // => 48rem
 */
export const toEm = (px = 0) => Math.ceil(px / 16) + 'rem';

/**
 * @example tweakAt({width: 768})
 *          // => (min-width: 48rem)
 *
 *          tweakAt({width: 768, height: 384})
 *          // => (min-width: 48rem) and (min-height: 24rem)
 */
export const tweakAt = (args) =>
  Object.keys(args)
    .map((key) => `(min-${key}: ${toEm(args[key])})`)
    .join(' and ');

import React from 'react';
import styled from 'styled-components/macro';
import YouTube from 'react-youtube';
import { lifecycle } from 'recompose';
import NoVideos from './no-videos';
import OpenInAppButton from './open-in-app-button';
import SubscribeButton from './subscribe-button';
import Video from './video';
import VideoMeta from './video-meta';
import { fixLineBreakSpacers } from 'lib/utils';
import View from 'components/ui/view';
import { isEmpty } from 'lib/utils';
import { ContentSpacer, TitleSmall, DescriptionSmall } from '../styles';
import { Buttons, FieldsContainer, Layout, MainVideoContainer, Spinner } from './styles';

const Scrollable = styled(View)`
  padding: 0 10px 10px 10px;
  margin: 15px -10px 0 -10px;
  justify-content: flex-start;
`;

// Reference to the video player, set via the player's onReady event
let video = null;

const CardLayout = ({
  activeVideo,
  buttonColor,
  changeVideo,
  description,
  handleYoutubeStateChange,
  hasLoadedVideos,
  hasVideos,
  onVideoPlay,
  recordPlayStat,
  sendCardView,
  setActiveVideo,
  setRecordPlayStat,
  setVideoIsLoading,
  title,
  videoIframeIsLoading,
  videos,
  youtube,
  ...props
}) => {
  return (
    <Layout {...props} full scroll={false} overflow={false} flex="column">
      <div style={{ height: '100%' }} children={props.children} />
    </Layout>
  );
};

function render(props) {
  const {
    activeVideo: thisIsTheActiveVideo,
    buttonColor,
    changeVideo,
    description,
    handleYoutubeStateChange,
    hasLoadedVideos,
    onVideoPlay,
    setVideoIsLoading,
    title,
    videoIframeIsLoading,
    videos,
    youtube,
  } = props;

  // We're waiting for the videos to load
  if (!hasLoadedVideos) return <Spinner />;

  // We've loaded the videos but there aren't any
  if (!videos.length) return <NoVideos />;

  const activeVideo = isEmpty(thisIsTheActiveVideo) ? videos[0] : thisIsTheActiveVideo;
  const titleWithFallback = title || 'Tap a video to watch';

  return (
    <FieldsContainer>
      <ContentSpacer title={titleWithFallback} description={description}>
        <TitleSmall children={fixLineBreakSpacers(titleWithFallback)} />
        {description && <DescriptionSmall children={fixLineBreakSpacers(description)} />}
      </ContentSpacer>
      <MainVideoContainer>
        {videoIframeIsLoading && <Spinner fill="rgba(255,255,255,1)" />}
        <YouTube
          videoId={activeVideo.id}
          containerClassName="youtube-video-container"
          onStateChange={handleYoutubeStateChange}
          onPlay={onVideoPlay.bind(this, activeVideo)}
          onReady={(event) => {
            video = event.target;
            setVideoIsLoading(false);
          }}
          // https://developers.google.com/youtube/player_parameters
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: 0, // Don't autoplay because views aren't counted for autoplay videos
              showinfo: 0, // Don't display information like the video title and uploader before the video starts playing.
              modestbranding: 1,
              rel: 0, // Don't show related videos after playback has finished
              playsinline: 0, // Play in fullscreen
            },
          }}
        />
      </MainVideoContainer>
      <VideoMeta video={activeVideo} />
      <Buttons>
        <SubscribeButton channelId={youtube.channelId} backgroundColor={buttonColor || 'red'} />
        <OpenInAppButton video={activeVideo} backgroundColor={buttonColor || 'red'} />
      </Buttons>
      <Scrollable scroll flex="column" center>
        {videos.slice(1).map((video, index) => (
          <Video activeVideo={activeVideo} key={index} setActiveVideo={changeVideo} video={video} />
        ))}
      </Scrollable>
    </FieldsContainer>
  );
}

/**
 * If we've just swiped to this card, start the primary video playback
 * If we're swiping away, pause the playback. We have this HOC here
 * because we need a reference to the player in order to manipulate its
 * playing state.
 */
const withLifecycle = lifecycle({
  componentDidUpdate() {
    if (!video) return;
    if (this.props.activeIndex !== this.props.cardIndexInDeck) {
      video.pauseVideo();
    }
  },
});

export default withLifecycle((props) => <CardLayout {...props} children={render(props)} />);

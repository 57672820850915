import { withProps } from 'recompose';

export const isIframeBlocked = ({ deck }) => deck.publicCards.some((card) => card.position < 0);

export const withIframeDetection = withProps(({ account }) => ({
  iframe: {
    blocked: isIframeBlocked(account),
    src: account.profile && account.profile.website,
  },
}));

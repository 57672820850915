import { YoutubeIcon } from 'components/ui/icons';
import React from 'react';
import { withHandlers } from 'recompose';
import styled from 'styled-components/macro';

const Link = styled.a`
  color: white;
  width: 55%;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.backgroundColor};
  font-weight: 700;
  border-radius: 99999px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
  }
`.withComponent(({ backgroundColor, children, ...props }) => <a {...props} children={children} />);

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    line-height: 1;
    margin-left: 5px;
  }

  path {
    fill: #ffffff;
  }
  #youtube-icon-chevron {
    fill: ${(props) => props.backgroundColor};
  }
`.withComponent(({ backgroundColor, ...props }) => <div {...props} />);

const SubscribeButton = ({ backgroundColor, handleClick }) => (
  <Link backgroundColor={backgroundColor} onClick={handleClick}>
    <Icon backgroundColor={backgroundColor}>
      <YoutubeIcon width={30} height={20} />
      <span>Subscribe</span>
    </Icon>
  </Link>
);

/**
 * How to manually test this code in Android
 *   1. Find a REAL android device. I used a Nexus 5 running Android 6.0.1
 *   2. Ensure you're running the server as detailed in README.md
 *   3. Disable the YouTube app. This is a two step process. First, you must downgrade it to the base factory installed version. Then you can disable it. The best way to do this seems to be via `Settings > Apps > YouTube > Disable`.
 *   4. Now you can click the 'Subscribe' button and it should open in the browser (as a fallback)
 *   5. Next you should re-enable the YouTube app and try the process again. Clicking the button should open the video in the YouTube app.
 */

const withPlatformLink = withHandlers({
  handleClick: (props) => (event) => {
    event.preventDefault();
    const baseUrl = `www.youtube.com/channel/${props.channelId}?sub_confirmation=1`;
    const browserLocation = `https://${baseUrl}`;
    if (/Android/i.test(window.navigator.userAgent)) {
      window.location = `vnd.youtube://${baseUrl}`;
      setTimeout(function() {
        window.location = browserLocation;
      }, 250);
    } else {
      window.location = browserLocation;
    }
  },
});

export default withPlatformLink(SubscribeButton);

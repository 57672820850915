import * as Recompose from 'recompose';

/**
 * If the card object is an empty array (can happen if a
 * user hides all of her cards) then inject a single card
 * into the deck displaying a "Coming Soon" message
 */

const CARD_ATTRS = {
  backgroundColorSolid: '#33154E',
  buttonColor: '#E52952D2',
  ctas: [
    {
      id: 1,
      title: 'Create My Tap Bio',
      url: 'https://tap.bio/sign-up',
    },
  ],
  description:
    'They’ll likely be announcing it soon. In the meantime, why not make a Tap Bio of your\u00A0own?',
  hasBackgroundGradient: false,
  id: 0,
  kind: 'simple',
  position: 0,
};

const withComingSoonCard = Recompose.withProps((props) => {
  if (props.cards.length > 0) return;

  const { username } = props;
  const { profile } = props.account;

  const name = profile.name || username;

  const comingSoonCard = {
    ...CARD_ATTRS,
    title: `\n\n${name} is working on something amazing!`,
  };

  return { cards: [comingSoonCard] };
});

export { withComingSoonCard };
export default withComingSoonCard;
